import { z } from "zod";

export const articleCategoryUpsertFormSchema = z.object({
  display: z.string().min(3).max(255),
  displayAr: z.string().min(3).max(255),
  code: z.string().min(1),
});

export type IArticleCategoryUpsertFormValues = z.infer<typeof articleCategoryUpsertFormSchema>;

export const articleCategoryUpsertFormSchemaDefaultValues: Partial<IArticleCategoryUpsertFormValues> = {
  display: undefined,
  displayAr: undefined,
  code: undefined,
};
