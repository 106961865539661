/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GuidedCareTemplateActivationMutation = { __typename?: 'Mutation', changeHealthProgramTemplateActiveStatus?: { __typename?: 'HealthProgramTemplateCRUD', healthProgramTemplate?: { __typename?: 'HealthProgramTemplate', active?: boolean | null } | null, healthProgramTemplateErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTemplateActivationDocument = gql`
    mutation GuidedCareTemplateActivation($id: ID!) {
  changeHealthProgramTemplateActiveStatus(id: $id) {
    healthProgramTemplate {
      active
    }
    healthProgramTemplateErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTemplateActivationMutationFn = Apollo.MutationFunction<GuidedCareTemplateActivationMutation, GuidedCareTemplateActivationMutationVariables>;

/**
 * __useGuidedCareTemplateActivationMutation__
 *
 * To run a mutation, you first call `useGuidedCareTemplateActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTemplateActivationMutation, { data, loading, error }] = useGuidedCareTemplateActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareTemplateActivationMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTemplateActivationMutation, GuidedCareTemplateActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTemplateActivationMutation, GuidedCareTemplateActivationMutationVariables>(GuidedCareTemplateActivationDocument, options);
      }
export type GuidedCareTemplateActivationMutationHookResult = ReturnType<typeof useGuidedCareTemplateActivationMutation>;
export type GuidedCareTemplateActivationMutationResult = Apollo.MutationResult<GuidedCareTemplateActivationMutation>;
export type GuidedCareTemplateActivationMutationOptions = Apollo.BaseMutationOptions<GuidedCareTemplateActivationMutation, GuidedCareTemplateActivationMutationVariables>;