import { createPathDefinition } from "@toolkit/core";

export const healthParametersRoute = `/health-parameters`;

export const healthParametersPaths = createPathDefinition(healthParametersRoute, {
  list: "",
  create: "new",
  update: ":healthParameterId",
  decisionCreate: ":healthParameterId/rules/new",
  decisionUpdate: ":healthParameterId/rules/:id",
});
