/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type HPageInfoFragmentFragment = { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null };

export const HPageInfoFragmentFragmentDoc = gql`
    fragment HPageInfoFragment on H_PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `;