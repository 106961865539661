import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  GuidedCareProgramActivityItemsUpdateMutation,
  useGuidedCareProgramActivityGetQuery,
  useGuidedCareProgramActivityItemsUpdateMutation,
} from "pages/Programs/gql";
import { useGuidedCareProgramsBreadcrumbs } from "pages/Programs/hooks";
import { convertActivityItemsFormValuesToBackEndValues } from "pages/Programs/others";
import { IGuidedCareProgramActivityItemListContainerParams, IGuidedCareProgramActivityItemsFormEvent } from "pages/Programs/types";
import { GuidedCareProgramActivityItemsForm } from "../../forms/ProgramActivityItems/GuidedCareProgramActivityItemsForm";

export const GuidedCareProgramActivityItemListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();

  const { setGuidedCareProgramActivityItemListContainerBreadcrumb } = useGuidedCareProgramsBreadcrumbs();

  const { activityId } = useParams<IGuidedCareProgramActivityItemListContainerParams>();

  const { data, loading, refetch } = useGuidedCareProgramActivityGetQuery({
    variables: {
      guidedCareHealthProgramActivityId: activityId!,
    },
  });

  const programActivity = data?.guidedCareHealthProgramActivity;

  const [fetchGuidedCareProgramActivityItemsUpdateMutation, { loading: isSubmitting }] = useGuidedCareProgramActivityItemsUpdateMutation({
    onCompleted: (mutationData: GuidedCareProgramActivityItemsUpdateMutation) => {
      const errors = mutationData?.updateGuidedCareHealthProgramActivityItems?.guidedCareHealthProgramActivityErrors;

      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Activity items have been updated successfully"));
      }
    },
    onError: () => {
      failed(t("Activity items failed to update"));
    },
  });

  const onActivityItemsFormChange = (event: IGuidedCareProgramActivityItemsFormEvent) => {
    if (event.type === "SUBMIT") {
      fetchGuidedCareProgramActivityItemsUpdateMutation({
        variables: {
          activityId: activityId!,
          activityItems: convertActivityItemsFormValuesToBackEndValues(event.payload.values.activityItems),
        },
      }).then(() => refetch());
    }
  };

  useEffect(() => {
    setGuidedCareProgramActivityItemListContainerBreadcrumb();
  }, [setGuidedCareProgramActivityItemListContainerBreadcrumb]);

  return (
    <GuidedCareProgramActivityItemsForm
      isLoading={loading || isSubmitting}
      activity={programActivity}
      onChange={onActivityItemsFormChange}
    />
  );
};
