import { useCustomFormContext, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, useTheme } from "@toolkit/ui";
import { IGuidedCareProgramActivityItem, IGuidedCareProgramActivityUpsertFormEvent } from "pages/Programs/types";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { GuidedCareProgramActivityUpsertModal } from "../../modals/ProgramActivityUpsert/GuidedCareProgramActivityUpsertModal";
import { IGuidedCareProgramUpsertFormValues } from "../ProgramUpsert/GuidedCareProgramUpsertFormSchema";
import { useGuidedCareProgramActivitiesColumns } from "./useGuidedCareProgramActivitiesColumns";
import { useGuidedCareProgramActivitiesFormStyle } from "./useGuidedCareProgramActivitiesFormStyle";

export const GuidedCareProgramActivitiesForm = () => {
  const [selectedActivity, setSelectedActivity] = useState<IGuidedCareProgramActivityItem>();

  const { t } = useTranslation("gcadmin");
  const { open, handleOpen, handleClose } = useOpenState();

  const form = useCustomFormContext<IGuidedCareProgramUpsertFormValues>();
  const { control, getValues, isFormDisabled } = form;

  const { teamMembers, activities } = getValues();

  const { classes } = useGuidedCareProgramActivitiesFormStyle();
  const theme = useTheme();

  const { fields, prepend, remove, update } = useFieldArray<IGuidedCareProgramUpsertFormValues, "activities">({
    control,
    name: "activities",
  });

  const onProgramActivityUpsertModalChange = (event: IGuidedCareProgramActivityUpsertFormEvent) => {
    if (event.type === "CREATE") {
      prepend(event.payload.values);
      handleClose();
    } else if (event.type === "UPDATE" && selectedActivity) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedActivity.id);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, { ...activities[updatedFieldIndex], ...event.payload.values });
        update(updatedFieldIndex, event.payload.values);
      }
      handleClose();
    }
  };

  const onAddClick = () => {
    setSelectedActivity(undefined);
    handleOpen();
  };

  const onEditRowClick = (item: IGuidedCareProgramActivityItem) => {
    setSelectedActivity(item);
    handleOpen();
  };

  const onDeleteRowClick = (item: IGuidedCareProgramActivityItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);
    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  return (
    <Grid container spacing={2}>
      {open && (
        <GuidedCareProgramActivityUpsertModal
          isOpen={open}
          onClose={handleClose}
          teamMembers={teamMembers}
          activity={selectedActivity}
          onChange={onProgramActivityUpsertModalChange}
        />
      )}

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography className={classes.title}> {t("Activities")} </Typography>
          </Grid>

          {!isFormDisabled && (
            <Grid xs={4} lg={2}>
              <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon fill={theme.palette.common.white} />}>
                {t("Add Activity")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareProgramActivitiesColumns({ isFormDisabled })}
          isEditVisible
          isDeleteVisible
          isRowDeletable={({ templateActivityId }) => !isFormDisabled && !templateActivityId}
          isRowEditable={({ templateActivityId }) => !isFormDisabled && !templateActivityId}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
