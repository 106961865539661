import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useArticleCategoryListContainerColumns } from "./useArticleCategoryListContainerColumns";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "@health/domains";
import { ArticleCategory } from "@health/queries/types";
import { articlesCategoriesBreadcrumb, articlesCategoriesPaths } from "../../constants";
import { useArticleCategoryListQuery } from "../../gql";

export const ArticleCategoryListContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(articlesCategoriesPaths.create.fullPath);
  };

  const onEditRowClick = (item: ArticleCategory) => {
    navigate(articlesCategoriesPaths.update.fullPathWithParams({ articleCategoryId: item?.id }));
  };

  useEffect(() => {
    setBreadCrumb({
      title: articlesCategoriesBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName={"articleCategoryList"}
      query={useArticleCategoryListQuery}
      columns={useArticleCategoryListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
