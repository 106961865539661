import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { IMedicalFormUpsertFormEvent, MedicalFormUpsertForm } from "@health/domains";
import { createMedicalFormsBreadcrumbs, medicalFormsPaths } from "pages/MedicalForms/constants";
import { AdminMedicalFormCreateMutation, useAdminMedicalFormCreateMutation } from "pages/MedicalForms/gql";
import { convertAdminMedicalFormValuesToBackEndValues } from "pages/MedicalForms/others/utils";

export const AdminMedicalFormCreateContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const { t } = useTranslation("gcadmin");

  const { failed, succeeded } = useAddToast();

  const [fetchAdminMedicalFormCreateMutation, { loading: isSubmitting }] = useAdminMedicalFormCreateMutation({
    onCompleted: (mutationData: AdminMedicalFormCreateMutation) => {
      const errors = mutationData?.adminCreateMedicalForm?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Medical form created successfully"));
        navigate(medicalFormsPaths.main.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onMedicalFormUpsertFormChange = (event: IMedicalFormUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertAdminMedicalFormValuesToBackEndValues(event.payload.values);

      if (!values?.questions?.length) {
        return failed(t("There should be at least one question included in the medical form"));
      }

      fetchAdminMedicalFormCreateMutation({
        variables: {
          input: values,
        },
      });
    }
  };

  const handleCancel = () => {
    navigate(medicalFormsPaths.main.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({ title: createMedicalFormsBreadcrumbs(t).title });
  }, [setBreadCrumb, t]);

  return (
    <MedicalFormUpsertForm
      isAdmin
      submitButtonLabel={t("Create")}
      isSubmitDisabled={isSubmitting}
      handleCancel={handleCancel}
      onChange={onMedicalFormUpsertFormChange}
    />
  );
};
