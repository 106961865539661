import { z } from "zod";

export const riskFactorTemplateUpsertFormSchema = z.object({
  display: z.string().max(255),
  arabicDisplay: z.string().max(255),
  code: z.string().min(1).max(255),
  isActive: z.boolean(),
});

export type IRiskFactorTemplateUpsertFormValues = z.infer<typeof riskFactorTemplateUpsertFormSchema>;

export const riskFactorTemplateUpsertFormDefaultValues: Partial<IRiskFactorTemplateUpsertFormValues> = {
  display: undefined,
  arabicDisplay: undefined,
  code: undefined,
  isActive: false,
};
