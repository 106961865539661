import { FormActions, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  IRiskFactorTemplateUpsertFormValues,
  riskFactorTemplateUpsertFormDefaultValues,
  riskFactorTemplateUpsertFormSchema,
} from "./RiskFactorTemplateUpsertFormSchema";
import { FC, useEffect } from "react";
import { RiskFactorTemplate } from "@health/queries/types";
import { useNavigate } from "react-router-dom";
import { riskFactorTemplatesPaths } from "../../constants";
import { convertRiskFactorTemplateToFormValues } from "../../others/utils";

export type IRiskFactorTemplateUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IRiskFactorTemplateUpsertFormValues;
  };
};

type RiskFactorTemplateUpsertFormProps = {
  riskFactorTemplate?: RiskFactorTemplate;
  submitButtonLabel: string;
  isClone?: boolean;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IRiskFactorTemplateUpsertFormEvent) => void;
};

export const RiskFactorTemplateUpsertForm: FC<RiskFactorTemplateUpsertFormProps> = props => {
  const { riskFactorTemplate, submitButtonLabel, isClone, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const form = useCustomForm<IRiskFactorTemplateUpsertFormValues>({
    defaultValues: riskFactorTemplateUpsertFormDefaultValues,
    schema: riskFactorTemplateUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IRiskFactorTemplateUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onCancelClick = () => {
    navigate(riskFactorTemplatesPaths.list.fullPath);
  };

  useEffect(() => {
    if (riskFactorTemplate) {
      const _riskFactorTemplate = convertRiskFactorTemplateToFormValues(riskFactorTemplate, !!isClone);
      setValues(_riskFactorTemplate);
    }
  }, [riskFactorTemplate, isClone, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={onCancelClick}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Risk Factor Template Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"display"} label={t("Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"arabicDisplay"} label={t("Arabic Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"code"} label={t("Code")} disabled={!!riskFactorTemplate && !isClone} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormSwitch name={"isActive"} label={t("Is Active")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
