import { CareGapsIcon, useTheme } from "@toolkit/ui";

export const HealthParametersRoutesIcon = () => {
  const theme = useTheme();

  return (
    <CareGapsIcon
      sx={{
        "& path": {
          fill: theme.palette.primary.main,
        },
      }}
    />
  );
};
