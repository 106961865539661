import { HealthConditionUpsertForm, IHealthConditionUpsertFormEvent } from "../../forms/HealthConditionUpsert/HealthConditionUpsertForm";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useHealthConditionCreateMutation } from "../../gql";
import { formatGraphQLError } from "@toolkit/apollo";
import { convertHealthConditionFormValuesToBackEndValues } from "../../others/utils";
import { useSetHealthConditionsBreadcrumbs } from "../../hooks";
import { healthConditionsPaths } from "../../constants";

export const HealthConditionCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createHealthCondition, { loading: isSubmitting }] = useHealthConditionCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addHealthCondition?.errors?.length) {
        succeeded(t("Health Condition created successfully"));
        navigate(healthConditionsPaths.list.fullPath);
      } else {
        failed(t("Health Condition create failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthConditionUpsertFormChange = (event: IHealthConditionUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthConditionFormValuesToBackEndValues(event.payload.values);

      createHealthCondition({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetHealthConditionsBreadcrumbs("CREATE");

  return (
    <HealthConditionUpsertForm
      submitButtonLabel={t("Create")}
      isButtonDisabled={isSubmitting}
      onChange={onHealthConditionUpsertFormChange}
    />
  );
};
