import { getAutocompleteFilter } from "@toolkit/ui";
import { useHealthParameterCategoriesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const getHealthParameterCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "getHealthParameterCategories",
        query: useHealthParameterCategoriesAutocompleteQuery,
        filtersForQuery: {},
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, item === null || item === void 0 ? void 0 : item.arabicDisplay),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
