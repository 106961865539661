import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomAlert, Link, Typography } from "@toolkit/ui";
import { useGuidedCareProgramOutdatedAlertStyle } from "./useGuidedCareProgramOutdatedAlertStyle";

type ProgramOutdatedAlertProps = {
  onClick: () => void;
};

export const GuidedCareProgramOutdatedAlert: FC<ProgramOutdatedAlertProps> = props => {
  const { onClick } = props;

  const { t } = useTranslation("gcadmin");

  const { classes } = useGuidedCareProgramOutdatedAlertStyle();

  return (
    <CustomAlert level={"module"} appearance={"error"} title={t("Be Aware!")} disableClose disableMarginBottom>
      <Typography>
        {t("The program is outdated and cannot be updated. Please click")}{" "}
        <Link onClick={onClick} className={classes.link}>
          {t("here")}
        </Link>{" "}
        {t("to sync it.")}
      </Typography>
    </CustomAlert>
  );
};
