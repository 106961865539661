import { Box, FormCard, Grid, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { RiskFactorTemplate } from "@health/queries/types";

type RiskFactorTemplateInformationProps = {
  riskFactorTemplate: RiskFactorTemplate;
  isLoading?: boolean;
};

export const RiskFactorTemplateInformation: FC<RiskFactorTemplateInformationProps> = props => {
  const { riskFactorTemplate, isLoading } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Box marginBottom={2}>
      <FormCard title={t("Risk Factor Template")} doYouHaveData={!!riskFactorTemplate} loading={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Display")}: {riskFactorTemplate?.display || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Arabic Display")}: {riskFactorTemplate?.arabicDisplay || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Code")}: {riskFactorTemplate?.code || "-"}
            </Typography>
          </Grid>
        </Grid>
      </FormCard>
    </Box>
  );
};
