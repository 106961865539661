import { FallbackComponent } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Route, Routes } from "react-router-dom";
import { doLogout } from "shared/configs/apollo";
import { LoggedInRoutes } from "./LoggedInRoutes";

export const RootRouter: FC = () => {
  const auth = useAuth();
  useEffect(() => {
    if (auth && auth.isLoading) return;
    if (auth && auth.isAuthenticated && !auth.user?.expired) return;
    auth.signinRedirect();
  }, [auth]);

  switch (auth.activeNavigator) {
    case "signinSilent":
    case "signoutRedirect":
      return <FallbackComponent />;
  }

  if (auth.isLoading) {
    return <FallbackComponent />;
  }

  if (auth.error) {
    console.log("auth.error", auth.error);
    doLogout();
    return <FallbackComponent />;
  }

  return (
    <Routes>
      <Route path='404' element={<PageNotFound />} />
      <Route path='soon' element={<PageUnderConstruction />} />
      {auth.isAuthenticated && (
        <>
          <Route path='app' element={<LoggedInRoutes />} />
          <Route path='/*' element={<LoggedInRoutes />} />
        </>
      )}
    </Routes>
  );
};

const PageNotFound = () => (
  <div>
    <h2>404 PAGE Not Found!</h2>
  </div>
);
const PageUnderConstruction = () => (
  <div>
    <h2>PAGE UNDER CONSTRUCTION!</h2>
  </div>
);
