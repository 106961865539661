import { SadaVideosAutocomplete, ArticlesAutocomplete } from "@health/autocompletes";
import { MedicalMessageType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField } from "@toolkit/ui";
import { FC } from "react";

type MedicalMessageTypeFormProps = {
  medicalMessageType: MedicalMessageType;
  isArabic?: boolean;
};

export const MedicalMessageTypeForm: FC<MedicalMessageTypeFormProps> = props => {
  const { medicalMessageType, isArabic } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <>
      {medicalMessageType === MedicalMessageType.Article && !isArabic && <ArticlesAutocomplete name={"article"} />}

      {medicalMessageType === MedicalMessageType.Video && (
        <SadaVideosAutocomplete
          name={isArabic ? "videoArabic" : "videoEnglish"}
          label={isArabic ? t("Video (Arabic)") : t("Video (English)")}
        />
      )}

      {medicalMessageType === MedicalMessageType.Text && (
        <FormTextField
          name={isArabic ? "contentURLArabic" : "contentURLEnglish"}
          label={isArabic ? t("URL (Arabic)") : t("URL (English)")}
        />
      )}
    </>
  );
};
