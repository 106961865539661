/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.RiskFactorTemplateUpdateInput;
}>;


export type RiskFactorTemplateUpdateMutation = { __typename?: 'Mutation', updateRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const RiskFactorTemplateUpdateDocument = gql`
    mutation RiskFactorTemplateUpdate($id: ID!, $input: RiskFactorTemplateUpdateInput!) {
  updateRiskFactorTemplate(id: $id, input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type RiskFactorTemplateUpdateMutationFn = Apollo.MutationFunction<RiskFactorTemplateUpdateMutation, RiskFactorTemplateUpdateMutationVariables>;

/**
 * __useRiskFactorTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useRiskFactorTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskFactorTemplateUpdateMutation, { data, loading, error }] = useRiskFactorTemplateUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRiskFactorTemplateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RiskFactorTemplateUpdateMutation, RiskFactorTemplateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RiskFactorTemplateUpdateMutation, RiskFactorTemplateUpdateMutationVariables>(RiskFactorTemplateUpdateDocument, options);
      }
export type RiskFactorTemplateUpdateMutationHookResult = ReturnType<typeof useRiskFactorTemplateUpdateMutation>;
export type RiskFactorTemplateUpdateMutationResult = Apollo.MutationResult<RiskFactorTemplateUpdateMutation>;
export type RiskFactorTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<RiskFactorTemplateUpdateMutation, RiskFactorTemplateUpdateMutationVariables>;