import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, Grid } from "@toolkit/ui";
import { MedicalMessagesAutocomplete } from "@health/autocompletes";
import { appointmentFrequencyTypeOptions, guidedCareActivityTypeProgramOptions } from "@health/enum-options";
import { MedicalMessageCategory } from "@health/queries/types";
import { filterTeamMembersByActivityType } from "pages/Programs/others";
import { IGuidedCareProgramActivityUpsertFormEvent } from "pages/Programs/types";
import { IGuidedCareProgramMemberUpsertFormValues } from "../ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";
import {
  GuidedCareProgramActivityUpsertFormSchema,
  IGuidedCareProgramActivityUpsertFormValues,
  guidedCareProgramActivityUpsertFormDefaultValues,
} from "./GuidedCareProgramActivityUpsertFormSchema";

type GuidedCareProgramActivityUpsertFormProps = {
  teamMembers: IGuidedCareProgramMemberUpsertFormValues[];
  activity?: IGuidedCareProgramActivityUpsertFormValues;
  onChange: (event: IGuidedCareProgramActivityUpsertFormEvent) => void;
};

export type GuidedCareProgramActivityUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareProgramActivityUpsertFormValues>;
  submit: () => void;
};

const GuidedCareProgramActivityUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareProgramActivityUpsertFormRef,
  GuidedCareProgramActivityUpsertFormProps
> = (props, ref) => {
  const { activity, teamMembers, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareProgramActivityUpsertFormValues>({
    defaultValues: guidedCareProgramActivityUpsertFormDefaultValues,
    schema: GuidedCareProgramActivityUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const templateActivityType = watch("templateActivityType")?.value;

  const filteredTeamMembers = filterTeamMembersByActivityType(teamMembers, templateActivityType);

  const onSubmit = (values: IGuidedCareProgramActivityUpsertFormValues) => {
    if (activity) {
      onChange({
        type: "UPDATE",
        payload: { values },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (activity) {
      setValues(activity);
    }
  }, [activity, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormAutocomplete name={"templateActivityType"} label={t("Activity Type")} options={guidedCareActivityTypeProgramOptions} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete name={"teamMember"} label={t("Assignee")} options={filteredTeamMembers} />
          </Grid>

          <Grid item xs={6}>
            <FormNumberField name={"numberOfOccurrences"} label={t("Occurrences")} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete name={"frequencyType"} label={t("Frequency")} options={appointmentFrequencyTypeOptions} />
          </Grid>

          <Grid item xs={6}>
            <MedicalMessagesAutocomplete
              name={"beforeActivity"}
              label={t("Before Activity")}
              filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <MedicalMessagesAutocomplete
              name={"afterActivity"}
              label={t("After Activity")}
              filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormNumberField name={"beforeActivityOffset"} label={t("Before Activity - Offset (Days)")} />
          </Grid>

          <Grid item xs={6}>
            <FormNumberField name={"afterActivityOffset"} label={t("After Activity - Offset (Days)")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareProgramActivityUpsertForm = forwardRef(GuidedCareProgramActivityUpsertFormForwardRef);
