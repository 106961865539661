import { z } from "zod";
import { zodSchema } from "@health/domains";

export const GuidedCareProgramActivityItemUpdateFormSchema = z.object({
  offsetInDays: z.coerce.number().min(0),
  beforeActivity: zodSchema.medicalMessageAutocompleteOptional,
  afterActivity: zodSchema.medicalMessageAutocompleteOptional,
  beforeActivityOffset: z.coerce.number().min(0).nullable().optional(),
  afterActivityOffset: z.coerce.number().min(0).nullable().optional(),
});

export type IGuidedCareProgramActivityItemUpdateFormValues = z.infer<typeof GuidedCareProgramActivityItemUpdateFormSchema>;

export const guidedCareProgramActivityItemUpdateFormDefaultValues: Partial<IGuidedCareProgramActivityItemUpdateFormValues> = {
  offsetInDays: undefined,
  beforeActivity: null,
  afterActivity: null,
  beforeActivityOffset: null,
  afterActivityOffset: null,
};
