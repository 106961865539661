/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionPlanListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.DecisionPlanFilterInput>;
}>;


export type DecisionPlanListQuery = { __typename?: 'Query', getPlans?: { __typename?: 'DecisionPlanConnection', edges?: Array<{ __typename?: 'DecisionPlanEdge', node?: { __typename?: 'DecisionPlan', id?: string | null, code?: string | null, display?: string | null } | null } | null> | null } | null };


export const DecisionPlanListDocument = gql`
    query DecisionPlanList($after: String, $before: String, $first: Int, $last: Int, $filter: DecisionPlanFilterInput) {
  getPlans(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
  ) {
    edges {
      node {
        id
        code
        display
      }
    }
  }
}
    `;

/**
 * __useDecisionPlanListQuery__
 *
 * To run a query within a React component, call `useDecisionPlanListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionPlanListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionPlanListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionPlanListQuery(baseOptions?: Apollo.QueryHookOptions<DecisionPlanListQuery, DecisionPlanListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionPlanListQuery, DecisionPlanListQueryVariables>(DecisionPlanListDocument, options);
      }
export function useDecisionPlanListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionPlanListQuery, DecisionPlanListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionPlanListQuery, DecisionPlanListQueryVariables>(DecisionPlanListDocument, options);
        }
export type DecisionPlanListQueryHookResult = ReturnType<typeof useDecisionPlanListQuery>;
export type DecisionPlanListLazyQueryHookResult = ReturnType<typeof useDecisionPlanListLazyQuery>;
export type DecisionPlanListQueryResult = Apollo.QueryResult<DecisionPlanListQuery, DecisionPlanListQueryVariables>;