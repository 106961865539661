import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { Article, ArticleSortingField } from "@health/queries/types";
import { articlesPaths, articlesBreadcrumb } from "../../constants";
import { useNavigate } from "react-router-dom";
import { GridProvider } from "@health/domains";
import { useArticleListContainerColumns } from "./useArticleListContainerColumns";
import { useArticleListQuery } from "../../gql";

export const ArticleListContainer = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(articlesPaths.create.fullPath);
  };

  const onEditRowClick = (item: Article) => {
    navigate(articlesPaths.update.fullPathWithParams({ articleId: item?.id! }));
  };

  useEffect(() => {
    setBreadCrumb({
      title: articlesBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName={"articleList"}
      query={useArticleListQuery}
      columns={useArticleListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: ArticleSortingField.Created,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
