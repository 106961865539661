import { TFunction } from "@toolkit/i18n";
import { IFilter } from "@toolkit/ui";

const activeInActiveFilter: IFilter = {
  type: "autocomplete",
  name: "isActive",
  getOptionLabel: option => option?.name,
  getValueForBackend: option => option?.value,
};

export const getActiveInActiveFilter = (t: TFunction) => {
  return {
    options: [
      { name: t("Active", { ns: "gcadmin" }), value: true },
      { name: t("In active", { ns: "gcadmin" }), value: false },
    ],
    ...activeInActiveFilter,
  };
};
