import { TFunction } from "@toolkit/i18n";
import { subListsPaths, subListsRoute } from "pages/Sublists/constants";
import {
  GuidedCareSublistListContainer,
  GuidedCareSublistCreateContainer,
  GuidedCareSublistUpdateContainer,
  GuidedCareSublistItemUpdateContainer,
} from "../containers";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";

export const subListsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "sub-lists-routes",
    text: t("Sub Lists Management", { ns: "gcadmin" }),
    route: subListsRoute,
    subItems: [
      {
        id: "sub-list-list-route",
        text: "",
        route: subListsPaths.main.route,
        fullPath: subListsPaths.main.fullPath,
        element: <GuidedCareSublistListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "sub-list-new-route",
        text: "",
        route: subListsPaths.new.route,
        fullPath: subListsPaths.new.fullPath,
        element: <GuidedCareSublistCreateContainer />,
        hidden: true,
      },
      {
        id: "sub-list-update-route",
        text: "",
        route: subListsPaths.edit.route,
        fullPath: subListsPaths.edit.fullPath,
        element: <GuidedCareSublistUpdateContainer />,
        hidden: true,
      },
      {
        id: "sub-list-item-update-route",
        text: "",
        route: subListsPaths.editItem.route,
        fullPath: subListsPaths.editItem.fullPath,
        element: <GuidedCareSublistItemUpdateContainer />,
        hidden: true,
      },
    ],
  };
};
