/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.ArticleFilterInput>;
  sortBy?: Types.InputMaybe<Types.ArticleSortingInput>;
}>;


export type ArticleListQuery = { __typename?: 'Query', articles?: { __typename?: 'ArticleCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ArticleCountableEdge', node: { __typename?: 'Article', id: string, title?: string | null, titleAr?: string | null, active?: boolean | null, content?: string | null, contentAr?: string | null, description?: string | null, headerImage?: string | null, priority?: number | null, readTimeMinutes?: number | null, tags?: Array<string | null> | null, category?: { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null } | null, contentImages?: Array<{ __typename?: 'ArticleContentImage', id: string, alias: string, url: string } | null> | null, relatedArticles?: Array<{ __typename?: 'Article', id: string, title?: string | null } | null> | null } }> } | null };


export const ArticleListDocument = gql`
    query ArticleList($first: Int, $last: Int, $after: String, $before: String, $filter: ArticleFilterInput, $sortBy: ArticleSortingInput) {
  articles(
    first: $first
    last: $last
    after: $after
    filter: $filter
    before: $before
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        title
        titleAr
        active
        content
        contentAr
        description
        headerImage
        priority
        readTimeMinutes
        tags
        category {
          id
          code
          display
          displayAr
        }
        contentImages {
          id
          alias
          url
        }
        relatedArticles {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useArticleListQuery__
 *
 * To run a query within a React component, call `useArticleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useArticleListQuery(baseOptions?: Apollo.QueryHookOptions<ArticleListQuery, ArticleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleListQuery, ArticleListQueryVariables>(ArticleListDocument, options);
      }
export function useArticleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleListQuery, ArticleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleListQuery, ArticleListQueryVariables>(ArticleListDocument, options);
        }
export type ArticleListQueryHookResult = ReturnType<typeof useArticleListQuery>;
export type ArticleListLazyQueryHookResult = ReturnType<typeof useArticleListLazyQuery>;
export type ArticleListQueryResult = Apollo.QueryResult<ArticleListQuery, ArticleListQueryVariables>;