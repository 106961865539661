import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { createMedicalMessagesBreadcrumbs, medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { MedicalMessageVariantUpsertForm } from "pages/MedicalMessages/forms";
import {
  MedicalMessageVariantCreateMutation,
  useMedicalMessageGetQuery,
  useMedicalMessageVariantCreateMutation,
} from "pages/MedicalMessages/gql";
import { convertMedicalMessageVariantFormValuesToBackEndValues } from "pages/MedicalMessages/others";
import { IMedicalMessageVariantCreateContainerParams, IMedicalMessageVariantUpsertFormEvent } from "pages/MedicalMessages/types";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const MedicalMessageVariantCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();

  const { medicalMessageId } = useParams<IMedicalMessageVariantCreateContainerParams>();

  const { data: medicalMessageData, loading: isMedicalMessageLoading } = useMedicalMessageGetQuery({
    variables: { medicalMessageId: medicalMessageId! },
    skip: !medicalMessageId,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const medicalMessageType = medicalMessageData?.medicalMessage?.medicalMessageType;

  const [fetchMedicalMessageVariantCreateMutation, { loading: isSubmitting }] = useMedicalMessageVariantCreateMutation({
    onCompleted: (mutationData: MedicalMessageVariantCreateMutation) => {
      const mutationErrors = mutationData?.medicalMessageVariantCreate?.medicalMessageVariantErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Medical Message Variant created successfully"));
        navigate(medicalMessagesPaths.variants.fullPathWithParams({ medicalMessageId: medicalMessageId! }));
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const onMedicalMessageVariantUpsertFormChange = (event: IMedicalMessageVariantUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertMedicalMessageVariantFormValuesToBackEndValues(event.payload.values, medicalMessageType!);

      fetchMedicalMessageVariantCreateMutation({
        variables: {
          input: {
            ...values,
            medicalMessageID: medicalMessageId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (medicalMessageId) {
      const { variantsTitle, main, variantList, create } = createMedicalMessagesBreadcrumbs(t);
      setBreadCrumb({ title: variantsTitle, values: [main, variantList(medicalMessageId), create] });
    }
  }, [setBreadCrumb, t, medicalMessageId]);

  return (
    <MedicalMessageVariantUpsertForm
      submitButtonLabel={t("Create")}
      isButtonDisabled={isSubmitting}
      medicalMessageType={medicalMessageType!}
      isLoading={isMedicalMessageLoading}
      onChange={onMedicalMessageVariantUpsertFormChange}
    />
  );
};
