import { FC, useState } from "react";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { GuidedCareTemplateActivationMutation, useGuidedCareTemplateActivationMutation } from "pages/Templates/gql";

type GuidedCareTemplateActivationProps = {
  id: string;
  isActive: boolean;
};

export const GuidedCareTemplateActivation: FC<GuidedCareTemplateActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();

  const [fetchGuidedCareTemplateActivationMutation, { loading: isSubmitting }] = useGuidedCareTemplateActivationMutation({
    onCompleted: (data: GuidedCareTemplateActivationMutation) => {
      const mutationErrors = data?.changeHealthProgramTemplateActiveStatus?.healthProgramTemplateErrors;

      if (mutationErrors?.length) {
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        setActive(!!data?.changeHealthProgramTemplateActiveStatus?.healthProgramTemplate?.active);
        succeeded(t("Template updated successfully"));
      }
    },
    onError: () => {
      failed(t("Template update failed"));
    },
  });

  const handleChangeToggle = () => {
    fetchGuidedCareTemplateActivationMutation({
      variables: { id },
    });
  };

  return <CustomToggleButton disabled={isSubmitting} isLoading={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
