import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareProgramMemberUpsertFormEvent } from "pages/Programs/types";
import { FC, MouseEvent, useRef } from "react";
import {
  GuidedCareProgramMemberUpsertForm,
  GuidedCareProgramMemberUpsertFormRef,
} from "../../forms/ProgramMemberUpsert/GuidedCareProgramMemberUpsertForm";
import { IGuidedCareProgramMemberUpsertFormValues } from "../../forms/ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";

type GuidedCareProgramMemberUpsertModalProps = {
  member?: IGuidedCareProgramMemberUpsertFormValues;
  onChange: (values: IGuidedCareProgramMemberUpsertFormEvent) => void;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareProgramMemberUpsertModal: FC<GuidedCareProgramMemberUpsertModalProps> = props => {
  const { member, onChange, isOpen, onClose: handleClose } = props;

  const { t } = useTranslation("gcadmin");
  const programMemberUpsertFormRef = useRef<GuidedCareProgramMemberUpsertFormRef>(null);

  const onButtonClick = () => {
    programMemberUpsertFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: member ? t("Update Team Member") : t("Add Team Member"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: member ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareProgramMemberUpsertForm member={member} ref={programMemberUpsertFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
