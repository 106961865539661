import { FC, useState } from "react";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useMedicalMessageVariantActivationMutation } from "../../gql";

type MedicalMessageVariantActivationProps = {
  id: string;
  isActive: boolean;
};

export const MedicalMessageVariantActivation: FC<MedicalMessageVariantActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();

  const [medicalMessageVariantActivation, { loading: isSubmitting }] = useMedicalMessageVariantActivationMutation({
    onCompleted: data => {
      const errors = data?.updateMedicalMessageVariantActiveStatus?.medicalMessageVariantErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Medical message variant has been updated successfully"));
        setActive(!!data?.updateMedicalMessageVariantActiveStatus?.medicalMessageVariant?.isActive);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    medicalMessageVariantActivation({ variables: { id } });
  };

  return <CustomToggleButton isLoading={isSubmitting} disabled={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
