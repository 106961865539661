import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { GuidedCareHealthProgram } from "@health/queries/types";
import { guidedCareProgramsPaths } from "pages/Programs/constants";
import {
  GuidedCareProgramGetDocument,
  GuidedCareProgramUpdateMutation,
  useGuidedCareProgramGetQuery,
  useGuidedCareProgramUpdateMutation,
} from "pages/Programs/gql";
import { useGuidedCareProgramsBreadcrumbs } from "pages/Programs/hooks";
import { compareProgramActivities, convertProgramFormValuesToBackEndValues } from "pages/Programs/others";
import { IGuidedCareProgramUpdateContainerParams, IGuidedCareProgramUpsertFormEvent } from "pages/Programs/types";
import { GuidedCareProgramUpsertForm } from "../../forms/ProgramUpsert/GuidedCareProgramUpsertForm";

export const GuidedCareProgramUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { setGuidedCareProgramUpdateContainerBreadcrumb } = useGuidedCareProgramsBreadcrumbs();

  const { programId } = useParams<IGuidedCareProgramUpdateContainerParams>();

  const { data: programData, loading: loadingProgram } = useGuidedCareProgramGetQuery({
    variables: { id: programId! },
    skip: !programId,
    fetchPolicy: "no-cache",
  });

  const program = programData?.guidedCareHealthProgram as GuidedCareHealthProgram;

  const [fetchGuidedCareProgramUpdateMutation, { loading: isSubmitting }] = useGuidedCareProgramUpdateMutation({
    onCompleted: (mutationData: GuidedCareProgramUpdateMutation) => {
      const mutationErrors = mutationData?.guidedCareHealthProgramUpdate?.guidedCareHealthProgramErrors;

      if (!mutationErrors?.length) {
        succeeded(t("Program updated successfully"));
        navigate(guidedCareProgramsPaths.list.fullPath);
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    refetchQueries: [GuidedCareProgramGetDocument],
  });

  const onProgramUpsertFormChange = (event: IGuidedCareProgramUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertProgramFormValuesToBackEndValues(event.payload.values);
      const programActivities = compareProgramActivities(program?.programActivities, values.programActivities);

      fetchGuidedCareProgramUpdateMutation({
        variables: {
          input: {
            ...values,
            programActivities,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (program?.name) {
      setGuidedCareProgramUpdateContainerBreadcrumb(program.name);
    }
  }, [program?.name, setGuidedCareProgramUpdateContainerBreadcrumb]);

  return (
    <GuidedCareProgramUpsertForm
      submitButtonLabel={t("Update")}
      program={program!}
      isLoading={loadingProgram}
      isButtonDisabled={isSubmitting}
      onChange={onProgramUpsertFormChange}
    />
  );
};
