import { CustomTable } from "@toolkit/ui";
import React, { FC } from "react";
import { useGuidedCareTemplateGoalRecommendationInterventionsFieldsColumns } from "./useGuidedCareTemplateGoalRecommendationInterventionsFieldsColumns";
import { IGuidedCareTemplateGoalRecommendationUpsertFormValues } from "../TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";

type GuidedCareTemplateGoalRecommendationInterventionsFieldsProps = {
  interventions: IGuidedCareTemplateGoalRecommendationUpsertFormValues["interventions"];
};

export const GuidedCareTemplateGoalRecommendationInterventionsFields: FC<
  GuidedCareTemplateGoalRecommendationInterventionsFieldsProps
> = props => {
  const { interventions } = props;

  return (
    <CustomTable
      data={interventions}
      columns={useGuidedCareTemplateGoalRecommendationInterventionsFieldsColumns()}
      hasFooter={false}
      scrollable
    />
  );
};
