import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import {
  getGuidedCareActivityTypeFilter,
  getGuidedCareTeamMemberPositionFilter,
  guidedCareActivityTypeOptionsMap,
  guidedCareTeamMemberPositionOptionsMap,
} from "@health/enum-options";
import { H_OrderDirection, TemplateActivityEventItem, TemplateActivityEventItemSortingField } from "@health/queries/types";

export const useGuidedCareTemplateActivitiesItemListColumns = (): CustomTableColumnProps<TemplateActivityEventItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "position",
        header: t("Position"),
        accessor: ({ templateActivity }) => guidedCareTeamMemberPositionOptionsMap[templateActivity?.templateTeamMember?.position!]?.label,
        type: "string",
        filter: getGuidedCareTeamMemberPositionFilter({ name: "position" }),
      },
      {
        key: "activityType",
        header: t("Activity Type"),
        accessor: ({ templateActivity }) => guidedCareActivityTypeOptionsMap[templateActivity?.templateActivityType!]?.label,
        type: "string",
        filter: getGuidedCareActivityTypeFilter({ name: "templateActivityType", multiple: true }),
      },
      {
        key: "offsetInDays",
        header: t("Offset (Days)"),
        accessor: "offsetInDays",
        type: "string",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: TemplateActivityEventItemSortingField.Offset,
      },
      {
        key: "medicalMessageName",
        header: t("Name"),
        accessor: ({ templateActivity }) => templateActivity?.medicalMessage?.name || "-",
        type: "string",
      },
      {
        key: "fromOffset",
        header: t("From Offset (Days)"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "offsetGreaterThanOrEquals",
        },
      },
      {
        key: "toOffset",
        header: t("To Offset (Days)"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "offsetLessThanOrEquals",
        },
      },
    ];
  }, [t]);
};
