import { makeStyles } from "@toolkit/ui";

export const useEmptyDataContentStyle = makeStyles()(theme => ({
  root: {
    position: "relative",
    height: 150,
  },
  absoluteWrapper: {
    position: "absolute",
    textAlign: "center",
    width: 250,
    display: "block",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  noItemsMessage: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.primary.main,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  startAddingDataMessage: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.primary.main,
  },
}));
