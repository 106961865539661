import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateActivityItemUpdateForm,
  GuidedCareTemplateActivityItemUpdateFormRef,
} from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateForm";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

type IGuidedCareTemplateInterventionActivityItemUpdateModalData = {
  activityItem: IGuidedCareTemplateActivityItemUpdateFormValues;
};

type IOpen = (data: IGuidedCareTemplateInterventionActivityItemUpdateModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateInterventionActivityItemUpdateModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateInterventionActivityItemUpdateModalProps = {
  onChange: (values: IGuidedCareTemplateGoalRecommendationInterventionActivityItemUpdateModalEvent) => void;
};

export const GuidedCareTemplateInterventionActivityItemUpdateModal: FC<
  GuidedCareTemplateInterventionActivityItemUpdateModalProps
> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareTemplateInterventionActivityItemUpdateModalData>();

  const { t } = useTranslation("gcadmin");
  const activityItemUpdateFormRef = useRef<GuidedCareTemplateActivityItemUpdateFormRef>(null);

  const onUpdateClick = () => {
    activityItemUpdateFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareTemplateInterventionActivityItemUpdateModalApi.open = open;
    GuidedCareTemplateInterventionActivityItemUpdateModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Update Activity Item"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Update"),
        onSubmit: onUpdateClick,
      }}
    >
      <GuidedCareTemplateActivityItemUpdateForm activityItem={data?.activityItem} ref={activityItemUpdateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
