import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { articlesBreadcrumb } from "../../constants";
import { articlesPaths } from "../../constants";
import { useArticleCreateMutation } from "../../gql";
import { convertArticleFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArticleUpsertForm, IArticleUpsertFormEvent } from "../../forms/ArticleUpsert/ArticleUpsertForm";

export const ArticleCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();

  const [createArticle, { loading: isSubmitting }] = useArticleCreateMutation({
    onCompleted: () => {
      succeeded(t("Article created successfully"));
      navigate(articlesPaths.list.fullPath);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onArticleUpsertFormChange = (event: IArticleUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertArticleFormValuesToBackEndValues(event.payload.values);

      createArticle({
        variables: {
          input: values,
        },
      });
    }
  };

  useEffect(() => {
    const { title, main, create } = articlesBreadcrumb();
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);

  return <ArticleUpsertForm submitButtonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onArticleUpsertFormChange} />;
};
