import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormHelperText, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { HealthConditionsAutocomplete, RiskFactorTemplatesAutocomplete } from "@health/autocompletes";
import { guidedCareTypeOptions } from "@health/enum-options";
import { HealthProgramTemplate } from "@health/queries/types";
import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";

type GuidedCareTemplateInformationFormProps = {
  template?: HealthProgramTemplate;
  isClone?: boolean;
};

export const GuidedCareTemplateInformationForm: FC<GuidedCareTemplateInformationFormProps> = props => {
  const { template, isClone } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const templateDuration = form.watch("templateDuration");
  const isDurationModified = template && !isClone && template?.templateDuration !== templateDuration;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"name"} label={t("Name")} disabled={!!template && !isClone} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthConditionsAutocomplete name={"healthCondition"} filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"guidedCareType"} label={t("Type")} options={guidedCareTypeOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <RiskFactorTemplatesAutocomplete name={"riskFactorTemplate"} filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormNumberField name={"templateDuration"} label={t("Duration (Days)")} />

        {isDurationModified && <FormHelperText error> {t("Please be aware that all activities will be regenerated")} </FormHelperText>}
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormSwitch name={"isRenewable"} label={t("Is Renewable")} />
      </Grid>
    </Grid>
  );
};
