import { z } from "zod";
import { zodSchema } from "@health/domains";

export const guidedCareTemplateActivitiesItemsFilterFormSchema = z.object({
  activityType: zodSchema.guidedCareActivityTypeAutocompleteEnumOptional,
});

export type IGuidedCareTemplateActivitiesItemsFilterFormValues = z.infer<typeof guidedCareTemplateActivitiesItemsFilterFormSchema>;

export const guidedCareTemplateActivitiesItemsFilterFormDefaultValues: Partial<IGuidedCareTemplateActivitiesItemsFilterFormValues> = {
  activityType: undefined,
};
