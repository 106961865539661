import { PageWrapper, PlusIcon, StyledButton, useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { HealthParameter, LabTemplate } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import React from "react";
import { ILabTemplateParameterListContainerParams } from "../../types";
import {
  LabTemplateParameterListDocument,
  useLabTemplateParameterCreateMutation,
  useLabTemplateParameterDeleteMutation,
  useLabTemplateParameterListQuery,
} from "../../gql";
import { labTemplatesPaths } from "../../constants";
import { LabTemplateInformation } from "../../components/LabTemplateInformation/LabTemplateInformation";
import {
  LabTemplateParameterCreateModal,
  LabTemplateParameterCreateModalApi,
} from "../../modals/LabTemplateParameterCreate/LabTemplateParameterCreateModal";
import { ILabTemplateParameterCreateFormEvent } from "../../forms/LabTemplateParameterCreate/LabTemplateParameterCreateForm";
import { useSetLabTemplatesBreadcrumbs } from "../../hooks";
import { HealthParameterCustomTableList } from "../../../HealthParameters/components";

export const LabTemplateParameterListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { labTemplateId } = useParams<ILabTemplateParameterListContainerParams>();

  const { data, loading } = useLabTemplateParameterListQuery({
    variables: {
      id: labTemplateId!,
    },
    skip: !labTemplateId,
  });

  const labTemplate = data?.getLabTemplate as LabTemplate;
  const labTemplateParameters = labTemplate?.fields as HealthParameter[];

  const [createLabTemplateParameter, { loading: isCreating }] = useLabTemplateParameterCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addFieldToLabTemplate?.errors?.length) {
        succeeded(t("Lab Template Parameter added successfully"));
        LabTemplateParameterCreateModalApi.close();
      } else {
        failed(t("Lab Template Parameter add failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: LabTemplateParameterListDocument,
        variables: { id: labTemplateId },
      },
    ],
  });

  const [deleteLabTemplateParameter, { loading: isDeleting }] = useLabTemplateParameterDeleteMutation({
    onCompleted: mutationData => {
      if (!mutationData?.deleteFieldFromLabTemplate?.errors?.length) {
        succeeded(t("Lab Template Parameter deleted successfully"));
      } else {
        failed(t("Lab Template Parameter delete failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: LabTemplateParameterListDocument,
        variables: { id: labTemplateId },
      },
    ],
  });

  const onAddNewItemClick = () => {
    LabTemplateParameterCreateModalApi.open();
  };

  const onDeleteRowClick = (item: HealthParameter) => {
    deleteLabTemplateParameter({
      variables: {
        labTemplateId: labTemplateId!,
        healthParameterId: item?.id!,
      },
    });
  };

  const onCancelClick = () => {
    navigate(labTemplatesPaths.list.fullPath);
  };

  const onLabTemplateParameterCreateModalChange = (event: ILabTemplateParameterCreateFormEvent) => {
    if (event.type === "CREATE") {
      createLabTemplateParameter({
        variables: {
          labTemplateId: labTemplateId!,
          healthParameterId: event.payload.values.healthParameter?.value?.id,
        },
      });
    }
  };

  useSetLabTemplatesBreadcrumbs("PARAMETERS_LIST", pickLocalizedValue(labTemplate?.display!, labTemplate?.arabicDisplay!));

  return (
    <>
      <LabTemplateParameterCreateModal isLoading={isCreating} onChange={onLabTemplateParameterCreateModalChange} />

      <PageWrapper
        actions={
          <>
            <StyledButton color={"success"} startIcon={<PlusIcon />} onClick={onAddNewItemClick}>
              {t("Add New")}
            </StyledButton>

            <StyledButton variant={"outlined"} onClick={onCancelClick}>
              {t("Cancel")}
            </StyledButton>
          </>
        }
      >
        <LabTemplateInformation labTemplate={labTemplate} isLoading={loading} />

        <HealthParameterCustomTableList
          healthParameters={labTemplateParameters}
          isLoading={loading}
          isRowDeletable={!isDeleting}
          onDeleteRow={onDeleteRowClick}
        />
      </PageWrapper>
    </>
  );
};
