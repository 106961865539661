import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormStyle = makeStyles()(theme => ({
  root: {
    paddingInline: 4,
    alignItems: "center",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  divider: {
    width: "100%",
    marginBlock: 12,
    borderBottomWidth: 2,
  },
  classificationName: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  rangeScoreValues: {
    display: "flex",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
