import {
  IRiskFactorTemplateUpsertFormEvent,
  RiskFactorTemplateUpsertForm,
} from "../../forms/RiskFactorTemplateUpsert/RiskFactorTemplateUpsertForm";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useRiskFactorTemplateCreateMutation } from "../../gql";
import { riskFactorTemplatesPaths } from "../../constants";
import { convertRiskFactorTemplateFormValuesToBackEndValues } from "../../others/utils";
import { useSetRiskFactorTemplatesBreadcrumbs } from "../../hooks";

export const RiskFactorTemplateCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createRiskFactorTemplate, { loading: isSubmitting }] = useRiskFactorTemplateCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addRiskFactorTemplate?.errors?.length) {
        succeeded(t("Risk Factor Template created successfully"));
        navigate(riskFactorTemplatesPaths.list.fullPath);
      } else {
        failed(t("Risk Factor Template create failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onRiskFactorTemplateUpsertFormChange = (event: IRiskFactorTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertRiskFactorTemplateFormValuesToBackEndValues(event.payload.values);

      createRiskFactorTemplate({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetRiskFactorTemplatesBreadcrumbs("CREATE");

  return (
    <RiskFactorTemplateUpsertForm
      submitButtonLabel={t("Create")}
      isButtonDisabled={isSubmitting}
      onChange={onRiskFactorTemplateUpsertFormChange}
    />
  );
};
