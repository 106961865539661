import { useCallback, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { guidedCareProgramsPaths } from "pages/Programs/constants";

export const useGuidedCareProgramsBreadcrumbs = () => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Guided Care Programs");

  const programListBreadcrumb = useMemo(
    () => ({
      name: t("Programs"),
      route: guidedCareProgramsPaths.list.fullPath,
    }),
    [t]
  );

  const setGuidedCareProgramListContainerBreadcrumb = useCallback(() => {
    const guidedCareProgramListContainerBreadcrumb = {
      title: breadcrumbTitle,
    };

    setBreadCrumb(guidedCareProgramListContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb]);

  const setGuidedCareProgramCreateContainerBreadcrumb = useCallback(() => {
    const guidedCareProgramCreateContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [programListBreadcrumb, { name: t("New") }],
    };

    setBreadCrumb(guidedCareProgramCreateContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, programListBreadcrumb, t]);

  const setGuidedCareProgramUpdateContainerBreadcrumb = useCallback(
    (programName: string) => {
      const guidedCareProgramUpdateContainerBreadcrumb = {
        title: breadcrumbTitle,
        values: [programListBreadcrumb, { name: programName }],
      };

      setBreadCrumb(guidedCareProgramUpdateContainerBreadcrumb);
    },
    [breadcrumbTitle, setBreadCrumb, programListBreadcrumb]
  );

  const setGuidedCareProgramTeamListContainerBreadcrumb = useCallback(() => {
    const guidedCareProgramTeamListContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [programListBreadcrumb, { name: t("Teams") }],
    };

    setBreadCrumb(guidedCareProgramTeamListContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, programListBreadcrumb, t]);

  const setGuidedCareProgramActivityItemListContainerBreadcrumb = useCallback(() => {
    const guidedCareProgramActivityItemsContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [programListBreadcrumb, { name: t("Activity Items") }],
    };

    setBreadCrumb(guidedCareProgramActivityItemsContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, programListBreadcrumb, t]);

  return {
    setGuidedCareProgramListContainerBreadcrumb,
    setGuidedCareProgramCreateContainerBreadcrumb,
    setGuidedCareProgramUpdateContainerBreadcrumb,
    setGuidedCareProgramActivityItemListContainerBreadcrumb,
    setGuidedCareProgramTeamListContainerBreadcrumb,
  };
};
