import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { HealthProgramTemplate } from "@health/queries/types";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";
import { useGuidedCareTemplateCreateMutation, useGuidedCareTemplateGetQuery } from "pages/Templates/gql";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { convertTemplateFormValuesToBackEndValues } from "pages/Templates/others";
import { IGuidedCareTemplateUpsertFormEvent, ITemplateCloneContainerParams } from "pages/Templates/types";
import { GuidedCareTemplateUpsertForm } from "../../forms/TemplateUpsert/GuidedCareTemplateUpsertForm";

export const GuidedCareTemplateClone = () => {
  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { setGuidedCareTemplateUpdateContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const { templateId } = useParams<ITemplateCloneContainerParams>();

  const { data, loading } = useGuidedCareTemplateGetQuery({
    variables: { id: templateId! },
    skip: !templateId,
  });

  const template = data?.healthProgramTemplate as HealthProgramTemplate;

  const [createGuidedCareTemplateMutation, { loading: isSubmitting }] = useGuidedCareTemplateCreateMutation({
    onCompleted: () => {
      succeeded(t("Template cloned successfully"));
      navigate(guidedCareTemplatesPaths.list.fullPath);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onTemplateUpsertFormChange = (event: IGuidedCareTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertTemplateFormValuesToBackEndValues(event.payload.values, template, true);

      createGuidedCareTemplateMutation({
        variables: {
          input: values,
        },
      });
    }
  };

  useEffect(() => {
    if (template?.name) {
      setGuidedCareTemplateUpdateContainerBreadcrumb(template.name);
    }
  }, [template?.name, setGuidedCareTemplateUpdateContainerBreadcrumb]);

  return (
    <GuidedCareTemplateUpsertForm
      submitButtonLabel={t("Clone")}
      isLoading={loading}
      isClone
      isButtonDisabled={isSubmitting}
      template={template!}
      onChange={onTemplateUpsertFormChange}
    />
  );
};
