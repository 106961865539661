/* eslint-disable max-lines */
import { Button, Grid, PlusIcon, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useGuidedCareTemplateGoalsStyle } from "./useGuidedCareTemplateGoalsStyle";
import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import {
  GuidedCareTemplateGoalUpsertModal,
  GuidedCareTemplateGoalUpsertModalApi,
} from "../../modals/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertModal";
import {
  IGuidedCareTemplateGoalFormUpsertFormEvent,
  IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent,
  IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent,
} from "../../types";
import React, { useState } from "react";
import {
  GuidedCareTemplateGoalRecommendationUpsertModal,
  GuidedCareTemplateGoalRecommendationUpsertModalApi,
} from "../../modals/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertModal";
import {
  GuidedCareTemplateGoalDeleteConfirmationModal,
  GuidedCareTemplateGoalDeleteConfirmationModalApi,
  IGuidedCareTemplateGoalDeleteConfirmationModalEvent,
} from "../../modals/TemplateGoalDeleteConfirmation/GuidedCareTemplateGoalDeleteConfirmationModal";
import {
  GuidedCareTemplateGoalRecommendationInterventionUpsertModal,
  GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi,
} from "../../modals/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertModal";
import { GuidedCareTemplateGoalsFields, IGuidedCareTemplateGoalsFieldsEvent } from "../TemplateGoalsFields/GuidedCareTemplateGoalsFields";
import {
  createNewTemplateRecommendation,
  removeTemplateGoal,
  removeTemplateRecommendation,
  updateTemplateRecommendation,
  updateTemplateRecommendationIntervention,
} from "../../others";
import { GuidedCareTemplateGoalRecommendationInterventionActivitiesModal } from "../../modals/TemplateGoalRecommendationInterventionActivities/GuidedCareTemplateGoalRecommendationInterventionActivitiesModal";

export const GuidedCareTemplateGoals = () => {
  const [selectedGoalIndex, setSelectedGoalIndex] = useState<number>();
  const [selectedRecommendationIndex, setSelectedRecommendationIndex] = useState<number>();

  const { t } = useTranslation("gcadmin");

  const { classes } = useGuidedCareTemplateGoalsStyle();

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();
  const { watch, setValue } = form;

  const goals = watch("goals");

  const onAddGoalClick = () => {
    GuidedCareTemplateGoalUpsertModalApi.open();
  };

  const onGuidedCareTemplateGoalsFieldsChange = (event: IGuidedCareTemplateGoalsFieldsEvent) => {
    const { type } = event;

    if (type === "RECOMMENDATION_ADD" || type === "GOAL_UPDATE" || type === "GOAL_DELETE") {
      const { goalIndex } = event.payload;

      setSelectedGoalIndex(goalIndex);

      if (type === "RECOMMENDATION_ADD") {
        GuidedCareTemplateGoalRecommendationUpsertModalApi.open();
      } else if (type === "GOAL_UPDATE") {
        GuidedCareTemplateGoalUpsertModalApi.open({ goal: goals[goalIndex] });
      } else if (type === "GOAL_DELETE") {
        GuidedCareTemplateGoalDeleteConfirmationModalApi.open({ type: "GOAL" });
      }
    } else if (type === "INTERVENTION_UPDATE" || type === "RECOMMENDATION_UPDATE" || type === "RECOMMENDATION_DELETE") {
      const { goalIndex, recommendationIndex } = event.payload;

      setSelectedGoalIndex(goalIndex);
      setSelectedRecommendationIndex(recommendationIndex);

      if (type === "INTERVENTION_UPDATE") {
        GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.open({
          intervention: {
            interventions: goals?.[goalIndex!].recommendations?.[recommendationIndex]?.interventions,
          },
        });
      } else if (type === "RECOMMENDATION_UPDATE") {
        GuidedCareTemplateGoalRecommendationUpsertModalApi.open({
          recommendation: goals?.[goalIndex!].recommendations?.[recommendationIndex],
        });
      } else if (type === "RECOMMENDATION_DELETE") {
        GuidedCareTemplateGoalDeleteConfirmationModalApi.open({ type: "RECOMMENDATION" });
      }
    }
  };

  const onGuidedCareTemplateGoalUpsertModalChange = (event: IGuidedCareTemplateGoalFormUpsertFormEvent) => {
    if (event.type === "CREATE") {
      const newGoals = [...goals, event.payload.values];
      setValue("goals", newGoals);
    } else if (event.type === "UPDATE") {
      const updatedGoals = goals.map((goal, index) => (index === selectedGoalIndex ? event.payload.values : goal));
      setValue("goals", updatedGoals);
    }

    GuidedCareTemplateGoalUpsertModalApi.close();
  };

  const onGuidedCareTemplateGoalRecommendationUpsertModalChange = (event: IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent) => {
    if (event.type === "CREATE") {
      const updatedGoals = createNewTemplateRecommendation(goals, selectedGoalIndex!, event.payload.values);
      setValue("goals", updatedGoals);
    } else if (event.type === "UPDATE") {
      const updatedGoals = updateTemplateRecommendation(goals, selectedGoalIndex!, selectedRecommendationIndex!, event.payload.values);
      setValue("goals", updatedGoals);
    }

    GuidedCareTemplateGoalRecommendationUpsertModalApi.close();
  };

  const onGuidedCareTemplateGoalRecommendationInterventionUpsertModalChange = (
    event: IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent
  ) => {
    if (event.type === "UPDATE") {
      const updatedGoals = updateTemplateRecommendationIntervention(
        goals,
        selectedGoalIndex!,
        selectedRecommendationIndex!,
        event.payload.values
      );
      setValue("goals", updatedGoals);
    }

    GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.close();
  };

  const onGuidedCareTemplateGoalDeleteConfirmationModalChange = (event: IGuidedCareTemplateGoalDeleteConfirmationModalEvent) => {
    if (event.type === "GOAL_DELETE") {
      const updatedGoals = removeTemplateGoal(goals, selectedGoalIndex!);
      setValue("goals", updatedGoals);
    } else if (event.type === "RECOMMENDATION_DELETE") {
      const updatedGoals = removeTemplateRecommendation(goals, selectedGoalIndex!, selectedRecommendationIndex!);
      setValue("goals", updatedGoals);
    }

    GuidedCareTemplateGoalDeleteConfirmationModalApi.close();
  };

  return (
    <Grid container spacing={2}>
      <GuidedCareTemplateGoalUpsertModal onChange={onGuidedCareTemplateGoalUpsertModalChange} />
      <GuidedCareTemplateGoalRecommendationUpsertModal onChange={onGuidedCareTemplateGoalRecommendationUpsertModalChange} />
      <GuidedCareTemplateGoalRecommendationInterventionUpsertModal
        onChange={onGuidedCareTemplateGoalRecommendationInterventionUpsertModalChange}
      />
      <GuidedCareTemplateGoalRecommendationInterventionActivitiesModal />
      <GuidedCareTemplateGoalDeleteConfirmationModal onChange={onGuidedCareTemplateGoalDeleteConfirmationModalChange} />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography className={classes.title}> {t("Template Goals")} </Typography>
          </Grid>

          <Grid xs={4} lg={2}>
            <Button startIcon={<PlusIcon />} fullWidth onClick={onAddGoalClick}>
              {t("Add Goal")}
            </Button>
          </Grid>

          <Grid xs={12} className={classes.fieldsWrapper}>
            <GuidedCareTemplateGoalsFields goals={goals} onChange={onGuidedCareTemplateGoalsFieldsChange} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
