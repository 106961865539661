import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  EditIcon,
  IconButton,
  MuiDeleteForeverIcon,
  MuiExpandMoreIcon,
  PlusIcon,
  Tooltip,
  Typography,
} from "@toolkit/ui";
import React, { FC, MouseEvent, PropsWithChildren } from "react";
import { useGuidedCareTemplateCustomAccordionStyle } from "./useGuidedCareTemplateCustomAccordionStyle";

export type IGuidedCareTemplateCustomAccordionEvent = {
  type: "ADD_CLICK" | "UPDATE_CLICK" | "DELETE_CLICK";
};

type GuidedCareTemplateCustomAccordionProps = PropsWithChildren<{
  title: string;
  description?: string;
  tooltipMessage?: string;
  onChange: (event: IGuidedCareTemplateCustomAccordionEvent) => void;
}>;

export const GuidedCareTemplateCustomAccordion: FC<GuidedCareTemplateCustomAccordionProps> = props => {
  const { title, description, tooltipMessage, onChange } = props;

  const { classes, theme } = useGuidedCareTemplateCustomAccordionStyle();

  const onAddClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange({ type: "ADD_CLICK" });
  };

  const onUpdateClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange({ type: "UPDATE_CLICK" });
  };

  const onDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange({ type: "DELETE_CLICK" });
  };

  return (
    <Accordion className={classes.accordion} elevation={0} disableGutters>
      <AccordionSummary expandIcon={<MuiExpandMoreIcon />} className={classes.accordionSummary}>
        <Box className={classes.titleWrapper}>
          <Typography className={classes.title}> {title} </Typography>
          {description && <Typography variant={"body2"}>{description}</Typography>}
        </Box>

        <Tooltip title={tooltipMessage || ""}>
          <IconButton color={"primary"} onClick={onAddClick}>
            <PlusIcon fill={theme.palette.primary.main} fontSize={"medium"} />
          </IconButton>
        </Tooltip>

        <IconButton color={"primary"} onClick={onUpdateClick}>
          <EditIcon fontSize={"small"} />
        </IconButton>

        <IconButton onClick={onDeleteClick} color={"primary"}>
          <MuiDeleteForeverIcon fontSize={"medium"} />
        </IconButton>
      </AccordionSummary>

      {props.children && <AccordionDetails className={classes.accordionDetails}> {props.children} </AccordionDetails>}
    </Accordion>
  );
};
