/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramTemplateInput;
}>;


export type GuidedCareTemplateCreateMutation = { __typename?: 'Mutation', healthProgramTemplateCreate?: { __typename?: 'HealthProgramTemplateCRUD', healthProgramTemplateErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTemplateCreateDocument = gql`
    mutation GuidedCareTemplateCreate($input: HealthProgramTemplateInput!) {
  healthProgramTemplateCreate(input: $input) {
    healthProgramTemplateErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTemplateCreateMutationFn = Apollo.MutationFunction<GuidedCareTemplateCreateMutation, GuidedCareTemplateCreateMutationVariables>;

/**
 * __useGuidedCareTemplateCreateMutation__
 *
 * To run a mutation, you first call `useGuidedCareTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTemplateCreateMutation, { data, loading, error }] = useGuidedCareTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareTemplateCreateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTemplateCreateMutation, GuidedCareTemplateCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTemplateCreateMutation, GuidedCareTemplateCreateMutationVariables>(GuidedCareTemplateCreateDocument, options);
      }
export type GuidedCareTemplateCreateMutationHookResult = ReturnType<typeof useGuidedCareTemplateCreateMutation>;
export type GuidedCareTemplateCreateMutationResult = Apollo.MutationResult<GuidedCareTemplateCreateMutation>;
export type GuidedCareTemplateCreateMutationOptions = Apollo.BaseMutationOptions<GuidedCareTemplateCreateMutation, GuidedCareTemplateCreateMutationVariables>;