import React, { FC, PropsWithChildren } from "react";
import { GridProvider } from "@health/domains";
import { H_OrderDirection, TemplateActivityEventItemSortingField } from "@health/queries/types";
import { useGuidedCareTemplateActivitiesItemListQuery } from "pages/Templates/gql";
import { useGuidedCareTemplateActivitiesItemListColumns } from "./useGuidedCareTemplateActivitiesItemListColumns";

type GuidedCareTemplateActivitiesItemListProps = PropsWithChildren<{
  templateId: string;
  minOffsetInDays?: number;
  maxOffsetInDays?: number;
}>;

export const GuidedCareTemplateActivitiesItemList: FC<GuidedCareTemplateActivitiesItemListProps> = props => {
  const { templateId, minOffsetInDays, maxOffsetInDays, children } = props;

  return (
    <GridProvider
      gridName={"guidedCareTemplateActivitiesItemList"}
      columns={useGuidedCareTemplateActivitiesItemListColumns()}
      query={useGuidedCareTemplateActivitiesItemListQuery}
      hasTableSetting
      filterInput={{ offsetGreaterThanOrEquals: minOffsetInDays, offsetLessThanOrEquals: maxOffsetInDays }}
      variables={{
        filter: { templateId },
        sortBy: {
          field: TemplateActivityEventItemSortingField.Offset,
          direction: H_OrderDirection.Asc,
        },
      }}
      skipCall={!templateId}
    >
      {children}
    </GridProvider>
  );
};
