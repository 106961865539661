/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessagePublishMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type MedicalMessagePublishMutation = { __typename?: 'Mutation', updateMedicalMessagePublishStatus?: { __typename?: 'MedicalMessageCRUD', medicalMessage?: { __typename?: 'MedicalMessage', isPublished?: boolean | null } | null, medicalMessageErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const MedicalMessagePublishDocument = gql`
    mutation MedicalMessagePublish($id: ID!) {
  updateMedicalMessagePublishStatus(id: $id) {
    medicalMessage {
      isPublished
    }
    medicalMessageErrors {
      field
      message
    }
  }
}
    `;
export type MedicalMessagePublishMutationFn = Apollo.MutationFunction<MedicalMessagePublishMutation, MedicalMessagePublishMutationVariables>;

/**
 * __useMedicalMessagePublishMutation__
 *
 * To run a mutation, you first call `useMedicalMessagePublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessagePublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalMessagePublishMutation, { data, loading, error }] = useMedicalMessagePublishMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicalMessagePublishMutation(baseOptions?: Apollo.MutationHookOptions<MedicalMessagePublishMutation, MedicalMessagePublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalMessagePublishMutation, MedicalMessagePublishMutationVariables>(MedicalMessagePublishDocument, options);
      }
export type MedicalMessagePublishMutationHookResult = ReturnType<typeof useMedicalMessagePublishMutation>;
export type MedicalMessagePublishMutationResult = Apollo.MutationResult<MedicalMessagePublishMutation>;
export type MedicalMessagePublishMutationOptions = Apollo.BaseMutationOptions<MedicalMessagePublishMutation, MedicalMessagePublishMutationVariables>;