import { createPathDefinition } from "@toolkit/core";

export const labTemplatesRoute = `/lab-templates`;

export const labTemplatesPaths = createPathDefinition(labTemplatesRoute, {
  list: "",
  create: "new",
  update: ":labTemplateId",
  clone: ":labTemplateId/clone",
  parameterList: ":labTemplateId/parameters",
});
