import { makeStyles } from "@toolkit/ui";

export const useHealthParameterDefaultRangeValuesStyle = makeStyles()(theme => ({
  root: {
    marginInline: 10,
  },
  numberWrapper: {
    marginBlock: 10,
  },
  label: {
    display: "flex",
    gap: 1,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
