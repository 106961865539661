import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { GridProvider } from "@health/domains";
import { useGuidedCareProgramTeamListQuery } from "pages/Programs/gql";
import { useGuidedCareProgramsBreadcrumbs } from "pages/Programs/hooks";
import { IGuidedCareProgramTeamsContainerParams } from "pages/Programs/types";
import { useGuidedCareProgramTeamListColumns } from "./useGuidedCareProgramTeamListColumns";

export const GuidedCareProgramTeamListContainer = () => {
  const { setGuidedCareProgramTeamListContainerBreadcrumb } = useGuidedCareProgramsBreadcrumbs();

  const { programId } = useParams<IGuidedCareProgramTeamsContainerParams>();

  useEffect(() => {
    setGuidedCareProgramTeamListContainerBreadcrumb();
  }, [setGuidedCareProgramTeamListContainerBreadcrumb]);

  return (
    <GridProvider
      gridName={"guidedCareProgramTeamList"}
      columns={useGuidedCareProgramTeamListColumns()}
      filterInput={{ guidedCareHealthProgramId: programId! }}
      query={useGuidedCareProgramTeamListQuery}
      hasTableSetting
      tableAction={{
        isEditVisible: false,
        isDeleteVisible: false,
      }}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
