import { FC, SyntheticEvent, useState } from "react";
import { Controller } from "react-hook-form";
import { useFormChipsInputStyle } from "./useFormChipsInputStyle";
import { get } from "lodash";
import React from "react";
import { useCustomFormContext } from "@toolkit/core";
import { AutocompleteProps } from "../../../types";
import { Autocomplete, Chip, IconButton, TextField } from "@mui/material";
import { CustomIcon } from "../../CustomIcon";

type IFormChipsInputFieldOnChange = (value: string[]) => void;

type FormChipsInputProps = Omit<AutocompleteProps, "defaultValue" | "label"> & {
  name: string;
};

export const FormChipsInput: FC<FormChipsInputProps> = props => {
  const { name, placeholder, disabled, ...rest } = props;

  const [inputValue, setInputValue] = useState("");

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  const { classes } = useFormChipsInputStyle();

  const handleChange = (fieldOnChange: IFormChipsInputFieldOnChange) => (event: SyntheticEvent, newValue: string[]) => {
    fieldOnChange(newValue);
  };

  const handleInputChange = (event: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleAddChip = (fieldValue: string[], fieldOnChange: IFormChipsInputFieldOnChange) => {
    const trimmedInput = inputValue.trim();

    if (trimmedInput && !fieldValue.includes(trimmedInput)) {
      fieldOnChange([...fieldValue, trimmedInput]);
      setInputValue("");
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...rest}
          multiple
          freeSolo
          options={[]}
          disableClearable
          value={field.value}
          onChange={handleChange(field.onChange)}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          disabled={isFormDisabled || disabled}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip variant={"outlined"} label={option} key={key} {...tagProps} />;
            })
          }
          renderInput={params => (
            <TextField
              {...params}
              error={!!error}
              helperText={error}
              variant={"filled"}
              placeholder={placeholder}
              className={classes.autocompleteInput}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <IconButton disabled={isFormDisabled || disabled} onClick={() => handleAddChip(field.value, field.onChange)}>
                      <CustomIcon icon={"add"} />
                    </IconButton>

                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
