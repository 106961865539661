export type IHealthParameterUpdateContainerParams = {
  healthParameterId: string;
};

export type IHealthParameterDecisionCreateContainerParams = {
  healthParameterId: string;
};

export type IHealthParameterDecisionUpdateContainerParams = {
  healthParameterId: string;
};

export enum DecisionPlanCode {
  HealthParameterReference = "HEALTH_PARAMETER_REFERENCE",
}
