/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCreateMutationVariables = Types.Exact<{
  input: Types.ArticleInput;
}>;


export type ArticleCreateMutation = { __typename?: 'Mutation', articleCreate?: { __typename?: 'Article', id: string } | null };


export const ArticleCreateDocument = gql`
    mutation ArticleCreate($input: ArticleInput!) {
  articleCreate(input: $input) {
    id
  }
}
    `;
export type ArticleCreateMutationFn = Apollo.MutationFunction<ArticleCreateMutation, ArticleCreateMutationVariables>;

/**
 * __useArticleCreateMutation__
 *
 * To run a mutation, you first call `useArticleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCreateMutation, { data, loading, error }] = useArticleCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleCreateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleCreateMutation, ArticleCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleCreateMutation, ArticleCreateMutationVariables>(ArticleCreateDocument, options);
      }
export type ArticleCreateMutationHookResult = ReturnType<typeof useArticleCreateMutation>;
export type ArticleCreateMutationResult = Apollo.MutationResult<ArticleCreateMutation>;
export type ArticleCreateMutationOptions = Apollo.BaseMutationOptions<ArticleCreateMutation, ArticleCreateMutationVariables>;