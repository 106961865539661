import { z } from "zod";
import { guidedCareTemplateInterventionActivityUpsertFormSchema } from "../TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertFormSchema";

export const guidedCareTemplateInterventionUpsertFormSchema = z.object({
  interventionId: z.string().nullable().optional(),
  uniqueId: z.string().nullable().optional(),
  name: z.string(),
  nameAr: z.string().nullable().optional(),
  code: z.string(),
  duration: z.number(),
  category: z.string(),
  description: z.string(),
  descriptionAr: z.string().nullable().optional(),
  activities: z.array(guidedCareTemplateInterventionActivityUpsertFormSchema),
});

export type IGuidedCareTemplateInterventionUpsertFormValues = z.infer<typeof guidedCareTemplateInterventionUpsertFormSchema>;

export const guidedCareTemplateInterventionUpsertFormDefaultValues: Partial<IGuidedCareTemplateInterventionUpsertFormValues> = {
  interventionId: undefined,
  uniqueId: undefined,
  name: undefined,
  nameAr: undefined,
  code: undefined,
  duration: undefined,
  category: undefined,
  description: undefined,
  descriptionAr: undefined,
  activities: [],
};
