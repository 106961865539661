import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, Grid, MDTextEditor, ShowButton } from "@toolkit/ui";
import { FC } from "react";

type ArticlePreviewProps = {
  title: string;
  description: string;
  content: string;
  contentAr: string;
};

export const ArticlePreview: FC<ArticlePreviewProps> = props => {
  const { title, description, content, contentAr } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog type={"info"} button={<ShowButton />} title={title}>
      <Grid container>
        <Grid item xs={12}>
          <MDTextEditor label={t("Description")} value={description} readonly />
        </Grid>

        <Grid item xs={12}>
          <MDTextEditor label={t("Content (English)")} value={content} readonly />
        </Grid>

        <Grid item xs={12}>
          <MDTextEditor label={t("Content (Arabic)")} value={contentAr} readonly direction='rtl' />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
