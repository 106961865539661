import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import { Button } from "@toolkit/ui";
import { guidedCareProgramsPaths } from "pages/Programs/constants";

type GuidedCareProgramActivityItemsManageProps = {
  activityId?: string;
  isDisabled: boolean;
};

export const GuidedCareProgramActivityItemsManage: FC<GuidedCareProgramActivityItemsManageProps> = props => {
  const { activityId, isDisabled } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onManageClick = () => {
    if (!activityId) return;
    navigate(guidedCareProgramsPaths.activityItemList.fullPathWithParams({ activityId }));
  };

  return (
    <Button onClick={onManageClick} disabled={isDisabled}>
      {t("Manage")}
    </Button>
  );
};
