import { ProviderGuidedCareProgramTeamMember } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog } from "@toolkit/ui";
import { IGuidedCareProgramTeamMemberListModalEvent } from "pages/Programs/types";
import { FC, MouseEvent } from "react";
import { GuidedCareProgramTeamMemberList } from "../../components/ProgramTeamMemberList/GuidedCareProgramTeamMemberList";

type GuidedCareProgramTeamMemberListModalProps = {
  teamMembers: ProviderGuidedCareProgramTeamMember[];
  isLoading?: boolean;
  onChange: (event: IGuidedCareProgramTeamMemberListModalEvent) => void;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareProgramTeamMemberListModal: FC<GuidedCareProgramTeamMemberListModalProps> = props => {
  const { teamMembers, onChange, isLoading, isOpen, onClose: handleClose } = props;

  const { t } = useTranslation("gcadmin");

  const onApproveClick = () => {
    onChange({
      type: "CHANGE",
      payload: {
        isApprove: true,
      },
    });
  };

  const onRejectClick = () => {
    onChange({
      type: "CHANGE",
      payload: {
        isApprove: false,
      },
    });
  };

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: t("Team Members"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        children: (
          <Box>
            <Button onClick={onApproveClick} disabled={isLoading}>
              {t("Approve")}
            </Button>
            <Button color={"error"} onClick={onRejectClick} disabled={isLoading}>
              {t("Reject")}
            </Button>
          </Box>
        ),
      }}
    >
      <GuidedCareProgramTeamMemberList teamMembers={teamMembers} />
    </CustomDialog>
  );
};
