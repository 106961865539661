import { z } from "zod";
import { zodSchema } from "@health/domains";

export const guidedCareTemplateActivitiesItemsFormSchema = z.object({
  activitiesItems: z.array(
    z.object({
      id: z.string(),
      activityId: z.string(),
      activityType: zodSchema.guidedCareActivityTypeAutocompleteEnum,
      medicalMessage: zodSchema.medicalMessageAutocompleteOptional,
      offset: z.coerce.number().min(0),
      beforeActivity: zodSchema.medicalMessageAutocompleteOptional,
      afterActivity: zodSchema.medicalMessageAutocompleteOptional,
      beforeActivityOffset: z.coerce.number().min(0).nullable().optional(),
      afterActivityOffset: z.coerce.number().min(0).nullable().optional(),
    })
  ),
});

export type IGuidedCareTemplateActivitiesItemsFormValues = z.infer<typeof guidedCareTemplateActivitiesItemsFormSchema>;

export const guidedCareTemplateActivitiesItemsFormDefaultValues: Partial<IGuidedCareTemplateActivitiesItemsFormValues> = {
  activitiesItems: [],
};
