/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionDiagnosisListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthConditionDiagnosesFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthConditionDiagnosesSortingInput>;
}>;


export type HealthConditionDiagnosisListQuery = { __typename?: 'Query', getHealthConditionDiagnoses?: { __typename?: 'HealthConditionDiagnosisConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null, edges?: Array<{ __typename?: 'HealthConditionDiagnosisEdge', node?: { __typename?: 'HealthConditionDiagnosis', id?: string | null, diagnosisCode?: string | null, diagnosisDisplay?: string | null } | null } | null> | null } | null };


export const HealthConditionDiagnosisListDocument = gql`
    query HealthConditionDiagnosisList($first: Int, $last: Int, $before: String, $after: String, $filter: HealthConditionDiagnosesFilterInput, $sortBy: HealthConditionDiagnosesSortingInput) {
  getHealthConditionDiagnoses(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        diagnosisCode
        diagnosisDisplay
      }
    }
  }
}
    `;

/**
 * __useHealthConditionDiagnosisListQuery__
 *
 * To run a query within a React component, call `useHealthConditionDiagnosisListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionDiagnosisListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthConditionDiagnosisListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthConditionDiagnosisListQuery(baseOptions?: Apollo.QueryHookOptions<HealthConditionDiagnosisListQuery, HealthConditionDiagnosisListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthConditionDiagnosisListQuery, HealthConditionDiagnosisListQueryVariables>(HealthConditionDiagnosisListDocument, options);
      }
export function useHealthConditionDiagnosisListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthConditionDiagnosisListQuery, HealthConditionDiagnosisListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthConditionDiagnosisListQuery, HealthConditionDiagnosisListQueryVariables>(HealthConditionDiagnosisListDocument, options);
        }
export type HealthConditionDiagnosisListQueryHookResult = ReturnType<typeof useHealthConditionDiagnosisListQuery>;
export type HealthConditionDiagnosisListLazyQueryHookResult = ReturnType<typeof useHealthConditionDiagnosisListLazyQuery>;
export type HealthConditionDiagnosisListQueryResult = Apollo.QueryResult<HealthConditionDiagnosisListQuery, HealthConditionDiagnosisListQueryVariables>;