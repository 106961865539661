import { getAutocompleteEnumFilter } from "@toolkit/ui";
import { guidedCareTeamMemberPositionOptions, guidedCareTeamMemberNonManagementOptions, guidedCareTeamMemberWithSpecializationOptions, } from "./GuidedCareTeamMemberPosition";
export const isGuidedCareTeamMemberNonManagement = (teamMember) => {
    return !!guidedCareTeamMemberNonManagementOptions.find(item => (item === null || item === void 0 ? void 0 : item.value) === teamMember);
};
export const doesGuidedCareTeamMemberHaveSpecialization = (teamMember) => {
    return !!guidedCareTeamMemberWithSpecializationOptions.find(item => (item === null || item === void 0 ? void 0 : item.value) === teamMember);
};
export const getGuidedCareTeamMemberPositionFilter = ({ name, multiple }) => {
    return getAutocompleteEnumFilter({
        name,
        multiple,
        options: guidedCareTeamMemberPositionOptions,
    });
};
