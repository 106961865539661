import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { Article } from "@health/queries/types";
import { ArticleInformationForm } from "../ArticleInformation/ArticleInformationForm";
import { convertArticleToFormValues } from "../../utils";
import { useNavigate } from "react-router-dom";
import { articlesPaths } from "../../constants";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { ArticleContentsForm } from "../ArticleContents/ArticleContentsForm";
import { articleUpsertFormSchema, articleUpsertFormSchemaDefaultValues, IArticleUpsertFormValues } from "./ArticleUpsertFormSchema";
import { ArticleReferencesForm } from "../ArticleReferences/ArticleReferencesForm";

export type IArticleUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IArticleUpsertFormValues;
  };
};

type ArticleUpsertFormProps = {
  article?: Article;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (event: IArticleUpsertFormEvent) => void;
};

export const ArticleUpsertForm: FC<ArticleUpsertFormProps> = props => {
  const { article, isLoading, isSubmitting, submitButtonLabel, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IArticleUpsertFormValues>({
    defaultValues: articleUpsertFormSchemaDefaultValues,
    schema: articleUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IArticleUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(articlesPaths.list.fullPath);
  };

  useEffect(() => {
    if (article) {
      const _article = convertArticleToFormValues(article);
      setValues(_article);
    }
  }, [article, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              newButtonDisabled={isLoading || isSubmitting}
              onNavigation={handleCancel}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Article Information")} loading={isLoading} doYouHaveData>
                <ArticleInformationForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Contents")} loading={isLoading} doYouHaveData>
                <ArticleContentsForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Header Image")} loading={isLoading} doYouHaveData>
                <ImageUploadController control={form.control} name={"headerImage"} token={accessToken!} />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("References")} loading={isLoading} doYouHaveData>
                <ArticleReferencesForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
