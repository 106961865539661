import { HealthConditionsAutocomplete } from "@health/autocompletes";
import { medicalMessageCategoryOptions, medicalMessageTypeOptions } from "@health/enum-options";
import { MedicalMessage } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormAutocomplete, FormCard, FormTextField, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { convertMedicalMessageToFormValues } from "pages/MedicalMessages/others";
import { MedicalMessageUpsertFormButtonTitle } from "pages/MedicalMessages/types";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks";
import {
  medicalMessageDefaultValues,
  IMedicalMessageUpsertFormValues,
  medicalMessageUpsertFormSchema,
} from "./MedicalMessageUpsertFormSchema";
import { useCustomForm } from "@toolkit/core";

export type IMedicalMessageUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IMedicalMessageUpsertFormValues;
  };
};

type MedicalMessageUpsertFormProps = {
  submitButtonLabel: MedicalMessageUpsertFormButtonTitle;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  medicalMessage?: MedicalMessage;
  onChange: (values: IMedicalMessageUpsertFormEvent) => void;
};

export const MedicalMessageUpsertForm: FC<MedicalMessageUpsertFormProps> = props => {
  const { submitButtonLabel, isLoading, isButtonDisabled, medicalMessage, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IMedicalMessageUpsertFormValues>({
    defaultValues: medicalMessageDefaultValues,
    schema: medicalMessageUpsertFormSchema,
  });

  const {
    setValues,
    formState: { errors },
    handleSubmit,
  } = form;

  const onSubmit = (values: IMedicalMessageUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(medicalMessagesPaths.main.fullPath);
  };

  useEffect(() => {
    if (medicalMessage) {
      const _medicalMessage = convertMedicalMessageToFormValues(medicalMessage);
      setValues(_medicalMessage);
    }
  }, [medicalMessage, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormCard title={t("Patient Engagement Messages Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormTextField name={"name"} label={t("Name")} />
                  </Grid>

                  <Grid item xs={4}>
                    <FormTextField name={"code"} label={t("Code")} />
                  </Grid>

                  <Grid item xs={4}>
                    <FormAutocomplete
                      name={"medicalMessageType"}
                      label={t("Type")}
                      placeholder={t("Type")}
                      disabled={!!medicalMessage}
                      options={medicalMessageTypeOptions}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormAutocomplete
                      name={"medicalMessageCategory"}
                      label={t("Category")}
                      placeholder={t("Category")}
                      options={medicalMessageCategoryOptions}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <HealthConditionsAutocomplete name={"healthCondition"} filter={{ isActive: true }} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Header Image")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      control={form.control}
                      name={"headerImage"}
                      label={t("Image")}
                      validationRation
                      error={!!errors?.headerImage}
                      helperText={errors?.headerImage?.message}
                      token={accessToken!}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
