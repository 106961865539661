import { useCallback, useMemo } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationUpsertFormValues } from "../TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";
import { GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi } from "../../modals/TemplateGoalRecommendationInterventionActivities/GuidedCareTemplateGoalRecommendationInterventionActivitiesModal";

export const useGuidedCareTemplateGoalRecommendationInterventionsFieldsColumns = (): CustomTableColumnProps<
  IGuidedCareTemplateGoalRecommendationUpsertFormValues["interventions"][number]
>[] => {
  const { t } = useTranslation("gcadmin");

  const onViewActivitiesClick = useCallback((uniqueId: string) => {
    GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi.open({
      interventionUniqueId: uniqueId,
    });
  }, []);

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: "label",
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ value }) => pickLocalizedValue(value?.description, value?.descriptionAr),
      },
      {
        key: "duration",
        header: t("Duration (Days)"),
        accessor: ({ value }) => value?.duration,
      },
      {
        key: "activities",
        header: t("Activities"),
        accessor: ({ value: { uniqueId } }) => (
          <Button onClick={() => onViewActivitiesClick(uniqueId!)} variant={"contained"}>
            {t("View")}
          </Button>
        ),
      },
    ];
  }, [onViewActivitiesClick, t]);
};
