import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateActivitiesItemsTimelineMenuStyle = makeStyles()(theme => ({
  btn: {
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    boxShadow: theme.mixins.shadows.md,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  title: {
    color: theme.palette.stale.main,
    fontSize: theme.mixins.fonts.fontSize.xxs,
    whiteSpace: "nowrap",
  },
  selected: {
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  menuRoot: {
    marginTop: "8px !important",
    borderRadius: "10px !important",
  },
  menuPaper: {
    minWidth: 120,
    borderRadius: "10px !important",
  },
  menuItem: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
