/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type RiskFactorTemplateActivationMutation = { __typename?: 'Mutation', updateRiskFactorTemplateActiveStatus?: { __typename?: 'RiskFactorTemplate', isActive?: boolean | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const RiskFactorTemplateActivationDocument = gql`
    mutation RiskFactorTemplateActivation($id: ID!) {
  updateRiskFactorTemplateActiveStatus(id: $id) {
    isActive
    errors {
      field
      message
    }
  }
}
    `;
export type RiskFactorTemplateActivationMutationFn = Apollo.MutationFunction<RiskFactorTemplateActivationMutation, RiskFactorTemplateActivationMutationVariables>;

/**
 * __useRiskFactorTemplateActivationMutation__
 *
 * To run a mutation, you first call `useRiskFactorTemplateActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskFactorTemplateActivationMutation, { data, loading, error }] = useRiskFactorTemplateActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRiskFactorTemplateActivationMutation(baseOptions?: Apollo.MutationHookOptions<RiskFactorTemplateActivationMutation, RiskFactorTemplateActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RiskFactorTemplateActivationMutation, RiskFactorTemplateActivationMutationVariables>(RiskFactorTemplateActivationDocument, options);
      }
export type RiskFactorTemplateActivationMutationHookResult = ReturnType<typeof useRiskFactorTemplateActivationMutation>;
export type RiskFactorTemplateActivationMutationResult = Apollo.MutationResult<RiskFactorTemplateActivationMutation>;
export type RiskFactorTemplateActivationMutationOptions = Apollo.BaseMutationOptions<RiskFactorTemplateActivationMutation, RiskFactorTemplateActivationMutationVariables>;