import { FC } from "react";
import { TemplateFieldType } from "@health/queries/types";
import { Box, TruncateTypography, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { useHealthParameterDefaultRangeValuesStyle } from "./useHealthParameterDefaultRangeValuesStyle";

type HealthParameterDefaultRangeValuesProps = {
  type?: TemplateFieldType;
  defaultValue?: string;
  minNormalRangeValue?: number;
  maxNormalRangeValue?: number;
  allowedValues?: string[];
};

export const HealthParameterDefaultRangeValues: FC<HealthParameterDefaultRangeValuesProps> = props => {
  const { type, defaultValue, minNormalRangeValue, maxNormalRangeValue, allowedValues } = props;

  const { t } = useTranslation("gcadmin");

  const { classes } = useHealthParameterDefaultRangeValuesStyle();

  return (
    <Box className={classes.root}>
      {type === TemplateFieldType.Number && (
        <Box>
          <Box className={classes.numberWrapper}>
            <Typography className={classes.label}>
              {t("Min Normal Range Value")}:<Typography> {minNormalRangeValue || "-"} </Typography>
            </Typography>
          </Box>

          <Box className={classes.numberWrapper}>
            <Typography className={classes.label}>
              {t("Max Normal Range Value")}:<Typography> {maxNormalRangeValue || "-"} </Typography>
            </Typography>
          </Box>
        </Box>
      )}

      {type === TemplateFieldType.String && (
        <Box>
          <Typography className={classes.label}>
            {t("Default Value")}: <TruncateTypography text={defaultValue || ""} />
          </Typography>

          <Typography className={classes.label}>{t("Allowed Values")}:</Typography>

          {allowedValues?.map(item => <TruncateTypography key={item} text={item} />) || "-"}
        </Box>
      )}
    </Box>
  );
};
