import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { HealthSymptomsRoutesIcon } from "../components/HealthSymptomsRoutesIcon/HealthSymptomsRoutesIcon";
import { HealthSymptomCreateContainer, HealthSymptomListContainer, HealthSymptomUpdateContainer } from "../containers";
import { healthSymptomsPaths, healthSymptomsRoute } from "./HealthSymptomsPaths";

export const healthSymptomsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "health-symptoms-routes",
    text: t("Health Symptoms", { ns: "gcadmin" }),
    route: healthSymptomsRoute,
    icon: <HealthSymptomsRoutesIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageHealthSymptoms),
    subItems: [
      {
        id: "health-symptom-list-route",
        route: healthSymptomsPaths.list.route,
        fullPath: healthSymptomsPaths.list.fullPath,
        element: <HealthSymptomListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-symptom-create-route",
        route: healthSymptomsPaths.create.route,
        fullPath: healthSymptomsPaths.create.fullPath,
        hidden: true,
        element: <HealthSymptomCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-symptom-update-route",
        route: healthSymptomsPaths.update.route,
        fullPath: healthSymptomsPaths.update.fullPath,
        hidden: true,
        element: <HealthSymptomUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
