import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateCustomAccordionStyle = makeStyles()(theme => ({
  accordion: {
    "&:before": {
      display: "none",
    },
  },
  accordionSummary: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    borderRadius: 0,
    position: "relative",
    paddingRight: 0,
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 16,
      right: 0,
      height: "1px",
      backgroundColor: "#e0e0e0",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  accordionDetails: {
    paddingRight: 0,
  },
}));
