import { z } from "zod";
import { GuidedCareTemplateActivityItemUpdateFormSchema } from "../TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

export const GuidedCareTemplateActivityItemsFormSchema = z.object({
  activityItems: z.array(GuidedCareTemplateActivityItemUpdateFormSchema),
});

export type IGuidedCareActivityItemsFormValues = z.infer<typeof GuidedCareTemplateActivityItemsFormSchema>;

export const guidedCareActivityItemsFormDefaultValues: Partial<IGuidedCareActivityItemsFormValues> = {
  activityItems: [],
};
