import { FC } from "react";
import { orderBy } from "lodash";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, Typography } from "@toolkit/ui";
import { ProviderGuidedCareProgramTeamMember } from "@health/queries/types";
import { useGuidedCareProgramTeamMemberListColumns } from "./useGuidedCareProgramTeamMemberListColumns";

type GuidedCareProgramTeamMemberListProps = {
  teamMembers: ProviderGuidedCareProgramTeamMember[];
};

export const GuidedCareProgramTeamMemberList: FC<GuidedCareProgramTeamMemberListProps> = props => {
  const { teamMembers } = props;

  const { t } = useTranslation("gcadmin");

  const columns = useGuidedCareProgramTeamMemberListColumns();

  if (!teamMembers || !teamMembers?.length) {
    return <Typography> {t("No Team Members Found")} </Typography>;
  }

  const sortedTeamMembers = orderBy(teamMembers, item => item?.guidedCareProgramTeamMember?.position, ["desc"]);

  return <CustomTable data={sortedTeamMembers} columns={columns} hasFooter={false} scrollable />;
};
