/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabTemplateUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.LabTemplateUpdateInput;
}>;


export type LabTemplateUpdateMutation = { __typename?: 'Mutation', updateLabTemplate?: { __typename?: 'LabTemplate', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const LabTemplateUpdateDocument = gql`
    mutation LabTemplateUpdate($id: ID!, $input: LabTemplateUpdateInput!) {
  updateLabTemplate(id: $id, input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type LabTemplateUpdateMutationFn = Apollo.MutationFunction<LabTemplateUpdateMutation, LabTemplateUpdateMutationVariables>;

/**
 * __useLabTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useLabTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLabTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [labTemplateUpdateMutation, { data, loading, error }] = useLabTemplateUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLabTemplateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<LabTemplateUpdateMutation, LabTemplateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LabTemplateUpdateMutation, LabTemplateUpdateMutationVariables>(LabTemplateUpdateDocument, options);
      }
export type LabTemplateUpdateMutationHookResult = ReturnType<typeof useLabTemplateUpdateMutation>;
export type LabTemplateUpdateMutationResult = Apollo.MutationResult<LabTemplateUpdateMutation>;
export type LabTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<LabTemplateUpdateMutation, LabTemplateUpdateMutationVariables>;