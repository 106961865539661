import { useNavigate } from "react-router-dom";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useHealthSymptomCreateMutation } from "../../gql";
import { HealthSymptomUpsertForm, IHealthSymptomUpsertFormEvent } from "../../forms/HealthSymptomUpsert/HealthSymptomUpsertForm";
import { convertHealthSymptomFormValuesToBackEndValues } from "../../others";
import { healthSymptomsPaths } from "../../constants";
import { useSetHealthSymptomsBreadcrumbs } from "../../hooks";

export const HealthSymptomCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createHealthSymptomMutation, { loading: isSubmitting }] = useHealthSymptomCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.healthSymptomCreate?.healthSymptomErrors?.length) {
        succeeded(t("Health Symptom created successfully"));
        navigate(healthSymptomsPaths.list.fullPath);
      } else {
        failed(t("Health Symptom create failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthSymptomUpsertFormChange = (event: IHealthSymptomUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthSymptomFormValuesToBackEndValues(event.payload.values);

      createHealthSymptomMutation({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetHealthSymptomsBreadcrumbs("CREATE");

  return (
    <HealthSymptomUpsertForm submitButtonLabel={t("Create")} isButtonDisabled={isSubmitting} onChange={onHealthSymptomUpsertFormChange} />
  );
};
