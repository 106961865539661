import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateActivitiesItemsTimelineStyle = makeStyles()(theme => ({
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    padding: 16,
    borderRadius: 8,
    background: theme.palette.common.white,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    color: theme.palette.primary.main,
  },
  menuWrapper: {
    display: "flex",
  },
}));
