import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, MouseEvent } from "react";
import { GuidedCareTemplateActivityServicesForm } from "../../forms/TemplateActivityServices/GuidedCareTemplateActivityServicesForm";

type GuidedCareTemplateActivityServicesModalProps = {
  activityIndex: number;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareTemplateActivityServicesModal: FC<GuidedCareTemplateActivityServicesModalProps> = props => {
  const { activityIndex, isOpen, onClose } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Activity Services"),
        onClose,
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <GuidedCareTemplateActivityServicesForm activityIndex={activityIndex} />
    </CustomDialog>
  );
};
