import { z } from "zod";
import { zodSchema } from "@health/domains";

export const GuidedCareProgramActivityServiceUpsertFormSchema = z.object({
  serviceId: z.string().optional(),
  standard: zodSchema.systemCodeAutocompleteEnum,
  service: zodSchema.systemCodeAutocompleteOptional,
});

export type IGuidedCareProgramActivityServiceUpsertFormValues = z.infer<typeof GuidedCareProgramActivityServiceUpsertFormSchema>;

export const guidedCareProgramActivityServiceUpsertFormDefaultValues: Partial<IGuidedCareProgramActivityServiceUpsertFormValues> = {
  serviceId: undefined,
  standard: undefined,
  service: undefined,
};
