import { IEnumOption } from "@health/enum-options";
import { Box, Button, CustomIcon, Menu, MenuItem, Typography } from "@toolkit/ui";
import React, { useState } from "react";
import { useGuidedCareTemplateActivitiesItemsTimelineMenuStyle } from "./useGuidedCareTemplateActivitiesItemsTimelineMenuStyle";

type GuidedCareTemplateActivitiesItemsTimelineMenuProps<T extends string> = {
  title: string;
  items: IEnumOption<T>[];
  selected?: string;
  onChange: (type: T) => void;
};

export const GuidedCareTemplateActivitiesItemsTimelineMenu = <T extends string>(
  props: GuidedCareTemplateActivitiesItemsTimelineMenuProps<T>
) => {
  const { items, selected, title, onChange } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isOpen = !!anchorEl;

  const { classes } = useGuidedCareTemplateActivitiesItemsTimelineMenuStyle();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (item: IEnumOption<T>) => {
    return () => {
      setAnchorEl(null);
      onChange(item?.value);
    };
  };

  return (
    <Box>
      <Button
        id={"patient-add-activity-menu-button"}
        aria-controls={isOpen ? "patient-add-activity-menu" : undefined}
        aria-haspopup={"true"}
        aria-expanded={isOpen ? "true" : undefined}
        variant={"text"}
        disableElevation
        onClick={handleClick}
        className={classes.btn}
        endIcon={
          <Box>
            <CustomIcon icon={"expandArrow"} viewBox={"0 -3 20 20"} color={"inherit"} />
          </Box>
        }
      >
        <Box className={classes.titleWrapper}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.selected}>{selected}</Typography>
        </Box>
      </Button>

      <Menu
        id={"patient-add-activity-menu"}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{
          root: classes.menuRoot,
          paper: classes.menuPaper,
        }}
      >
        {items?.map(item => (
          <MenuItem key={`${item?.key}`} onClick={handleSelect(item)}>
            <Typography className={classes.menuItem}>{item?.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
