import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { labTemplatesPaths } from "../constants";

export const useSetLabTemplatesBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "CLONE" | "PARAMETERS_LIST",
  display?: string
) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Lab Templates");

  const labTemplateListBreadcrumb = useMemo(() => {
    return {
      name: t("Lab Templates"),
      route: labTemplatesPaths.list.fullPath,
    };
  }, [t]);

  const setLabTemplateListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setLabTemplateCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [labTemplateListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, labTemplateListBreadcrumb, t]);

  const setLabTemplateUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [labTemplateListBreadcrumb, { name: display }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, labTemplateListBreadcrumb]);

  const setLabTemplateCloneContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [labTemplateListBreadcrumb, { name: display }, { name: t("Clone") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, labTemplateListBreadcrumb, t]);

  const setLabTemplateParameterListContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [labTemplateListBreadcrumb, { name: display }, { name: t("Parameters") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, labTemplateListBreadcrumb, t]);

  useEffect(() => {
    if (containerType === "LIST") {
      setLabTemplateListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setLabTemplateCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setLabTemplateUpdateContainerBreadcrumb();
    } else if (containerType === "CLONE") {
      setLabTemplateCloneContainerBreadcrumb();
    } else if (containerType === "PARAMETERS_LIST") {
      setLabTemplateParameterListContainerBreadcrumb();
    }
  }, [
    containerType,
    setLabTemplateListContainerBreadcrumb,
    setLabTemplateCreateContainerBreadcrumb,
    setLabTemplateUpdateContainerBreadcrumb,
    setLabTemplateCloneContainerBreadcrumb,
    setLabTemplateParameterListContainerBreadcrumb,
  ]);
};
