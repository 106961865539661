import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareProgramActivityServiceListItem } from "pages/Programs/types";

export const useGuidedCareProgramActivityServicesColumns = (): CustomTableColumnProps<IGuidedCareProgramActivityServiceListItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "standard",
        header: t("Standard"),
        accessor: ({ standard }) => standard?.label,
      },
      {
        key: "code",
        header: t("Code"),
        accessor: ({ service }) => service?.value?.code,
      },
      {
        key: "display",
        header: t("Display"),
        accessor: ({ service }) => service?.value?.display,
      },
    ];
  }, [t]);
};
