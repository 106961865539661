import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareProgramMemberUpsertFormValues } from "pages/Programs/forms/ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";
import { IGuidedCareProgramActivityUpsertFormEvent } from "pages/Programs/types";
import { FC, MouseEvent, useRef } from "react";
import {
  GuidedCareProgramActivityUpsertForm,
  GuidedCareProgramActivityUpsertFormRef,
} from "../../forms/ProgramActivityUpsert/GuidedCareProgramActivityUpsertForm";
import { IGuidedCareProgramActivityUpsertFormValues } from "../../forms/ProgramActivityUpsert/GuidedCareProgramActivityUpsertFormSchema";

type GuidedCareProgramActivityUpsertModalProps = {
  teamMembers: IGuidedCareProgramMemberUpsertFormValues[];
  activity?: IGuidedCareProgramActivityUpsertFormValues;
  onChange: (event: IGuidedCareProgramActivityUpsertFormEvent) => void;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareProgramActivityUpsertModal: FC<GuidedCareProgramActivityUpsertModalProps> = props => {
  const { teamMembers, activity, onChange, isOpen, onClose: handleClose } = props;

  const { t } = useTranslation("gcadmin");
  const programActivityUpsertFormRef = useRef<GuidedCareProgramActivityUpsertFormRef>(null);

  const onButtonClick = () => {
    programActivityUpsertFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: activity ? t("Update Activity") : t("Create Activity"),

        onClose: handleClose,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: activity ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareProgramActivityUpsertForm
        teamMembers={teamMembers}
        activity={activity}
        onChange={onChange}
        ref={programActivityUpsertFormRef}
      />
    </CustomDialog>
  );
};
