import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { SurveysIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { AdminMedicalFormCreateContainer, AdminMedicalFormUpdateContainer, AdminMedicalFormsContainer } from "../containers";
import { medicalFormsPaths, medicalFormsRoute } from "./MedicalFormsPaths";

export const medicalFormsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "medical-forms",
    text: t("Medical Forms", { ns: "gcadmin" }),
    isProhibited: !hasPermission(PermissionEnum.ManageMedicalForms),
    route: medicalFormsRoute,
    icon: <SurveysIcon />,
    subItems: [
      {
        id: medicalFormsPaths.main.fullPath,
        text: "",
        route: medicalFormsPaths.main.route,
        fullPath: medicalFormsPaths.main.fullPath,
        element: <AdminMedicalFormsContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: medicalFormsPaths.new.fullPath,
        text: "",
        route: medicalFormsPaths.new.route,
        fullPath: medicalFormsPaths.new.fullPath,
        element: <AdminMedicalFormCreateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: medicalFormsPaths.update.fullPath,
        text: "",
        route: medicalFormsPaths.update.route,
        fullPath: medicalFormsPaths.update.fullPath,
        element: <AdminMedicalFormUpdateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
