import { FormProvider, UseFormReturn } from "react-hook-form";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useCustomForm, useCustomFormContext } from "@toolkit/core";
import { FormAutocomplete, Grid, mapToAutocompleteOptions } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent } from "../../types";
import {
  guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema,
  IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues,
  guidedCareTemplateGoalRecommendationInterventionUpsertFormDefaultValues,
} from "./GuidedCareTemplateGoalRecommendationInterventionUpsertFormSchema";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";

type GuidedCareTemplateGoalRecommendationInterventionUpsertFormProps = {
  intervention: IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues;
  onChange: (event: IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent) => void;
};

export type GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateGoalRecommendationInterventionUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef,
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormProps
> = (props, ref) => {
  const { intervention, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const formContext = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const personalizedInterventions = formContext.getValues("interventions");

  const personalizedInterventionsOptions = mapToAutocompleteOptions(personalizedInterventions, "uniqueId", item =>
    pickLocalizedValue(item?.name, item?.nameAr!)
  );

  const form = useCustomForm<IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues>({
    defaultValues: guidedCareTemplateGoalRecommendationInterventionUpsertFormDefaultValues,
    schema: guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues) => {
    onChange({
      type: "UPDATE",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (intervention) {
      setValues(intervention);
    }
  }, [intervention, setValues]);

  return (
    <FormProvider {...form}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <FormAutocomplete name={"interventions"} label={t("Interventions")} multiple options={personalizedInterventionsOptions} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const GuidedCareTemplateGoalRecommendationInterventionUpsertForm = forwardRef(
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormForwardRef
);
