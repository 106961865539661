import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthParametersPaths } from "../constants";

export const useSetHealthParametersBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "DECISION_CREATE" | "DECISION_UPDATE",
  display?: string
) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Health Parameters");

  const healthParameterListBreadcrumb = useMemo(() => {
    return {
      name: t("Health Parameters"),
      route: healthParametersPaths.list.fullPath,
    };
  }, [t]);

  const setHealthParameterListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setHealthParameterCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [healthParameterListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, healthParameterListBreadcrumb, t]);

  const setHealthParameterUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthParameterListBreadcrumb, { name: display }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthParameterListBreadcrumb]);

  const setHealthParameterDecisionCreateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthParameterListBreadcrumb, { name: display }, { name: t("Rules") }, { name: t("New") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthParameterListBreadcrumb, t]);

  const setHealthParameterDecisionUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthParameterListBreadcrumb, { name: display }, { name: t("Rules") }, { name: t("Edit") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, healthParameterListBreadcrumb, t]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthParameterListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setHealthParameterCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setHealthParameterUpdateContainerBreadcrumb();
    } else if (containerType === "DECISION_CREATE") {
      setHealthParameterDecisionCreateContainerBreadcrumb();
    } else if (containerType === "DECISION_UPDATE") {
      setHealthParameterDecisionUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setHealthParameterListContainerBreadcrumb,
    setHealthParameterCreateContainerBreadcrumb,
    setHealthParameterUpdateContainerBreadcrumb,
    setHealthParameterDecisionCreateContainerBreadcrumb,
    setHealthParameterDecisionUpdateContainerBreadcrumb,
  ]);
};
