import { useCallback, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";

export const useGuidedCareTemplatesBreadcrumbs = () => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Templates");

  const templateListBreadcrumb = useMemo(() => {
    return {
      name: t("Templates"),
      route: guidedCareTemplatesPaths.list.fullPath,
    };
  }, [t]);

  const setGuidedCareTemplateListContainerBreadcrumb = useCallback(() => {
    const guidedCareTemplateListContainerBreadcrumb = {
      title: breadcrumbTitle,
    };

    setBreadCrumb(guidedCareTemplateListContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb]);

  const setGuidedCareTemplateCreateContainerBreadcrumb = useCallback(() => {
    const guidedCareTemplateCreateContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [templateListBreadcrumb, { name: t("New") }],
    };

    setBreadCrumb(guidedCareTemplateCreateContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, templateListBreadcrumb, t]);

  const setGuidedCareTemplateUpdateContainerBreadcrumb = useCallback(
    (templateName: string) => {
      const guidedCareTemplateUpdateContainerBreadcrumb = {
        title: breadcrumbTitle,
        values: [templateListBreadcrumb, { name: templateName }],
      };

      setBreadCrumb(guidedCareTemplateUpdateContainerBreadcrumb);
    },
    [breadcrumbTitle, setBreadCrumb, templateListBreadcrumb]
  );

  const setGuidedCareTemplateActivitiesItemListContainerBreadcrumb = useCallback(() => {
    const guidedCareTemplateActivitiesItemListContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [templateListBreadcrumb, { name: t("Activities Items") }],
    };

    setBreadCrumb(guidedCareTemplateActivitiesItemListContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, templateListBreadcrumb, t]);

  const setGuidedCareTemplateActivityItemListContainerBreadcrumb = useCallback(() => {
    const guidedCareTemplateActivityItemsContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [templateListBreadcrumb, { name: t("Activity Items") }],
    };

    setBreadCrumb(guidedCareTemplateActivityItemsContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, templateListBreadcrumb, t]);

  const setGuidedCareTemplateGoalRecommendationInterventionActivityItemListContainerBreadcrumb = useCallback(() => {
    const guidedCareTemplateActivityItemsContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [templateListBreadcrumb, { name: t("Intervention Activity Items") }],
    };

    setBreadCrumb(guidedCareTemplateActivityItemsContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, templateListBreadcrumb, t]);

  return {
    setGuidedCareTemplateListContainerBreadcrumb,
    setGuidedCareTemplateCreateContainerBreadcrumb,
    setGuidedCareTemplateUpdateContainerBreadcrumb,
    setGuidedCareTemplateActivitiesItemListContainerBreadcrumb,
    setGuidedCareTemplateActivityItemListContainerBreadcrumb,
    setGuidedCareTemplateGoalRecommendationInterventionActivityItemListContainerBreadcrumb,
  };
};
