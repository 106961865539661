/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageUpdateMutationVariables = Types.Exact<{
  medicalMessageUpdateId: Types.Scalars['ID'];
  input: Types.MedicalMessageInput;
}>;


export type MedicalMessageUpdateMutation = { __typename?: 'Mutation', medicalMessageUpdate?: { __typename?: 'MedicalMessageCRUD', medicalMessageErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const MedicalMessageUpdateDocument = gql`
    mutation MedicalMessageUpdate($medicalMessageUpdateId: ID!, $input: MedicalMessageInput!) {
  medicalMessageUpdate(id: $medicalMessageUpdateId, input: $input) {
    medicalMessageErrors {
      field
      message
    }
  }
}
    `;
export type MedicalMessageUpdateMutationFn = Apollo.MutationFunction<MedicalMessageUpdateMutation, MedicalMessageUpdateMutationVariables>;

/**
 * __useMedicalMessageUpdateMutation__
 *
 * To run a mutation, you first call `useMedicalMessageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalMessageUpdateMutation, { data, loading, error }] = useMedicalMessageUpdateMutation({
 *   variables: {
 *      medicalMessageUpdateId: // value for 'medicalMessageUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicalMessageUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MedicalMessageUpdateMutation, MedicalMessageUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalMessageUpdateMutation, MedicalMessageUpdateMutationVariables>(MedicalMessageUpdateDocument, options);
      }
export type MedicalMessageUpdateMutationHookResult = ReturnType<typeof useMedicalMessageUpdateMutation>;
export type MedicalMessageUpdateMutationResult = Apollo.MutationResult<MedicalMessageUpdateMutation>;
export type MedicalMessageUpdateMutationOptions = Apollo.BaseMutationOptions<MedicalMessageUpdateMutation, MedicalMessageUpdateMutationVariables>;