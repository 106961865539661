/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomGetQueryVariables = Types.Exact<{
  healthSymptomId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type HealthSymptomGetQuery = { __typename?: 'Query', healthSymptom?: { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } | null };


export const HealthSymptomGetDocument = gql`
    query HealthSymptomGet($healthSymptomId: ID) {
  healthSymptom(id: $healthSymptomId) {
    id
    name
    created
    specializations {
      id
      code
      display
      arabicDisplay
    }
  }
}
    `;

/**
 * __useHealthSymptomGetQuery__
 *
 * To run a query within a React component, call `useHealthSymptomGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthSymptomGetQuery({
 *   variables: {
 *      healthSymptomId: // value for 'healthSymptomId'
 *   },
 * });
 */
export function useHealthSymptomGetQuery(baseOptions?: Apollo.QueryHookOptions<HealthSymptomGetQuery, HealthSymptomGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthSymptomGetQuery, HealthSymptomGetQueryVariables>(HealthSymptomGetDocument, options);
      }
export function useHealthSymptomGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthSymptomGetQuery, HealthSymptomGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthSymptomGetQuery, HealthSymptomGetQueryVariables>(HealthSymptomGetDocument, options);
        }
export type HealthSymptomGetQueryHookResult = ReturnType<typeof useHealthSymptomGetQuery>;
export type HealthSymptomGetLazyQueryHookResult = ReturnType<typeof useHealthSymptomGetLazyQuery>;
export type HealthSymptomGetQueryResult = Apollo.QueryResult<HealthSymptomGetQuery, HealthSymptomGetQueryVariables>;