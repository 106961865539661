import { z } from "zod";
import { i18n } from "@toolkit/i18n";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { zodSchema } from "@health/domains";
import { isGuidedCareActivityTypeMedicalMessage } from "@health/enum-options";
import { GuidedCareActivityType } from "@health/queries/types";
import { GuidedCareTemplateActivityServiceUpsertFormSchema } from "../TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertFormSchema";
import { GuidedCareTemplateMemberUpsertFormSchema } from "../TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { guidedCareTemplateInterventionUpsertFormSchema } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";

export const GuidedCareTemplateActivityUpsertFormSchema = z
  .object({
    activityId: z.string().optional(),
    templateActivityType: zodSchema.guidedCareActivityTypeAutocompleteEnum,
    teamMember: createZodAutocompleteObject(GuidedCareTemplateMemberUpsertFormSchema),
    numberOfOccurrences: z.coerce.number().min(1),
    medicalForm: zodSchema.medicalFormClassificationAutocompleteOptional,
    medicalMessage: zodSchema.medicalMessageAutocompleteOptional,
    frequencyType: zodSchema.appointmentFrequencyTypeAutocompleteEnum,
    activityServices: z.array(GuidedCareTemplateActivityServiceUpsertFormSchema).optional(),
    beforeActivity: zodSchema.medicalMessageAutocompleteOptional,
    medicalFormClassificationsInterventions: z.array(
      z.object({
        id: z.string().nullable().optional(),
        classification: z.string(),
        intervention: createZodAutocompleteObject(guidedCareTemplateInterventionUpsertFormSchema).nullable().optional(),
      })
    ),
    beforeActivityOffset: z.coerce.number().min(0).nullable().optional(),
    afterActivity: zodSchema.medicalMessageAutocompleteOptional,
    afterActivityOffset: z.coerce.number().min(0).nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.templateActivityType?.key === GuidedCareActivityType.MedicalForm && !data.medicalForm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["medicalForm"],
      });
    }

    if (isGuidedCareActivityTypeMedicalMessage(data.templateActivityType?.value) && !data.medicalMessage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["medicalMessage"],
      });
    }

    if (data.beforeActivity && !data.beforeActivityOffset) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["beforeActivityOffset"],
      });
    }

    if (data.beforeActivityOffset && !data.beforeActivity) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["beforeActivity"],
      });
    }

    if (data.afterActivity && !data.afterActivityOffset) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["afterActivityOffset"],
      });
    }

    if (data.afterActivityOffset && !data.afterActivity) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["afterActivity"],
      });
    }
  });

export type IGuidedCareTemplateActivityUpsertFormValues = z.infer<typeof GuidedCareTemplateActivityUpsertFormSchema>;

export const guidedCareTemplateActivityUpsertFormDefaultValues: Partial<IGuidedCareTemplateActivityUpsertFormValues> = {
  templateActivityType: undefined,
  frequencyType: undefined,
  numberOfOccurrences: undefined,
  medicalForm: undefined,
  medicalMessage: undefined,
  activityServices: [],
  medicalFormClassificationsInterventions: [],
  beforeActivity: null,
  beforeActivityOffset: undefined,
  afterActivity: null,
  afterActivityOffset: undefined,
  teamMember: undefined,
};
