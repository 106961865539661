import { FC, useState } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useHealthConditionActivationMutation } from "../../gql";

type HealthConditionActivationProps = {
  id: string;
  isActive: boolean;
};

export const HealthConditionActivation: FC<HealthConditionActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();

  const [activateHealthCondition, { loading: isSubmitting }] = useHealthConditionActivationMutation({
    onCompleted: mutationData => {
      if (!mutationData?.updateHealthConditionActiveStatus?.errors?.length) {
        succeeded(t("Health Condition updated successfully"));
        setActive(!!mutationData?.updateHealthConditionActiveStatus?.isActive);
      } else {
        succeeded(t("Health Condition update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    activateHealthCondition({
      variables: { id },
    });
  };

  return <CustomToggleButton disabled={isSubmitting} isLoading={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
