import { CustomDialog } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { TemplateFieldType } from "@health/queries/types";
import { HealthParameterDefaultRangeValues } from "../../components/HealthParameterDefaultRangeValues/HealthParameterDefaultRangeValues";

type IHealthParameterDefaultRangeValuesModalData = {
  type: TemplateFieldType;
  defaultValue?: string;
  minNormalRangeValue?: number;
  maxNormalRangeValue?: number;
  allowedValues?: string[];
};

type IOpen = (data: IHealthParameterDefaultRangeValuesModalData) => void;
type IClose = () => void;

export const HealthParameterDefaultRangeValuesModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const HealthParameterDefaultRangeValuesModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IHealthParameterDefaultRangeValuesModalData>();

  const { t } = useTranslation("gcadmin");

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    HealthParameterDefaultRangeValuesModalApi.open = open;
    HealthParameterDefaultRangeValuesModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Default Range Values"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <HealthParameterDefaultRangeValues {...data} />
    </CustomDialog>
  );
};
