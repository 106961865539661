import { makeStyles } from "tss-react/mui";

export const useGuidedCareProgramTeamStatusStyle = makeStyles()(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
    textAlign: "center",
  },
  circle: {
    display: "flex",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
}));
