import { createPathDefinition } from "@toolkit/core";

export const guidedCareProgramsRoute = `/guided-care/programs`;

export const guidedCareProgramsPaths = createPathDefinition(guidedCareProgramsRoute, {
  list: "",
  create: "new",
  update: ":programId",
  teamList: ":programId/teams",
  activityItemList: "activities/:activityId/activity-items",
});
