import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { FormSwitch, FormTextField, Grid } from "@toolkit/ui";

type GuidedCareTemplateVerificationFormProps = {
  isVerified?: boolean;
};

export const GuidedCareTemplateVerificationForm: FC<GuidedCareTemplateVerificationFormProps> = props => {
  const { isVerified } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormSwitch name={"isVerified"} label={t("Is Template Verified")} />
      </Grid>

      {isVerified && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <FormTextField name={"standardName"} label={t("Standard Name")} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormTextField name={"standardCode"} label={t("Standard Code")} />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormTextField name={"standardURL"} label={t("Standard URL")} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
