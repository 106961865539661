import { z } from "zod";
import { zodSchema } from "@health/domains";

export const healthSymptomUpsertFormSchema = z.object({
  name: z.string().min(1).max(255),
  specializations: z.array(zodSchema.systemCodeAutocomplete).min(1),
});

export type IHealthSymptomUpsertFormValues = z.infer<typeof healthSymptomUpsertFormSchema>;

export const healthSymptomUpsertFormDefaultValues: Partial<IHealthSymptomUpsertFormValues> = {
  name: undefined,
  specializations: [],
};
