import React, { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, FormHelperText, Typography } from "@toolkit/ui";
import { IGuidedCareTemplateActivityItem } from "pages/Templates/types";
import { GuidedCareTemplateActivityItemsManage } from "../../components/TemplateActivityItemsManage/GuidedCareTemplateActivityItemsManage";
import { GuidedCareTemplateActivityServicesManage } from "../../components/TemplateActivityServicesManage/GuidedCareTemplateActivityServicesManage";
import { HealthProgramTemplate } from "@health/queries/types";

type GuidedCareTemplateActivitiesColumnsProps = {
  template?: HealthProgramTemplate;
  isClone?: boolean;
};

export const useGuidedCareTemplateActivitiesColumns = (
  props: GuidedCareTemplateActivitiesColumnsProps
): CustomTableColumnProps<IGuidedCareTemplateActivityItem>[] => {
  const { template, isClone } = props;

  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "type",
        header: t("Type"),
        accessor: ({ activityId, templateActivityType, numberOfOccurrences, frequencyType }) => {
          const currentActivity = template && !isClone && template?.templateActivities?.find(item => item?.id === activityId);

          const isDurationModified =
            currentActivity &&
            (currentActivity?.numberOfOccurrences !== numberOfOccurrences || currentActivity?.frequencyType !== frequencyType?.value);

          return (
            <>
              <Typography> {templateActivityType?.label} </Typography>

              {isDurationModified && (
                <FormHelperText error> {t("Please be aware that these activity items will be regenerated")} </FormHelperText>
              )}
            </>
          );
        },
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ medicalMessage }) => medicalMessage?.value?.name || "-",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: ({ medicalMessage }) => medicalMessage?.value?.code || "-",
      },
      {
        key: "position",
        header: t("Position"),
        accessor: ({ teamMember }) => teamMember?.label,
      },
      {
        key: "numberOfOccurrences",
        header: t("Occurrences"),
        accessor: ({ numberOfOccurrences, frequencyType }) => `${numberOfOccurrences} / ${t(frequencyType?.label!)}`,
      },
      {
        key: "serviceCode",
        header: t("Services"),
        accessor: ({ templateActivityType }, index) => (
          <GuidedCareTemplateActivityServicesManage activityType={templateActivityType?.value} activityIndex={index} />
        ),
      },
      {
        key: "items",
        header: t("Items"),
        accessor: ({ activityId }) => <GuidedCareTemplateActivityItemsManage activityId={activityId} />,
      },
    ];
  }, [isClone, t, template]);
};
