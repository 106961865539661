import { IEnumToOptionsMap } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";
import { HealthConditionDiagnoseCreationMethod } from "../types";

export const healthConditionDiagnoseCreateTypeOptionsMap: IEnumToOptionsMap<HealthConditionDiagnoseCreationMethod> = {
  [HealthConditionDiagnoseCreationMethod.Diagnose]: {
    key: HealthConditionDiagnoseCreationMethod.Diagnose,
    get label() {
      return i18n.t("By Diagnose", { ns: "gcadmin" });
    },
    value: HealthConditionDiagnoseCreationMethod.Diagnose,
  },
  [HealthConditionDiagnoseCreationMethod.Disease]: {
    key: HealthConditionDiagnoseCreationMethod.Disease,
    get label() {
      return i18n.t("By Disease", { ns: "gcadmin" });
    },
    value: HealthConditionDiagnoseCreationMethod.Disease,
  },
};

export const healthConditionDiagnoseCreateTypeOptions = Object.values(healthConditionDiagnoseCreateTypeOptionsMap);
