/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HPageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramTeamListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamFilterInput>;
  sortBy?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamSortingInput>;
}>;


export type GuidedCareProgramTeamListQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeams?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProviderGuidedCareHealthProgramTeamCountableEdge', node: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null, description?: string | null, teamStatus?: Types.ProviderGuidedCareTeamStatus | null, createdDate?: any | null, lastModifiedDate?: any | null, provider: { __typename?: 'Vendor', name: string }, teamMembers?: Array<{ __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, isBackup?: boolean | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, guidedCareProgramTeamMember?: { __typename?: 'GuidedCareProgramTeamMember', position?: Types.TeamMemberPosition | null, specializationArabicDisplay?: string | null, specializationDisplay?: string | null } | null } | null> | null } }> } | null };


export const GuidedCareProgramTeamListDocument = gql`
    query GuidedCareProgramTeamList($first: Int, $after: String, $last: Int, $before: String, $filter: ProviderGuidedCareHealthProgramTeamFilterInput, $sortBy: ProviderGuidedCareHealthProgramTeamSortingInput) {
  providerGuidedCareHealthProgramTeams(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...HPageInfoFragment
    }
    edges {
      node {
        id
        name
        description
        teamStatus
        createdDate
        lastModifiedDate
        provider {
          name
        }
        teamMembers {
          id
          isBackup
          user {
            firstName
            lastName
          }
          guidedCareProgramTeamMember {
            position
            specializationArabicDisplay
            specializationDisplay
          }
        }
      }
    }
  }
}
    ${HPageInfoFragmentFragmentDoc}`;

/**
 * __useGuidedCareProgramTeamListQuery__
 *
 * To run a query within a React component, call `useGuidedCareProgramTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareProgramTeamListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareProgramTeamListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareProgramTeamListQuery, GuidedCareProgramTeamListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareProgramTeamListQuery, GuidedCareProgramTeamListQueryVariables>(GuidedCareProgramTeamListDocument, options);
      }
export function useGuidedCareProgramTeamListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareProgramTeamListQuery, GuidedCareProgramTeamListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareProgramTeamListQuery, GuidedCareProgramTeamListQueryVariables>(GuidedCareProgramTeamListDocument, options);
        }
export type GuidedCareProgramTeamListQueryHookResult = ReturnType<typeof useGuidedCareProgramTeamListQuery>;
export type GuidedCareProgramTeamListLazyQueryHookResult = ReturnType<typeof useGuidedCareProgramTeamListLazyQuery>;
export type GuidedCareProgramTeamListQueryResult = Apollo.QueryResult<GuidedCareProgramTeamListQuery, GuidedCareProgramTeamListQueryVariables>;