import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { FormMDTextEditor, FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { GuidedCareTemplatesAutocomplete } from "@health/autocompletes";

type GuidedCareProgramInformationFormProps = {
  isUpdateMode: boolean;
};

export const GuidedCareProgramInformationForm: FC<GuidedCareProgramInformationFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"name"} label={t("Program Name")} disabled={isUpdateMode} />
      </Grid>

      <Grid item xs={4}>
        <GuidedCareTemplatesAutocomplete name={"template"} disabled={isUpdateMode} filter={{ isActive: true, isDraft: false }} />
      </Grid>

      <Grid item xs={4}>
        <FormNumberField name={"price"} label={t("Price (AED)")} />
      </Grid>

      <Grid item xs={12}>
        <FormMDTextEditor name={"description"} label={t("Description")} />
      </Grid>
    </Grid>
  );
};
