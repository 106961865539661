import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, FormNumberField } from "@toolkit/ui";
import { IGuidedCareTemplateActivitiesItem } from "../../types";

export const useGuidedCareTemplateActivitiesItemsColumns = (): CustomTableColumnProps<IGuidedCareTemplateActivitiesItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "activity",
        header: t("Activity"),
        accessor: ({ activityType }) => activityType?.label,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ medicalMessage }) => medicalMessage?.value?.name || "-",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: ({ medicalMessage }) => medicalMessage?.value?.code || "-",
      },
      {
        key: "offsetInDays",
        header: t("Offset (Days)"),
        accessor: (_, index) => <FormNumberField name={`activitiesItems.${index}.offset`} label={t("Offset")} placeholder={t("Offset")} />,
      },
    ];
  }, [t]);
};
