import { z } from "zod";
import { guidedCareTemplateGoalRecommendationUpsertFormSchema } from "../TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";
import { zodSchema } from "@health/domains";

export const guidedCareTemplateGoalUpsertFormSchema = z.object({
  goalId: z.string().nullable().optional(),
  uniqueId: z.string().nullable().optional(),
  name: z.string(),
  nameAr: z.string().nullable().optional(),
  healthParameter: zodSchema.healthParameterAutocomplete,
  target: z.string(),
  description: z.string(),
  descriptionAr: z.string().nullable().optional(),
  recommendations: z.array(guidedCareTemplateGoalRecommendationUpsertFormSchema),
});

export type IGuidedCareTemplateGoalUpsertFormValues = z.infer<typeof guidedCareTemplateGoalUpsertFormSchema>;

export const guidedCareTemplateGoalUpsertFormDefaultValues: Partial<IGuidedCareTemplateGoalUpsertFormValues> = {
  goalId: undefined,
  uniqueId: undefined,
  name: undefined,
  nameAr: undefined,
  healthParameter: undefined,
  target: undefined,
  description: undefined,
  descriptionAr: undefined,
  recommendations: [],
};
