import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, MouseEvent } from "react";
import { GuidedCareProgramActivityServicesForm } from "../../forms/ProgramActivityServicesForm/GuidedCareProgramActivityServicesForm";

type GuidedCareProgramActivityServicesModalProps = {
  activityIndex: number;
  isReadOnly?: boolean;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareProgramActivityServicesModal: FC<GuidedCareProgramActivityServicesModalProps> = props => {
  const { activityIndex, isReadOnly, isOpen, onClose: handleClose } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog
      type='base'
      open={isOpen}
      title={t("Activity Services")}
      DialogTitleProps={{
        title: t("Activity Services"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <GuidedCareProgramActivityServicesForm isReadOnly={isReadOnly} activityIndex={activityIndex} />
    </CustomDialog>
  );
};
