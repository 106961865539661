import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { GridProvider } from "@health/domains";
import { HealthProgramTemplate } from "@health/queries/types";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";
import { useGuidedCareTemplateListQuery } from "pages/Templates/gql";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { useGuidedCareTemplateListColumns } from "./useGuidedCareTemplateListColumns";

export const GuidedCareTemplateListContainer = () => {
  const navigate = useNavigate();

  const { setGuidedCareTemplateListContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const onAddNewItemClick = () => {
    navigate(guidedCareTemplatesPaths.create.fullPath);
  };

  const onEditRowClick = (item: HealthProgramTemplate) => {
    navigate(guidedCareTemplatesPaths.update.fullPathWithParams({ templateId: item?.id }));
  };

  useEffect(() => {
    setGuidedCareTemplateListContainerBreadcrumb();
  }, [setGuidedCareTemplateListContainerBreadcrumb]);

  return (
    <GridProvider
      gridName={"guidedCareTemplateList"}
      columns={useGuidedCareTemplateListColumns()}
      query={useGuidedCareTemplateListQuery}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
