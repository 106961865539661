import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthSymptomsPaths } from "../constants";

export const useSetHealthSymptomsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Health Symptoms");

  const healthSymptomListBreadcrumb = useMemo(() => {
    return {
      name: t("Health Symptoms"),
      route: healthSymptomsPaths.list.fullPath,
    };
  }, [t]);

  const setHealthSymptomListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setHealthSymptomCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [healthSymptomListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, healthSymptomListBreadcrumb, t]);

  const setHealthSymptomUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthSymptomListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, healthSymptomListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthSymptomListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setHealthSymptomCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setHealthSymptomUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setHealthSymptomListContainerBreadcrumb,
    setHealthSymptomCreateContainerBreadcrumb,
    setHealthSymptomUpdateContainerBreadcrumb,
  ]);
};
