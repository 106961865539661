import { getAutocompleteEnumFilter } from "@toolkit/ui";
import { ProviderGuidedCareTeamStatus } from "@health/queries/types";
import { guidedCareTeamStatusOptions } from "./GuidedCareTeamStatus";
export const getGuidedCareTeamStatusColor = (status, palette) => {
    switch (status) {
        case ProviderGuidedCareTeamStatus.Approved:
            return palette.success.main;
        case ProviderGuidedCareTeamStatus.Pending:
            return palette.warning.main;
        case ProviderGuidedCareTeamStatus.Rejected:
            return palette.error.main;
    }
};
export const getGuidedCareTeamStatusOptionsFilter = ({ name, multiple }) => {
    return getAutocompleteEnumFilter({
        name,
        multiple,
        options: guidedCareTeamStatusOptions,
    });
};
