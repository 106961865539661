import { TFunction } from "@toolkit/i18n";
import { medicalMessagesPaths } from "./MedicalMessagesPaths";

export const createMedicalMessagesBreadcrumbs = (t: TFunction) => ({
  title: t("Patient Engagement Messages", { ns: "gcadmin" }),
  main: {
    id: "medical-message-list",
    name: t("Patient Engagement Messages", { ns: "gcadmin" }),
    route: medicalMessagesPaths.main.fullPath,
  },
  create: {
    id: "medical-message-new",
    name: t("New", { ns: "gcadmin" }),
  },
  field: (fieldName: string) => ({
    id: `medical-message-field-${fieldName}`,
    name: fieldName,
  }),
  variantsTitle: t("Medical Messages Variants", { ns: "gcadmin" }),
  variantList: (medicalMessageId: string) => ({
    id: `medical-message-variant-list`,
    name: "Variants",
    route: medicalMessagesPaths.variants.fullPathWithParams({ medicalMessageId }),
  }),
});
