import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, FormHelperText, Grid } from "@toolkit/ui";
import { HealthProgramMemberListsAutocomplete, HealthProgramNetworksAutocomplete, PayersAutocomplete } from "@health/autocompletes";

type GuidedCareProgramPayerNetworksFormProps = {
  selectedPayerId: string;
  isLoading?: boolean;
};

export const GuidedCareProgramPayerNetworksForm: FC<GuidedCareProgramPayerNetworksFormProps> = props => {
  const { selectedPayerId, isLoading } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <>
      <Grid item xs={12}>
        <FormCard title={t("Payer")} loading={isLoading} doYouHaveData>
          <Grid item xs={4}>
            <PayersAutocomplete name={"payer"} />
          </Grid>
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Covered Networks")} loading={isLoading} doYouHaveData>
          <Grid item xs={4}>
            <HealthProgramNetworksAutocomplete
              name={"coveredNetworks"}
              multiple
              disabled={!selectedPayerId}
              filter={{ payers: [selectedPayerId] }}
            />

            {!selectedPayerId && <FormHelperText>{t("Please Select a Payer")}</FormHelperText>}
          </Grid>
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Covered Members")} loading={isLoading} doYouHaveData>
          <Grid item xs={4}>
            <HealthProgramMemberListsAutocomplete
              name={"coveredMembers"}
              multiple
              disabled={!selectedPayerId}
              filter={{ payers: [selectedPayerId] }}
            />

            {!selectedPayerId && <FormHelperText>{t("Please Select a Payer")}</FormHelperText>}
          </Grid>
        </FormCard>
      </Grid>
    </>
  );
};
