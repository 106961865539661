import { createPathDefinition } from "@toolkit/core";

export const guidedCareTemplatesRoute = `/guided-care/templates`;

export const guidedCareTemplatesPaths = createPathDefinition(guidedCareTemplatesRoute, {
  list: "",
  create: "new",
  update: ":templateId",
  clone: ":templateId/clone",
  timeline: ":templateId/timeline",
  activitiesItemsManage: ":templateId/activities-items",
  activityItemList: "activities/:activityId/activity-items",
  interventionActivityItemList: "intervention-activities/:activityId/activity-items",
});
