import { FC, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useArticleActivationMutation } from "../../gql";
import { formatGraphQLError } from "@toolkit/apollo";

type ArticleActivationProps = {
  id: string;
  isActive: boolean;
};

export const ArticleActivation: FC<ArticleActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();

  const [activationArticle, { loading: isSubmitting }] = useArticleActivationMutation({
    onCompleted: mutationData => {
      setActive(!!mutationData?.updateArticleActiveStatus?.active);
      succeeded(t("Article updated successfully"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    activationArticle({
      variables: { id },
    });
  };

  return <CustomToggleButton disabled={isSubmitting} isLoading={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
