import { useCallback, useMemo } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateInterventionItem } from "pages/Templates/types";
import { GuidedCareTemplateInterventionActivitiesModalApi } from "../../modals/TemplateInterventionActivities/GuidedCareTemplateInterventionActivitiesModal";

export const useGuidedCareTemplateInterventionsColumns = (): CustomTableColumnProps<IGuidedCareTemplateInterventionItem>[] => {
  const { t } = useTranslation("gcadmin");

  const onManageActivitiesClick = useCallback((interventionIndex: number) => {
    GuidedCareTemplateInterventionActivitiesModalApi.open({
      interventionIndex,
    });
  }, []);

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ description, descriptionAr }) => pickLocalizedValue(description, descriptionAr),
      },
      {
        key: "duration",
        header: t("Duration (Days)"),
        accessor: "duration",
      },
      {
        key: "activities",
        header: t("Activities"),
        accessor: (data, index) => (
          <Button onClick={() => onManageActivitiesClick(index)} variant={"contained"}>
            {t("Manage")}
          </Button>
        ),
      },
    ];
  }, [onManageActivitiesClick, t]);
};
