import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { IGuidedCareTemplateActivitiesItemsManageContainerParams } from "pages/Templates/types";
import { GuidedCareTemplateActivitiesItemsForm } from "../../forms/TemplateActivitiesItems/GuidedCareTemplateActivitiesItemsForm";

export const GuidedCareTemplateActivitiesItemsManageContainer = () => {
  const { setGuidedCareTemplateActivitiesItemListContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const { templateId } = useParams<IGuidedCareTemplateActivitiesItemsManageContainerParams>();

  useEffect(() => {
    setGuidedCareTemplateActivitiesItemListContainerBreadcrumb();
  }, [setGuidedCareTemplateActivitiesItemListContainerBreadcrumb]);

  return <GuidedCareTemplateActivitiesItemsForm templateId={templateId!} />;
};
