import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormActions, FormCard, Grid, PageWrapper, useAddToast } from "@toolkit/ui";
import { HealthProgramTemplate, TeamMemberPosition } from "@health/queries/types";
import { convertTemplateToFormValues } from "pages/Templates/others";
import { IGuidedCareTemplateUpsertFormEvent } from "pages/Templates/types";
import { GuidedCareTemplatePublish } from "../../components/TemplatePublish/GuidedCareTemplatePublish";
import { GuidedCareTemplateActivitiesForm } from "../TemplateActivities/GuidedCareTemplateActivitiesForm";
import { GuidedCareTemplateInformationForm } from "../TemplateInformation/GuidedCareTemplateInformationForm";
import { GuidedCareTemplateMembersForm } from "../TemplateMembers/GuidedCareTemplateMembersForm";
import { GuidedCareTemplateVerificationForm } from "../TemplateVerification/GuidedCareTemplateVerificationForm";
import {
  GuidedCareTemplateUpsertFormSchema,
  IGuidedCareTemplateUpsertFormValues,
  guidedCareTemplateUpsertFormDefaultValues,
} from "./GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateUpsertFormStyle } from "./useGuidedCareTemplateUpsertFormStyle";
import { GuidedCareTemplateGoals } from "../TemplateGoals/GuidedCareTemplateGoals";
import { GuidedCareTemplateInterventions } from "../TemplateInterventions/GuidedCareTemplateInterventions";

type GuidedCareTemplateUpsertFormProps = {
  submitButtonLabel: string;
  isClone?: boolean;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  template?: HealthProgramTemplate;
  onChange: (values: IGuidedCareTemplateUpsertFormEvent) => void;
};

export const GuidedCareTemplateUpsertForm: FC<GuidedCareTemplateUpsertFormProps> = props => {
  const { submitButtonLabel, isClone, isLoading, isButtonDisabled, template, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { failed } = useAddToast();

  const { classes } = useGuidedCareTemplateUpsertFormStyle();

  const form = useCustomForm<IGuidedCareTemplateUpsertFormValues>({
    defaultValues: guidedCareTemplateUpsertFormDefaultValues,
    schema: GuidedCareTemplateUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const isVerified = watch("isVerified");

  const onSubmit = (values: IGuidedCareTemplateUpsertFormValues) => {
    const teamLeader = values?.teamMembers?.find(item => item?.position?.value === TeamMemberPosition.TeamLeader);

    if (!teamLeader?.specialization) {
      return failed(t("Team Leader must have a specialization"));
    }

    if (!values?.activities?.length) {
      return failed(t("Template must have at least one activity"));
    }

    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (template) {
      const _template = convertTemplateToFormValues(template, isClone);
      setValues(_template);
    }
  }, [isClone, setValues, template]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box className={classes.formActionsWrapper}>
              <FormActions
                hasCancel
                hasFormButton
                isLoading={isLoading}
                formButtonTitle={submitButtonLabel}
                onNavigation={handleCancel}
                newButtonDisabled={isButtonDisabled}
              />

              {template?.isDraft && template?.code && !isClone && <GuidedCareTemplatePublish templateCode={template.code} />}
            </Box>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormCard title={t("Template Information")} loading={isLoading} doYouHaveData>
                <GuidedCareTemplateInformationForm template={template} isClone={isClone} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Template Verification")} loading={isLoading} doYouHaveData>
                <GuidedCareTemplateVerificationForm isVerified={isVerified} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateMembersForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateActivitiesForm template={template} isClone={isClone} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateInterventions />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateGoals />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
