import { getAutocompleteFilter } from "@toolkit/ui";
import { useGuidedCareTemplatesAutocompleteQuery } from "./gql";
export const getGuidedCareTemplatesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "healthProgramTemplates",
        query: useGuidedCareTemplatesAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
