/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabTemplateActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type LabTemplateActivationMutation = { __typename?: 'Mutation', updateLabTemplateActiveStatus?: { __typename?: 'LabTemplate', isActive?: boolean | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const LabTemplateActivationDocument = gql`
    mutation LabTemplateActivation($id: ID!) {
  updateLabTemplateActiveStatus(id: $id) {
    isActive
    errors {
      field
      message
    }
  }
}
    `;
export type LabTemplateActivationMutationFn = Apollo.MutationFunction<LabTemplateActivationMutation, LabTemplateActivationMutationVariables>;

/**
 * __useLabTemplateActivationMutation__
 *
 * To run a mutation, you first call `useLabTemplateActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLabTemplateActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [labTemplateActivationMutation, { data, loading, error }] = useLabTemplateActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabTemplateActivationMutation(baseOptions?: Apollo.MutationHookOptions<LabTemplateActivationMutation, LabTemplateActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LabTemplateActivationMutation, LabTemplateActivationMutationVariables>(LabTemplateActivationDocument, options);
      }
export type LabTemplateActivationMutationHookResult = ReturnType<typeof useLabTemplateActivationMutation>;
export type LabTemplateActivationMutationResult = Apollo.MutationResult<LabTemplateActivationMutation>;
export type LabTemplateActivationMutationOptions = Apollo.BaseMutationOptions<LabTemplateActivationMutation, LabTemplateActivationMutationVariables>;