import { z } from "zod";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { zodSchema } from "@health/domains";
import { GuidedCareTemplateActivityServiceUpsertFormSchema } from "../TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertFormSchema";
import { GuidedCareTemplateMemberUpsertFormSchema } from "../TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";

export const guidedCareTemplateInterventionActivityUpsertFormSchema = z.object({
  templateActivityType: zodSchema.guidedCareActivityTypeAutocompleteEnum,
  teamMember: createZodAutocompleteObject(GuidedCareTemplateMemberUpsertFormSchema),
  numberOfOccurrences: z.coerce.number().min(1),
  medicalForm: zodSchema.medicalFormAutocompleteOptional,
  medicalMessage: zodSchema.medicalMessageAutocompleteOptional,
  frequencyType: zodSchema.appointmentFrequencyTypeAutocompleteEnum,
  activityServices: z.array(GuidedCareTemplateActivityServiceUpsertFormSchema).optional(),
  beforeActivity: zodSchema.medicalMessageAutocompleteOptional,
  beforeActivityOffset: z.coerce.number().min(0).nullable().optional(),
  afterActivity: zodSchema.medicalMessageAutocompleteOptional,
  afterActivityOffset: z.coerce.number().min(0).nullable().optional(),
  activityId: z.string().optional(),
});

export type IGuidedCareTemplateInterventionActivityUpsertFormValues = z.infer<
  typeof guidedCareTemplateInterventionActivityUpsertFormSchema
>;

export const guidedCareTemplateInterventionActivityUpsertFormDefaultValues: Partial<IGuidedCareTemplateInterventionActivityUpsertFormValues> =
  {
    templateActivityType: undefined,
    frequencyType: undefined,
    numberOfOccurrences: undefined,
    medicalForm: undefined,
    medicalMessage: undefined,
    activityServices: [],
    beforeActivity: null,
    beforeActivityOffset: undefined,
    afterActivity: null,
    afterActivityOffset: undefined,
    teamMember: undefined,
  };
