import { getAutocompleteFilter } from "@toolkit/ui";
import { useGuidedCareProgramTeamsAutocompleteQuery } from "./gql";
export const getGuidedCareProgramTeamsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "providerGuidedCareHealthProgramTeams",
        query: useGuidedCareProgramTeamsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
        filterSearchKey: "name",
    });
};
