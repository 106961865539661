import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { articlesCategoriesBreadcrumb } from "../../constants";
import { articlesCategoriesPaths } from "../../constants";
import { useArticleCategoryCreateMutation } from "../../gql";
import { convertArticleCategoryFormValuesToBackEndValues } from "../../utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArticleCategoryUpsertForm,
  IArticleCategoryUpsertFormEvent,
} from "../../forms/ArticleCategoryFormUpsert/ArticleCategoryUpsertForm";

export const ArticleCategoryCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();

  const [createArticleCategory, { loading: isSubmitting }] = useArticleCategoryCreateMutation({
    onCompleted: () => {
      succeeded(t("Article Category created successfully"));
      navigate(articlesCategoriesPaths.list.fullPath);
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onArticleCategoryUpsertFormChange = (event: IArticleCategoryUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertArticleCategoryFormValuesToBackEndValues(event.payload.values);

      createArticleCategory({
        variables: {
          input: values,
        },
      });
    }
  };

  useEffect(() => {
    const { title, main, create } = articlesCategoriesBreadcrumb();
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);

  return (
    <ArticleCategoryUpsertForm submitButtonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onArticleCategoryUpsertFormChange} />
  );
};
