import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { TFunction } from "@toolkit/i18n";
import { riskFactorTemplatesRoute, riskFactorTemplatesPaths } from "./RiskFactorTemplatesPaths";
import {
  RiskFactorTemplateListContainer,
  RiskFactorTemplateCreateContainer,
  RiskFactorTemplateUpdateContainer,
  RiskFactorTemplateCloneContainer,
  RiskFactorTemplateParameterListContainer,
} from "../containers";

export const riskFactorTemplatesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "risk-factor-templates-routes",
    text: t("Risk Factor Templates", { ns: "gcadmin" }),
    route: riskFactorTemplatesRoute,
    subItems: [
      {
        id: "risk-factor-templates-list-route",
        route: riskFactorTemplatesPaths.list.route,
        fullPath: riskFactorTemplatesPaths.list.fullPath,
        element: <RiskFactorTemplateListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "risk-factor-templates-create-route",
        route: riskFactorTemplatesPaths.create.route,
        fullPath: riskFactorTemplatesPaths.create.fullPath,
        hidden: true,
        element: <RiskFactorTemplateCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "risk-factor-templates-update-route",
        route: riskFactorTemplatesPaths.update.route,
        fullPath: riskFactorTemplatesPaths.update.fullPath,
        hidden: true,
        element: <RiskFactorTemplateUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "risk-factor-templates-clone-route",
        route: riskFactorTemplatesPaths.clone.route,
        fullPath: riskFactorTemplatesPaths.clone.fullPath,
        hidden: true,
        element: <RiskFactorTemplateCloneContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "risk-factor-templates-parameter-list-route",
        route: riskFactorTemplatesPaths.parameterList.route,
        fullPath: riskFactorTemplatesPaths.parameterList.fullPath,
        hidden: true,
        element: <RiskFactorTemplateParameterListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
