import { Article, Maybe } from "@health/queries/types";
import { CustomDialog, Grid, ShowButton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";

type ArticleRelatedArticlesProps = {
  relatedArticles: Maybe<Maybe<Article>[]>;
};

export const ArticleRelatedArticles: FC<ArticleRelatedArticlesProps> = props => {
  const { relatedArticles } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog type={"info"} button={<ShowButton />} title={t("Related Articles")}>
      {relatedArticles?.length ? (
        <Grid container spacing={2}>
          {relatedArticles?.map(item => (
            <Grid key={item?.id} item xs={12}>
              {item?.title}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>{t("There are no related articles")}</Typography>
      )}
    </CustomDialog>
  );
};
