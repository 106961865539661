/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionDiagnoseCreateMutationVariables = Types.Exact<{
  diagnosisCode?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  healthConditionId: Types.Scalars['ID'];
}>;


export type HealthConditionDiagnoseCreateMutation = { __typename?: 'Mutation', addDiagnosisToHealthCondition?: Array<{ __typename?: 'HealthConditionDiagnosis', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null> | null };


export const HealthConditionDiagnoseCreateDocument = gql`
    mutation HealthConditionDiagnoseCreate($diagnosisCode: [String!], $healthConditionId: ID!) {
  addDiagnosisToHealthCondition(
    diagnosisCode: $diagnosisCode
    healthConditionId: $healthConditionId
  ) {
    errors {
      field
      message
    }
  }
}
    `;
export type HealthConditionDiagnoseCreateMutationFn = Apollo.MutationFunction<HealthConditionDiagnoseCreateMutation, HealthConditionDiagnoseCreateMutationVariables>;

/**
 * __useHealthConditionDiagnoseCreateMutation__
 *
 * To run a mutation, you first call `useHealthConditionDiagnoseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionDiagnoseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthConditionDiagnoseCreateMutation, { data, loading, error }] = useHealthConditionDiagnoseCreateMutation({
 *   variables: {
 *      diagnosisCode: // value for 'diagnosisCode'
 *      healthConditionId: // value for 'healthConditionId'
 *   },
 * });
 */
export function useHealthConditionDiagnoseCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthConditionDiagnoseCreateMutation, HealthConditionDiagnoseCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthConditionDiagnoseCreateMutation, HealthConditionDiagnoseCreateMutationVariables>(HealthConditionDiagnoseCreateDocument, options);
      }
export type HealthConditionDiagnoseCreateMutationHookResult = ReturnType<typeof useHealthConditionDiagnoseCreateMutation>;
export type HealthConditionDiagnoseCreateMutationResult = Apollo.MutationResult<HealthConditionDiagnoseCreateMutation>;
export type HealthConditionDiagnoseCreateMutationOptions = Apollo.BaseMutationOptions<HealthConditionDiagnoseCreateMutation, HealthConditionDiagnoseCreateMutationVariables>;