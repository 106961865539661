import { useTranslation } from "@toolkit/i18n";
import { FormMDTextEditor, Grid } from "@toolkit/ui";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";

export const ArticleContentsForm = () => {
  const { t } = useTranslation("provider");

  const { accessToken } = useOidcUserProfile();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormMDTextEditor name={"content"} label={t("English")} token={accessToken} />
      </Grid>

      <Grid item xs={12}>
        <FormMDTextEditor name={"contentAr"} label={t("Arabic")} direction={"rtl"} token={accessToken} />
      </Grid>
    </Grid>
  );
};
