/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabTemplateCloneMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.LabTemplateInput;
}>;


export type LabTemplateCloneMutation = { __typename?: 'Mutation', duplicateLabTemplate?: { __typename?: 'LabTemplate', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const LabTemplateCloneDocument = gql`
    mutation LabTemplateClone($id: ID!, $input: LabTemplateInput!) {
  duplicateLabTemplate(id: $id, input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type LabTemplateCloneMutationFn = Apollo.MutationFunction<LabTemplateCloneMutation, LabTemplateCloneMutationVariables>;

/**
 * __useLabTemplateCloneMutation__
 *
 * To run a mutation, you first call `useLabTemplateCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLabTemplateCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [labTemplateCloneMutation, { data, loading, error }] = useLabTemplateCloneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLabTemplateCloneMutation(baseOptions?: Apollo.MutationHookOptions<LabTemplateCloneMutation, LabTemplateCloneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LabTemplateCloneMutation, LabTemplateCloneMutationVariables>(LabTemplateCloneDocument, options);
      }
export type LabTemplateCloneMutationHookResult = ReturnType<typeof useLabTemplateCloneMutation>;
export type LabTemplateCloneMutationResult = Apollo.MutationResult<LabTemplateCloneMutation>;
export type LabTemplateCloneMutationOptions = Apollo.BaseMutationOptions<LabTemplateCloneMutation, LabTemplateCloneMutationVariables>;