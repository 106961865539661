import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { riskFactorTemplatesPaths } from "../constants";

export const useSetRiskFactorTemplatesBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "CLONE" | "PARAMETERS_LIST",
  display?: string
) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Risk Factor Templates");

  const riskFactorTemplateListBreadcrumb = useMemo(() => {
    return {
      name: t("Risk Factor Templates"),
      route: riskFactorTemplatesPaths.list.fullPath,
    };
  }, [t]);

  const setRiskFactorTemplateListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setRiskFactorTemplateCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [riskFactorTemplateListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, riskFactorTemplateListBreadcrumb, t]);

  const setRiskFactorTemplateUpdateContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [riskFactorTemplateListBreadcrumb, { name: display }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, riskFactorTemplateListBreadcrumb]);

  const setRiskFactorTemplateCloneContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [riskFactorTemplateListBreadcrumb, { name: display }, { name: t("Clone") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, riskFactorTemplateListBreadcrumb, t]);

  const setRiskFactorTemplateParameterListContainerBreadcrumb = useCallback(() => {
    if (display) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [riskFactorTemplateListBreadcrumb, { name: display }, { name: t("Parameters") }],
      });
    }
  }, [display, setBreadCrumb, breadcrumbTitle, riskFactorTemplateListBreadcrumb, t]);

  useEffect(() => {
    if (containerType === "LIST") {
      setRiskFactorTemplateListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setRiskFactorTemplateCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setRiskFactorTemplateUpdateContainerBreadcrumb();
    } else if (containerType === "CLONE") {
      setRiskFactorTemplateCloneContainerBreadcrumb();
    } else if (containerType === "PARAMETERS_LIST") {
      setRiskFactorTemplateParameterListContainerBreadcrumb();
    }
  }, [
    containerType,
    setRiskFactorTemplateListContainerBreadcrumb,
    setRiskFactorTemplateCreateContainerBreadcrumb,
    setRiskFactorTemplateUpdateContainerBreadcrumb,
    setRiskFactorTemplateCloneContainerBreadcrumb,
    setRiskFactorTemplateParameterListContainerBreadcrumb,
  ]);
};
