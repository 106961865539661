import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import {
  HealthConditionDiagnoseCreateForm,
  HealthConditionDiagnoseCreateFormRef,
  IHealthConditionDiagnoseCreateFormEvent,
} from "../../forms/HealthConditionDiagnoseCreate/HealthConditionDiagnoseCreateForm";

type IOpen = () => void;
type IClose = () => void;

export const HealthConditionDiagnoseCreateModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type HealthConditionDiagnoseCreateModalProps = {
  isLoading?: boolean;
  onChange: (event: IHealthConditionDiagnoseCreateFormEvent) => void;
};

export const HealthConditionDiagnoseCreateModal: FC<HealthConditionDiagnoseCreateModalProps> = props => {
  const { isLoading, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);

  const healthConditionDiagnoseCreateFormRef = useRef<HealthConditionDiagnoseCreateFormRef>(null);

  const { t } = useTranslation("gcadmin");

  const onButtonClick = () => {
    healthConditionDiagnoseCreateFormRef.current?.submit();
  };

  const open: IOpen = () => {
    setIsOpen(true);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    HealthConditionDiagnoseCreateModalApi.open = open;
    HealthConditionDiagnoseCreateModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Add New Diagnose"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Add"),
        disableSubmit: isLoading,
        onSubmit: onButtonClick,
      }}
    >
      <HealthConditionDiagnoseCreateForm ref={healthConditionDiagnoseCreateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
