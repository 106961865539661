import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  GuidedCareTemplateActivityItemsUpdateMutation,
  useGuidedCareTemplateActivityGetQuery,
  useGuidedCareTemplateActivityItemsUpdateMutation,
} from "pages/Templates/gql";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { convertActivityItemsFormValuesToBackEndValues } from "pages/Templates/others";
import { IGuidedCareActivityItemsFormEvent, IGuidedCareTemplateActivityItemsContainerParams } from "pages/Templates/types";
import { GuidedCareTemplateActivityItemsForm } from "../../forms/TemplateActivityItems/GuidedCareTemplateActivityItemsForm";

export const GuidedCareTemplateActivityItemListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();
  const navigate = useNavigate();

  const { setGuidedCareTemplateActivityItemListContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const { activityId } = useParams<IGuidedCareTemplateActivityItemsContainerParams>();

  const { data, loading, refetch } = useGuidedCareTemplateActivityGetQuery({
    variables: {
      templateActivityId: activityId!,
    },
  });

  const templateActivity = data?.templateActivity;

  const [fetchGuidedCareTemplateActivityItemsUpdateMutation, { loading: isSubmitting }] = useGuidedCareTemplateActivityItemsUpdateMutation({
    onCompleted: (mutationData: GuidedCareTemplateActivityItemsUpdateMutation) => {
      const errors = mutationData?.updateTemplateActivityItems?.templateActivityErrors;

      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Activity items have been updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const onActivityItemsFormChange = (event: IGuidedCareActivityItemsFormEvent) => {
    if (event.type === "SUBMIT") {
      fetchGuidedCareTemplateActivityItemsUpdateMutation({
        variables: {
          activityId: activityId!,
          activityItems: convertActivityItemsFormValuesToBackEndValues(event.payload.values.activityItems),
        },
      }).then(() => refetch());
    }
  };

  useEffect(() => {
    setGuidedCareTemplateActivityItemListContainerBreadcrumb();
  }, [setGuidedCareTemplateActivityItemListContainerBreadcrumb]);

  return (
    <GuidedCareTemplateActivityItemsForm
      isLoading={loading || isSubmitting}
      activity={templateActivity}
      onChange={onActivityItemsFormChange}
    />
  );
};
