import { z } from "zod";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { guidedCareTemplateInterventionUpsertFormSchema } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";

export const guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema = z.object({
  interventions: z.array(createZodAutocompleteObject(guidedCareTemplateInterventionUpsertFormSchema)),
});

export type IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues = z.infer<
  typeof guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema
>;

export const guidedCareTemplateGoalRecommendationInterventionUpsertFormDefaultValues: Partial<IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues> =
  {
    interventions: [],
  };
