import { z } from "zod";
import { GuidedCareProgramActivityItemUpdateFormSchema } from "../ProgramActivityItemUpdate/GuidedCareProgramActivityItemUpdateFormSchema";

export const GuidedCareProgramActivityItemsFormSchema = z.object({
  activityItems: z.array(GuidedCareProgramActivityItemUpdateFormSchema),
});

export type IGuidedCareProgramActivityItemsFormValues = z.infer<typeof GuidedCareProgramActivityItemsFormSchema>;

export const guidedCareProgramActivityItemsFormDefaultValues: Partial<IGuidedCareProgramActivityItemsFormValues> = {
  activityItems: [],
};
