import { HealthProgramTemplate } from "@health/queries/types";
import { useCustomFormContext, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography } from "@toolkit/ui";
import { IGuidedCareTemplateActivityItem, IGuidedCareTemplateActivityUpsertFormEvent } from "pages/Templates/types";
import { FC, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { GuidedCareTemplateActivityUpsertModal } from "../../modals/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertModal";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateActivitiesColumns } from "./useGuidedCareTemplateActivitiesColumns";
import { useGuidedCareTemplateActivitiesFormStyle } from "./useGuidedCareTemplateActivitiesFormStyle";

type GuidedCareTemplateActivitiesFormProps = {
  template?: HealthProgramTemplate;
  isClone?: boolean;
};

export const GuidedCareTemplateActivitiesForm: FC<GuidedCareTemplateActivitiesFormProps> = props => {
  const { template, isClone } = props;

  const [selectedActivity, setSelectedActivity] = useState<IGuidedCareTemplateActivityItem>();

  const { t } = useTranslation("gcadmin");
  const { open, handleOpen, handleClose } = useOpenState();

  const { classes } = useGuidedCareTemplateActivitiesFormStyle();

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { control, getValues } = form;

  const { activities } = getValues();

  const { fields, prepend, remove, update } = useFieldArray<IGuidedCareTemplateUpsertFormValues, "activities">({
    control,
    name: "activities",
  });

  const onTemplateActivityUpsertModalChange = (event: IGuidedCareTemplateActivityUpsertFormEvent) => {
    if (event.type === "CREATE") {
      prepend(event.payload.values);
      handleClose();
    } else if (event.type === "UPDATE" && selectedActivity) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedActivity.id);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, { ...activities[updatedFieldIndex], ...event.payload.values });
      }
      handleClose();
    }
  };

  const onAddClick = () => {
    setSelectedActivity(undefined);
    handleOpen();
  };

  const onEditRowClick = (item: IGuidedCareTemplateActivityItem) => {
    setSelectedActivity(item);
    handleOpen();
  };

  const onDeleteRowClick = (item: IGuidedCareTemplateActivityItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);
    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  return (
    <Grid container spacing={2}>
      <GuidedCareTemplateActivityUpsertModal
        isOpen={open}
        onClose={handleClose}
        activity={selectedActivity}
        onChange={onTemplateActivityUpsertModalChange}
      />

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography className={classes.title}> {t("Template Activities")} </Typography>
          </Grid>

          <Grid xs={4} lg={2}>
            <Button startIcon={<PlusIcon />} fullWidth onClick={onAddClick}>
              {t("Add Activity")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareTemplateActivitiesColumns({ template, isClone })}
          isEditVisible
          isDeleteVisible
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
