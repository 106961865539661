import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { HealthParametersRoutesIcon } from "../components/HealthParametersRoutesIcon/HealthParametersRoutesIcon";
import {
  HealthParameterCreateContainer,
  HealthParameterDecisionCreateContainer,
  HealthParameterDecisionUpdateContainer,
  HealthParameterListContainer,
  HealthParameterUpdateContainer,
} from "../containers";
import { healthParametersPaths, healthParametersRoute } from "./HealthParametersPaths";

export const healthParametersRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "health-parameters-routes",
    text: t("Health Parameters", { ns: "gcadmin" }),
    route: healthParametersRoute,
    icon: <HealthParametersRoutesIcon />,
    subItems: [
      {
        id: "health-parameter-list-route",
        route: healthParametersPaths.list.route,
        fullPath: healthParametersPaths.list.fullPath,
        element: <HealthParameterListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-parameter-create-route",
        route: healthParametersPaths.create.route,
        fullPath: healthParametersPaths.create.fullPath,
        hidden: true,
        element: <HealthParameterCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-parameter-update-route",
        route: healthParametersPaths.update.route,
        fullPath: healthParametersPaths.update.fullPath,
        hidden: true,
        element: <HealthParameterUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-parameter-decision-create-route",
        route: healthParametersPaths.decisionCreate.route,
        fullPath: healthParametersPaths.decisionCreate.fullPath,
        hidden: true,
        element: <HealthParameterDecisionCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-parameter-decision-update-route",
        route: healthParametersPaths.decisionUpdate.route,
        fullPath: healthParametersPaths.decisionUpdate.fullPath,
        hidden: true,
        element: <HealthParameterDecisionUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
