import { z } from "zod";
import { zodSchema } from "@health/domains";

export const GuidedCareTemplateActivityServiceUpsertFormSchema = z.object({
  serviceId: z.string().optional(),
  standard: zodSchema.systemCodeAutocompleteEnumOptional,
  service: zodSchema.systemCodeAutocompleteOptional,
});

export type IGuidedCareTemplateActivityServiceUpsertFormValues = z.infer<typeof GuidedCareTemplateActivityServiceUpsertFormSchema>;

export const guidedCareTemplateActivityServiceUpsertFormDefaultValues: Partial<IGuidedCareTemplateActivityServiceUpsertFormValues> = {
  serviceId: undefined,
  standard: undefined,
  service: undefined,
};
