import { PageWrapper, PlusIcon, StyledButton, useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import {
  HealthConditionParameterListDocument,
  useHealthConditionParameterCreateMutation,
  useHealthConditionParameterDeleteMutation,
  useHealthConditionParameterListQuery,
} from "../../gql";
import { IHealthConditionParameterListContainerParams } from "../../types";
import { HealthCondition, HealthParameter } from "@health/queries/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import {
  HealthConditionParameterCreateModal,
  HealthConditionParameterCreateModalApi,
} from "../../modals/HealthConditionParameterCreate/HealthConditionParameterCreateModal";
import { IHealthConditionParameterCreateFormEvent } from "../../forms/HealthConditionParameterCreate/HealthConditionParameterCreateForm";
import React from "react";
import { HealthConditionInformation } from "../../components/HealthConditionInformation/HealthConditionInformation";
import { useSetHealthConditionsBreadcrumbs } from "../../hooks";
import { healthConditionsPaths } from "../../constants";
import { HealthParameterCustomTableList } from "../../../HealthParameters/components";

export const HealthConditionParameterListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { healthConditionId } = useParams<IHealthConditionParameterListContainerParams>();

  const { data, loading } = useHealthConditionParameterListQuery({
    variables: {
      id: healthConditionId!,
    },
    skip: !healthConditionId,
  });

  const healthCondition = data?.getHealthCondition as HealthCondition;
  const healthConditionParameters = healthCondition?.fields as HealthParameter[];

  const [createHealthConditionParameter, { loading: isCreating }] = useHealthConditionParameterCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addFieldToHealthCondition?.errors?.length) {
        succeeded(t("Health Condition Parameter added successfully"));
        HealthConditionParameterCreateModalApi.close();
      } else {
        failed(t("Health Condition Parameter add failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: HealthConditionParameterListDocument,
        variables: {
          id: healthConditionId,
        },
      },
    ],
  });

  const [deleteHealthConditionParameter, { loading: isDeleting }] = useHealthConditionParameterDeleteMutation({
    onCompleted: mutationData => {
      if (!mutationData?.deleteFieldFromHealthCondition?.errors?.length) {
        succeeded(t("Health Condition Parameter deleted successfully"));
      } else {
        failed(t("Health Condition Parameter delete failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: HealthConditionParameterListDocument,
        variables: {
          id: healthConditionId,
        },
      },
    ],
  });

  const onAddNewItemClick = () => {
    HealthConditionParameterCreateModalApi.open();
  };

  const onDeleteRowClick = (item: HealthParameter) => {
    deleteHealthConditionParameter({
      variables: {
        healthConditionId: healthConditionId!,
        healthParameterId: item?.id!,
      },
    });
  };

  const onCancelClick = () => {
    navigate(healthConditionsPaths.list.fullPath);
  };

  const onHealthConditionParameterCreateModalChange = (event: IHealthConditionParameterCreateFormEvent) => {
    if (event.type === "CREATE") {
      createHealthConditionParameter({
        variables: {
          healthConditionId: healthConditionId!,
          healthParameterId: event.payload.values.healthParameter?.value?.id,
        },
      });
    }
  };

  useSetHealthConditionsBreadcrumbs("PARAMETERS_LIST", pickLocalizedValue(healthCondition?.display!, healthCondition?.arabicDisplay!));

  return (
    <>
      <HealthConditionParameterCreateModal isLoading={isCreating} onChange={onHealthConditionParameterCreateModalChange} />

      <PageWrapper
        actions={
          <>
            <StyledButton color={"success"} startIcon={<PlusIcon />} onClick={onAddNewItemClick}>
              {t("Add New")}
            </StyledButton>

            <StyledButton variant={"outlined"} onClick={onCancelClick}>
              {t("Cancel")}
            </StyledButton>
          </>
        }
      >
        <HealthConditionInformation healthCondition={healthCondition} isLoading={loading} />

        <HealthParameterCustomTableList
          healthParameters={healthConditionParameters}
          isLoading={loading}
          isRowDeletable={!isDeleting}
          onDeleteRow={onDeleteRowClick}
        />
      </PageWrapper>
    </>
  );
};
