import { initializeDomainsConfig } from "@health/domains";
import { initializeCoreConfig } from "@toolkit/core";
import { initializeI18nConfig } from "@toolkit/i18n";
import { getEnvVariable } from "./env";

export default function setupPackages() {
  initializeI18nConfig({
    enableTolgeeWizard: getEnvVariable("ENABLE_TOLGEE_WIZARD"),
    tolgeeProjectID: getEnvVariable("TOLGEE_PROJECT_ID"),
    apiUrl: getEnvVariable("TOLGEE_API_URL"),
    apiKey: getEnvVariable("TOLGEE_API_KEY"),
  });
  initializeCoreConfig({
    baseMediaUrl: getEnvVariable("BASE_MEDIA_URL"),
    fileServiceApiUrl: getEnvVariable("FILE_SERVICE_API_URL"),
    utils: {
      getAccessToken: () => {
        const data = localStorage.getItem(
          `oidc.user:${getEnvVariable("KEYCLOAK_REALM_LINK")}:${getEnvVariable("KEYCLOAK_CLIENT_ID_GUIDED_CARE")}`
        );
        return data ? JSON.parse(data)?.access_token : null;
      },
    },
  });
  initializeDomainsConfig({
    fileServiceApiUrl: getEnvVariable("FILE_SERVICE_API_URL"),
    flowiseBaseUrl: getEnvVariable("FLOWISE_BASE_URL"),
    serverUrl: getEnvVariable("SERVER_URL"),
  });
}
