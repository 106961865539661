import { FormActions, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  labTemplateUpsertFormSchema,
  ILabTemplateUpsertFormValues,
  labTemplateUpsertFormDefaultValues,
} from "./LabTemplateUpsertFormSchema";
import { FC, useEffect } from "react";
import { CodeSystemCode, LabTemplate } from "@health/queries/types";
import { useNavigate } from "react-router-dom";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { convertLabTemplateToFormValues } from "../../others/utils";
import { labTemplatesPaths } from "../../constants";

export type ILabTemplateUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: ILabTemplateUpsertFormValues;
  };
};

type LabTemplateUpsertFormProps = {
  labTemplate?: LabTemplate;
  submitButtonLabel: string;
  isClone?: boolean;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: ILabTemplateUpsertFormEvent) => void;
};

export const LabTemplateUpsertForm: FC<LabTemplateUpsertFormProps> = props => {
  const { labTemplate, submitButtonLabel, isClone, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const form = useCustomForm<ILabTemplateUpsertFormValues>({
    defaultValues: labTemplateUpsertFormDefaultValues,
    schema: labTemplateUpsertFormSchema,
  });
  console.log("form", form.getValues());
  const { setValues, handleSubmit } = form;

  const onSubmit = (values: ILabTemplateUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onCancelClick = () => {
    navigate(labTemplatesPaths.list.fullPath);
  };

  useEffect(() => {
    if (labTemplate) {
      const _labTemplate = convertLabTemplateToFormValues(labTemplate, !!isClone);
      setValues(_labTemplate);
    }
  }, [labTemplate, isClone, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={onCancelClick}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Lab Template Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"display"} label={t("Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"arabicDisplay"} label={t("Arabic Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <SystemCodeAutocomplete
                      name={"code"}
                      disabled={!!labTemplate && !isClone}
                      filter={{ codeSystemCode: CodeSystemCode.LabTest }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormSwitch name={"isActive"} label={t("Is Active")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
