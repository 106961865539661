/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplatePublishMutationVariables = Types.Exact<{
  code?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GuidedCareTemplatePublishMutation = { __typename?: 'Mutation', publishDraftHealthProgramTemplate?: { __typename?: 'HealthProgramTemplateCRUD', healthProgramTemplateErrors?: Array<{ __typename?: 'H_EntityError', code: Types.H_EntityErrorCode, field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTemplatePublishDocument = gql`
    mutation GuidedCareTemplatePublish($code: String) {
  publishDraftHealthProgramTemplate(code: $code) {
    healthProgramTemplateErrors {
      code
      field
      message
    }
  }
}
    `;
export type GuidedCareTemplatePublishMutationFn = Apollo.MutationFunction<GuidedCareTemplatePublishMutation, GuidedCareTemplatePublishMutationVariables>;

/**
 * __useGuidedCareTemplatePublishMutation__
 *
 * To run a mutation, you first call `useGuidedCareTemplatePublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplatePublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTemplatePublishMutation, { data, loading, error }] = useGuidedCareTemplatePublishMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGuidedCareTemplatePublishMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTemplatePublishMutation, GuidedCareTemplatePublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTemplatePublishMutation, GuidedCareTemplatePublishMutationVariables>(GuidedCareTemplatePublishDocument, options);
      }
export type GuidedCareTemplatePublishMutationHookResult = ReturnType<typeof useGuidedCareTemplatePublishMutation>;
export type GuidedCareTemplatePublishMutationResult = Apollo.MutationResult<GuidedCareTemplatePublishMutation>;
export type GuidedCareTemplatePublishMutationOptions = Apollo.BaseMutationOptions<GuidedCareTemplatePublishMutation, GuidedCareTemplatePublishMutationVariables>;