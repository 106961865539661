import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import React, { FC } from "react";
import { CustomTable, Grid } from "@toolkit/ui";
import { useTemplateGoalRecommendationInterventionsActivitiesFieldsColumns } from "./useTemplateGoalRecommendationInterventionsActivitiesFieldsColumns";

type GuidedCareTemplateGoalRecommendationInterventionsActivitiesFieldsProps = {
  interventionUniqueId: string;
};

export const GuidedCareTemplateGoalRecommendationInterventionsActivitiesFields: FC<
  GuidedCareTemplateGoalRecommendationInterventionsActivitiesFieldsProps
> = props => {
  const { interventionUniqueId } = props;

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const activities = form.getValues("interventions")?.find(item => item?.uniqueId === interventionUniqueId)?.activities || [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTable
          data={activities}
          columns={useTemplateGoalRecommendationInterventionsActivitiesFieldsColumns()}
          scrollable
          hasFooter={false}
        />
      </Grid>
    </Grid>
  );
};
