import { CodeSystemCode, HealthConditionDiagnosis } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { getSystemCodeAutocompleteFilter } from "@health/autocompletes";

export const useHealthConditionDiagnosisListContainerColumns = (): CustomTableColumnProps<HealthConditionDiagnosis>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "code",
        accessor: "diagnosisCode",
        header: t("Code"),
        filter: {
          ...getSystemCodeAutocompleteFilter({
            name: "diagnosisCode",
            queryVariables: { codeSystemCode: CodeSystemCode.Diagnosis },
          }),
        },
      },
      {
        key: "display",
        accessor: "diagnosisDisplay",
        header: t("Display"),
      },
    ];
  }, [t]);
};
