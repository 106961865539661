import { z } from "zod";
import { zodSchema } from "@health/domains";

export const GuidedCareTemplateMemberUpsertFormSchema = z.object({
  teamMemberId: z.string().optional(),
  uniqueId: z.string().optional(),
  position: zodSchema.guidedCareTeamMemberPositionAutocompleteEnum,
  specialization: zodSchema.systemCodeAutocompleteOptional,
  isLicencedHealthProfessional: z.boolean(),
  isBackupPersonMandatory: z.boolean(),
  isKeyTeamMember: z.boolean(),
});

export type IGuidedCareTemplateMemberUpsertFormValues = z.infer<typeof GuidedCareTemplateMemberUpsertFormSchema>;

export const guidedCareTemplateMemberUpsertFormDefaultValues: Partial<IGuidedCareTemplateMemberUpsertFormValues> = {
  teamMemberId: undefined,
  uniqueId: undefined,
  position: undefined,
  specialization: undefined,
  isLicencedHealthProfessional: false,
  isBackupPersonMandatory: false,
  isKeyTeamMember: false,
};
