export enum HealthConditionDiagnoseCreationMethod {
  Diagnose = "DIAGNOSE",
  Disease = "DISEASE",
}

export type IHealthConditionUpdateContainerParams = {
  healthConditionId: string;
};

export type IHealthConditionCloneContainerParams = {
  healthConditionId: string;
};

export type IHealthConditionDiagnosisListContainerParams = {
  healthConditionId: string;
};

export type IHealthConditionParameterListContainerParams = {
  healthConditionId: string;
};
