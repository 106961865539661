import { Box, FormCard, Grid, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { LabTemplate } from "@health/queries/types";

type LabTemplateInformationProps = {
  labTemplate: LabTemplate;
  isLoading?: boolean;
};

export const LabTemplateInformation: FC<LabTemplateInformationProps> = props => {
  const { labTemplate, isLoading } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Box marginBottom={2}>
      <FormCard title={t("Lab Template")} doYouHaveData={!!labTemplate} loading={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Display")}: {labTemplate?.display || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Arabic Display")}: {labTemplate?.arabicDisplay || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Code")}: {labTemplate?.labCodeDisplay || "-"}
            </Typography>
          </Grid>
        </Grid>
      </FormCard>
    </Box>
  );
};
