import { SublistItemUpdateContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { subListsBreadcrumbs } from "pages/Sublists/constants";
import { FC, useEffect } from "react";

export const GuidedCareSublistItemUpdateContainer: FC = () => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    const { itemTitle, main, update, updateItem } = subListsBreadcrumbs(t);

    setBreadCrumb({ title: itemTitle, values: [main, update, updateItem] });
  }, [setBreadCrumb, t]);

  return <SublistItemUpdateContainer />;
};
