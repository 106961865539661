import { getAutocompleteFilter } from "@toolkit/ui";
import { useHealthConditionsAutocompleteQuery } from "./gql";
export const getHealthConditionsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "getHealthConditions",
        query: useHealthConditionsAutocompleteQuery,
        labelBy: "display",
        backendAccessor: "id",
        filterSearchKey: "display",
    });
};
