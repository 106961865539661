import { z } from "zod";
import { zodSchema } from "@health/domains";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { HealthConditionDiagnoseCreationMethod } from "../../types";
import { i18n } from "@toolkit/i18n";

export const healthConditionDiagnoseCreateFormSchema = z
  .object({
    creationMethod: createZodAutocompleteObject(HealthConditionDiagnoseCreationMethod),
    diagnose: zodSchema.systemCodeAutocompleteOptional,
    disease: z.string().nullable().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.creationMethod?.value === HealthConditionDiagnoseCreationMethod.Diagnose && !data.diagnose) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["diagnose"],
      });
    } else if (data.creationMethod?.value === HealthConditionDiagnoseCreationMethod.Disease && !data.disease) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["disease"],
      });
    }
  });

export type IHealthConditionDiagnoseCreateFormValues = z.infer<typeof healthConditionDiagnoseCreateFormSchema>;

export const healthConditionDiagnoseCreateFormDefaultValues: Partial<IHealthConditionDiagnoseCreateFormValues> = {
  creationMethod: undefined,
  diagnose: undefined,
  disease: undefined,
};
