import { guidedCareActivityTypeOptionsMap, guidedCareTeamMemberPositionOptionsMap } from "@health/enum-options";
import { useCustomForm, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, FormActions, FormCard, Grid, PageWrapper, Typography } from "@toolkit/ui";
import { convertProgramActivityItemsToFormValues } from "pages/Programs/others";
import {
  IGuidedCareProgramActivity,
  IGuidedCareProgramActivityItemUpdateFormEvent,
  IGuidedCareProgramActivityItemsFormEvent,
  IGuidedCareProgramActivityItemsItem,
} from "pages/Programs/types";
import { FC, useEffect, useState } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GuidedCareProgramActivityItemUpdateModal } from "../../modals/ProgramActivityItemUpdate/GuidedCareProgramActivityItemUpdateModal";
import {
  GuidedCareProgramActivityItemsFormSchema,
  IGuidedCareProgramActivityItemsFormValues,
  guidedCareProgramActivityItemsFormDefaultValues,
} from "../ProgramActivityItems/GuidedCareProgramActivityItemsFormSchema";
import { useGuidedCareProgramActivityItemsColumns } from "./useGuidedCareProgramActivityItemsColumns";
import { useGuidedCareProgramActivityItemsFormStyle } from "./useGuidedCareProgramActivityItemsFormStyle";

type GuidedCareProgramActivityItemsFormProps = {
  isLoading?: boolean;
  activity?: IGuidedCareProgramActivity;
  onChange: (event: IGuidedCareProgramActivityItemsFormEvent) => void;
};

export const GuidedCareProgramActivityItemsForm: FC<GuidedCareProgramActivityItemsFormProps> = props => {
  const { isLoading, activity, onChange } = props;

  const [selectedActivityItem, setSelectedActivityItem] = useState<IGuidedCareProgramActivityItemsItem>();

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useOpenState();

  const { classes } = useGuidedCareProgramActivityItemsFormStyle();

  const form = useCustomForm<IGuidedCareProgramActivityItemsFormValues>({
    defaultValues: guidedCareProgramActivityItemsFormDefaultValues,
    schema: GuidedCareProgramActivityItemsFormSchema,
  });

  const { setValue, control, handleSubmit } = form;

  const { fields, update } = useFieldArray<IGuidedCareProgramActivityItemsFormValues, "activityItems">({
    control,
    name: "activityItems",
  });

  const onSubmit = (values: IGuidedCareProgramActivityItemsFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onActivityItemUpdateModalChange = (event: IGuidedCareProgramActivityItemUpdateFormEvent) => {
    if (event.type === "SUBMIT" && selectedActivityItem) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedActivityItem.id);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, event.payload.values);
      }
      handleClose();
    }
  };

  const onEditRowClick = (item: IGuidedCareProgramActivityItemsItem) => {
    setSelectedActivityItem(item);
    handleOpen();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (activity?.guidedCareProgramActivityItems) {
      const activityItems = convertProgramActivityItemsToFormValues(activity?.guidedCareProgramActivityItems);
      setValue("activityItems", activityItems);
    }
  }, [activity?.guidedCareProgramActivityItems, setValue]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions hasCancel hasFormButton formButtonTitle={t("Save")} onNavigation={handleCancel} newButtonDisabled={isLoading} />
          }
        >
          <FormCard title={t("Activity Items")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              {open && (
                <GuidedCareProgramActivityItemUpdateModal
                  isOpen={open}
                  onClose={handleClose}
                  activityItem={selectedActivityItem}
                  onChange={onActivityItemUpdateModalChange}
                />
              )}

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>
                  {t("Team Member")}: {t(guidedCareTeamMemberPositionOptionsMap[activity?.guidedCareProgramTeamMember?.position!]?.label!)}
                </Typography>

                <Typography className={classes.subtitle}>
                  {t("Activity Type")}: {t(guidedCareActivityTypeOptionsMap[activity?.templateActivityType!]?.label)}
                </Typography>

                {activity?.templateActivity?.medicalMessage && (
                  <Typography className={classes.subtitle}>
                    {t("Medical Message")}:
                    {`${activity?.templateActivity?.medicalMessage?.code} - ${activity?.templateActivity?.medicalMessage?.name}`}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <CustomTable
                  data={fields}
                  columns={useGuidedCareProgramActivityItemsColumns()}
                  isEditVisible
                  isRowEditable
                  onEditRow={onEditRowClick}
                  hasFooter={false}
                  scrollable
                />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
