/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminMedicalFormUpdateMutationVariables = Types.Exact<{
  adminUpdateMedicalFormId: Types.Scalars['ID'];
  input: Types.AdminMedicalFormInput;
}>;


export type AdminMedicalFormUpdateMutation = { __typename?: 'Mutation', adminUpdateMedicalForm?: { __typename?: 'MedicalForm', errors?: Array<{ __typename?: 'MedicalFormGraphqlError', errorType?: Types.ErrorType | null, message?: string | null, field?: string | null } | null> | null } | null };


export const AdminMedicalFormUpdateDocument = gql`
    mutation AdminMedicalFormUpdate($adminUpdateMedicalFormId: ID!, $input: AdminMedicalFormInput!) {
  adminUpdateMedicalForm(id: $adminUpdateMedicalFormId, input: $input) {
    errors {
      errorType
      message
      field
    }
  }
}
    `;
export type AdminMedicalFormUpdateMutationFn = Apollo.MutationFunction<AdminMedicalFormUpdateMutation, AdminMedicalFormUpdateMutationVariables>;

/**
 * __useAdminMedicalFormUpdateMutation__
 *
 * To run a mutation, you first call `useAdminMedicalFormUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMedicalFormUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMedicalFormUpdateMutation, { data, loading, error }] = useAdminMedicalFormUpdateMutation({
 *   variables: {
 *      adminUpdateMedicalFormId: // value for 'adminUpdateMedicalFormId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminMedicalFormUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AdminMedicalFormUpdateMutation, AdminMedicalFormUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminMedicalFormUpdateMutation, AdminMedicalFormUpdateMutationVariables>(AdminMedicalFormUpdateDocument, options);
      }
export type AdminMedicalFormUpdateMutationHookResult = ReturnType<typeof useAdminMedicalFormUpdateMutation>;
export type AdminMedicalFormUpdateMutationResult = Apollo.MutationResult<AdminMedicalFormUpdateMutation>;
export type AdminMedicalFormUpdateMutationOptions = Apollo.BaseMutationOptions<AdminMedicalFormUpdateMutation, AdminMedicalFormUpdateMutationVariables>;