import { Box, EmptyDataIcon, Typography } from "@toolkit/ui";
import React from "react";
import { useTranslation } from "@toolkit/i18n";
import { useEmptyDataContentStyle } from "./useEmptyDataContentStyle";

export const EmptyDataContent = () => {
  const { t } = useTranslation("gcadmin");

  const { classes } = useEmptyDataContentStyle();

  return (
    <Box className={classes.root}>
      <Box className={classes.absoluteWrapper}>
        <EmptyDataIcon height={100} />

        <Typography className={classes.noItemsMessage}>{t("No Items Added")}</Typography>

        <Typography className={classes.startAddingDataMessage}>{t("Start by adding data now")}</Typography>
      </Box>
    </Box>
  );
};
