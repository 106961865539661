import { FilterIcon, IconButton, styled } from "@toolkit/ui";
import React, { FC } from "react";
import {
  GuidedCareTemplateActivitiesItemsFilterModal,
  GuidedCareTemplateActivitiesItemsFilterModalApi,
} from "../../modals/TemplateActivitiesItemsFilter/GuidedCareTemplateActivitiesItemsFilterModal";
import { IGuidedCareTemplateActivitiesItemsFilterFormValues } from "../../forms/TemplateActivitiesItemsFilter/GuidedCareTemplateActivitiesItemsFilterFormSchema";

type GuidedCareTemplateActivitiesItemsFilterProps = {
  values?: IGuidedCareTemplateActivitiesItemsFilterFormValues;
  onChange: (values: IGuidedCareTemplateActivitiesItemsFilterFormValues) => void;
};

export const GuidedCareTemplateActivitiesItemsFilter: FC<GuidedCareTemplateActivitiesItemsFilterProps> = props => {
  const { values, onChange } = props;

  const onFilterIconClick = () => {
    GuidedCareTemplateActivitiesItemsFilterModalApi.open({ values });
  };

  return (
    <>
      <GuidedCareTemplateActivitiesItemsFilterModal onChange={onChange} />

      <StyledIconButton color={"primary"} size={"medium"} onClick={onFilterIconClick} disableFocusRipple>
        <FilterIcon />
      </StyledIconButton>
    </>
  );
};

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transition: theme.transitions.create(["background-color", "color"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  },
  margin: "10px 5px",
}));
