import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import {
  GuidedCareTemplateCustomAccordion,
  IGuidedCareTemplateCustomAccordionEvent,
} from "../../components/TemplateCustomAccordion/GuidedCareTemplateCustomAccordion";
import {
  GuidedCareTemplateGoalRecommendationsFields,
  IGuidedCareTemplateGoalRecommendationsFieldsEvent,
} from "../TemplateGoalRecommendationsFields/GuidedCareTemplateGoalRecommendationsFields";
import React, { FC } from "react";
import { EmptyDataContent } from "../../components/EmptyDataContent/EmptyDataContent";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";

export type IGuidedCareTemplateGoalsFieldsEvent =
  | IGuidedCareTemplateGoalRecommendationsFieldsEvent
  | {
      type: "GOAL_UPDATE" | "GOAL_DELETE" | "RECOMMENDATION_ADD";
      payload: {
        goalIndex: number;
      };
    };

type GuidedCareTemplateGoalsFieldsProps = {
  goals: IGuidedCareTemplateGoalUpsertFormValues[];
  onChange: (event: IGuidedCareTemplateGoalsFieldsEvent) => void;
};

export const GuidedCareTemplateGoalsFields: FC<GuidedCareTemplateGoalsFieldsProps> = props => {
  const { goals, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const onGuidedCareTemplateCustomAccordionChange = (event: IGuidedCareTemplateCustomAccordionEvent, goalIndex: number) => {
    if (event.type === "ADD_CLICK") {
      onChange({
        type: "RECOMMENDATION_ADD",
        payload: {
          goalIndex,
        },
      });
    } else if (event.type === "UPDATE_CLICK") {
      onChange({
        type: "GOAL_UPDATE",
        payload: {
          goalIndex,
        },
      });
    } else if (event.type === "DELETE_CLICK") {
      onChange({
        type: "GOAL_DELETE",
        payload: {
          goalIndex,
        },
      });
    }
  };

  const onGuidedCareTemplateGoalRecommendationsChange = (event: IGuidedCareTemplateGoalRecommendationsFieldsEvent) => {
    const { goalIndex, recommendationIndex } = event.payload;

    if (event.type === "INTERVENTION_UPDATE") {
      onChange({
        type: "INTERVENTION_UPDATE",
        payload: {
          goalIndex,
          recommendationIndex,
        },
      });
    } else if (event.type === "RECOMMENDATION_UPDATE") {
      onChange({
        type: "RECOMMENDATION_UPDATE",
        payload: {
          goalIndex,
          recommendationIndex,
        },
      });
    } else if (event.type === "RECOMMENDATION_DELETE") {
      onChange({
        type: "RECOMMENDATION_DELETE",
        payload: {
          goalIndex,
          recommendationIndex,
        },
      });
    }
  };

  return (
    <>
      {goals?.length ? (
        goals?.map((goal, goalIndex) => {
          return (
            <GuidedCareTemplateCustomAccordion
              key={goal?.uniqueId}
              title={pickLocalizedValue(goal?.name, goal?.nameAr!)}
              description={pickLocalizedValue(goal?.description, goal?.descriptionAr!)}
              tooltipMessage={t("Add New Recommendation")}
              onChange={event => onGuidedCareTemplateCustomAccordionChange(event, goalIndex)}
            >
              <GuidedCareTemplateGoalRecommendationsFields
                goalIndex={goalIndex}
                recommendations={goal?.recommendations}
                onChange={onGuidedCareTemplateGoalRecommendationsChange}
              />
            </GuidedCareTemplateCustomAccordion>
          );
        })
      ) : (
        <EmptyDataContent />
      )}
    </>
  );
};
