import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareActivityItemUpdateFormPropsEvent } from "pages/Templates/types";
import { FC, MouseEvent, useRef } from "react";
import {
  GuidedCareTemplateActivityItemUpdateForm,
  GuidedCareTemplateActivityItemUpdateFormRef,
} from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateForm";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

type GuidedCareTemplateActivityItemUpdateModalProps = {
  activityItem?: IGuidedCareTemplateActivityItemUpdateFormValues;
  onChange: (values: IGuidedCareActivityItemUpdateFormPropsEvent) => void;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareTemplateActivityItemUpdateModal: FC<GuidedCareTemplateActivityItemUpdateModalProps> = props => {
  const { activityItem, onChange, isOpen, onClose } = props;

  const { t } = useTranslation("gcadmin");
  const activityItemUpdateFormRef = useRef<GuidedCareTemplateActivityItemUpdateFormRef>(null);

  const onUpdateClick = () => {
    activityItemUpdateFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Update Activity Item"),
        onClose,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Update"),
        onSubmit: onUpdateClick,
      }}
    >
      <GuidedCareTemplateActivityItemUpdateForm activityItem={activityItem} ref={activityItemUpdateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
