import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateInterventionUpsertForm,
  GuidedCareTemplateInterventionUpsertFormRef,
  IGuidedCareTemplateInterventionUpsertFormEvent,
} from "../../forms/TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertForm";
import { IGuidedCareTemplateInterventionUpsertFormValues } from "../../forms/TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";

type IGuidedCareTemplateInterventionUpsertModalData = {
  intervention: IGuidedCareTemplateInterventionUpsertFormValues;
};

type IOpen = (data?: IGuidedCareTemplateInterventionUpsertModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateInterventionUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateInterventionUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateInterventionUpsertFormEvent) => void;
};

export const GuidedCareTemplateInterventionUpsertModal: FC<GuidedCareTemplateInterventionUpsertModalProps> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareTemplateInterventionUpsertModalData>();

  const { t } = useTranslation("gcadmin");

  const templateInterventionUpsertFormRef = useRef<GuidedCareTemplateInterventionUpsertFormRef>(null);

  const onButtonClick = () => {
    templateInterventionUpsertFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareTemplateInterventionUpsertModalApi.open = open;
    GuidedCareTemplateInterventionUpsertModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: data?.intervention ? t("Update Intervention") : t("Add New Intervention"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: !!data?.intervention,
        submitTitle: data?.intervention ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateInterventionUpsertForm
        intervention={data?.intervention}
        ref={templateInterventionUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
