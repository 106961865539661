/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateCloneMutationVariables = Types.Exact<{
  duplicateRiskFactorTemplateId: Types.Scalars['ID'];
  input: Types.RiskFactorTemplateInput;
}>;


export type RiskFactorTemplateCloneMutation = { __typename?: 'Mutation', duplicateRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const RiskFactorTemplateCloneDocument = gql`
    mutation RiskFactorTemplateClone($duplicateRiskFactorTemplateId: ID!, $input: RiskFactorTemplateInput!) {
  duplicateRiskFactorTemplate(id: $duplicateRiskFactorTemplateId, input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type RiskFactorTemplateCloneMutationFn = Apollo.MutationFunction<RiskFactorTemplateCloneMutation, RiskFactorTemplateCloneMutationVariables>;

/**
 * __useRiskFactorTemplateCloneMutation__
 *
 * To run a mutation, you first call `useRiskFactorTemplateCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskFactorTemplateCloneMutation, { data, loading, error }] = useRiskFactorTemplateCloneMutation({
 *   variables: {
 *      duplicateRiskFactorTemplateId: // value for 'duplicateRiskFactorTemplateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRiskFactorTemplateCloneMutation(baseOptions?: Apollo.MutationHookOptions<RiskFactorTemplateCloneMutation, RiskFactorTemplateCloneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RiskFactorTemplateCloneMutation, RiskFactorTemplateCloneMutationVariables>(RiskFactorTemplateCloneDocument, options);
      }
export type RiskFactorTemplateCloneMutationHookResult = ReturnType<typeof useRiskFactorTemplateCloneMutation>;
export type RiskFactorTemplateCloneMutationResult = Apollo.MutationResult<RiskFactorTemplateCloneMutation>;
export type RiskFactorTemplateCloneMutationOptions = Apollo.BaseMutationOptions<RiskFactorTemplateCloneMutation, RiskFactorTemplateCloneMutationVariables>;