import { FC } from "react";
import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { Box, Divider, FormAutocomplete, Grid, mapToAutocompleteOptions, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useGuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormStyle } from "./useGuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormStyle";
import { IGuidedCareTemplateInterventionUpsertFormValues } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";

type GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormProps = {
  interventions: IGuidedCareTemplateInterventionUpsertFormValues[];
};

export const GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertForm: FC<
  GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormProps
> = props => {
  const { interventions } = props;

  const { t } = useTranslation("gcadmin");

  const { classes } = useGuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertFormStyle();

  const form = useCustomFormContext<IGuidedCareTemplateActivityUpsertFormValues>();

  const { watch } = form;

  const medicalFormClassificationsInterventions = watch("medicalFormClassificationsInterventions");

  const interventionsOptions = mapToAutocompleteOptions(interventions, "uniqueId", item => pickLocalizedValue(item?.name, item?.nameAr!));

  if (!medicalFormClassificationsInterventions || !medicalFormClassificationsInterventions?.length) {
    return <></>;
  }

  return (
    <Box>
      <Typography className={classes.title}>{t("Conditions")}</Typography>

      <Divider className={classes.divider} />

      <Grid container spacing={2} className={classes.root}>
        {medicalFormClassificationsInterventions?.map((item, index) => {
          return (
            <>
              <Grid key={item?.id} item xs={6}>
                <Typography className={classes.classificationName}>{item?.classification}</Typography>
              </Grid>

              <Grid item xs={6}>
                <FormAutocomplete
                  name={`medicalFormClassificationsInterventions.${index}.intervention`}
                  label={t("Intervention")}
                  options={interventionsOptions}
                />
              </Grid>

              <Divider variant={"middle"} className={classes.divider} />
            </>
          );
        })}
      </Grid>
    </Box>
  );
};
