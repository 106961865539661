import React, { FC } from "react";
import { Box, CustomIcon, Typography, paths, useTheme } from "@toolkit/ui";

type GuidedCareTemplateActivitiesItemsTimelineItemContentProps = {
  icon: keyof typeof paths | string;
  title?: string;
  isBold?: boolean;
  viewBox?: string;
  color?: string;
};

export const GuidedCareTemplateActivitiesItemsTimelineItemContent: FC<
  GuidedCareTemplateActivitiesItemsTimelineItemContentProps
> = props => {
  const { icon, color, title, isBold, viewBox } = props;
  const theme = useTheme();
  return (
    <Box display='flex' alignItems='center'>
      <Box
        sx={{
          margin: "5px",
          width: "20px",
          height: "20px",
          background: color ? color : theme.palette.primary.main,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomIcon icon={icon as keyof typeof paths} color={theme.palette.common.white} viewBox={viewBox ? viewBox : "-10 -9 45 45"} />
      </Box>

      <Typography
        fontWeight={isBold ? theme.mixins.fonts.fontWeight.medium : theme.mixins.fonts.fontWeight.regular}
        whiteSpace='wrap'
        sx={{ color: color ? color : theme.palette.primary.main, maxWidth: 170 }}
        fontSize={theme.mixins.fonts.fontSize.xs}
      >
        {title}
      </Typography>
    </Box>
  );
};
