/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateActivityItemsUpdateMutationVariables = Types.Exact<{
  activityId: Types.Scalars['ID'];
  activityItems?: Types.InputMaybe<Array<Types.InputMaybe<Types.TemplateActivityEventItemInput>> | Types.InputMaybe<Types.TemplateActivityEventItemInput>>;
}>;


export type GuidedCareTemplateActivityItemsUpdateMutation = { __typename?: 'Mutation', updateTemplateActivityItems?: { __typename?: 'TemplateActivityCRUD', templateActivityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTemplateActivityItemsUpdateDocument = gql`
    mutation GuidedCareTemplateActivityItemsUpdate($activityId: ID!, $activityItems: [TemplateActivityEventItemInput]) {
  updateTemplateActivityItems(
    activityId: $activityId
    activityItems: $activityItems
  ) {
    templateActivityErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTemplateActivityItemsUpdateMutationFn = Apollo.MutationFunction<GuidedCareTemplateActivityItemsUpdateMutation, GuidedCareTemplateActivityItemsUpdateMutationVariables>;

/**
 * __useGuidedCareTemplateActivityItemsUpdateMutation__
 *
 * To run a mutation, you first call `useGuidedCareTemplateActivityItemsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateActivityItemsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTemplateActivityItemsUpdateMutation, { data, loading, error }] = useGuidedCareTemplateActivityItemsUpdateMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      activityItems: // value for 'activityItems'
 *   },
 * });
 */
export function useGuidedCareTemplateActivityItemsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTemplateActivityItemsUpdateMutation, GuidedCareTemplateActivityItemsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTemplateActivityItemsUpdateMutation, GuidedCareTemplateActivityItemsUpdateMutationVariables>(GuidedCareTemplateActivityItemsUpdateDocument, options);
      }
export type GuidedCareTemplateActivityItemsUpdateMutationHookResult = ReturnType<typeof useGuidedCareTemplateActivityItemsUpdateMutation>;
export type GuidedCareTemplateActivityItemsUpdateMutationResult = Apollo.MutationResult<GuidedCareTemplateActivityItemsUpdateMutation>;
export type GuidedCareTemplateActivityItemsUpdateMutationOptions = Apollo.BaseMutationOptions<GuidedCareTemplateActivityItemsUpdateMutation, GuidedCareTemplateActivityItemsUpdateMutationVariables>;