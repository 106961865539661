import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { TFunction } from "@toolkit/i18n";
import { labTemplatesRoute, labTemplatesPaths } from "./LabTemplatesPaths";
import {
  LabTemplateListContainer,
  LabTemplateCreateContainer,
  LabTemplateUpdateContainer,
  LabTemplateCloneContainer,
  LabTemplateParameterListContainer,
} from "../containers";

export const labTemplatesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "lab-templates-routes",
    text: t("Lab Templates", { ns: "gcadmin" }),
    route: labTemplatesRoute,
    subItems: [
      {
        id: "lab-templates-list-route",
        route: labTemplatesPaths.list.route,
        fullPath: labTemplatesPaths.list.fullPath,
        element: <LabTemplateListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-create-route",
        route: labTemplatesPaths.create.route,
        fullPath: labTemplatesPaths.create.fullPath,
        hidden: true,
        element: <LabTemplateCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-update-route",
        route: labTemplatesPaths.update.route,
        fullPath: labTemplatesPaths.update.fullPath,
        hidden: true,
        element: <LabTemplateUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-clone-route",
        route: labTemplatesPaths.clone.route,
        fullPath: labTemplatesPaths.clone.fullPath,
        hidden: true,
        element: <LabTemplateCloneContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "lab-templates-parameter-list-route",
        route: labTemplatesPaths.parameterList.route,
        fullPath: labTemplatesPaths.parameterList.fullPath,
        hidden: true,
        element: <LabTemplateParameterListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
