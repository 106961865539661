import { z } from "zod";
import { zodSchema } from "@health/domains";

export const labTemplateParameterCreateFormSchema = z.object({
  healthParameter: zodSchema.healthParameterAutocomplete,
});

export type ILabTemplateParameterCreateFormValues = z.infer<typeof labTemplateParameterCreateFormSchema>;

export const labTemplateParameterCreateFormDefaultValues: Partial<ILabTemplateParameterCreateFormValues> = {
  healthParameter: undefined,
};
