import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { TFunction } from "@toolkit/i18n";
import { riskFactorTemplatesRoutes } from "../../RiskFactorTemplates/constants";
import { HealthTemplatesRouteIcon } from "../components/HealthTemplatesRouteIcon/HealthTemplatesRouteIcon";
import { labTemplatesRoutes } from "../../LabTemplates/constants";

export const healthTemplatesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "health-templates-routes",
    text: t("Health Parameters Set", { ns: "gcadmin" }),
    icon: <HealthTemplatesRouteIcon />,
    subItems: [labTemplatesRoutes({ navigate, t }), riskFactorTemplatesRoutes({ navigate, t })],
  };
};
