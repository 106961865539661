import { useCallback, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateInterventionActivitiesField } from "pages/Templates/types";
import { GuidedCareTemplateInterventionActivityServicesModalApi } from "../../modals/TemplateInterventionActivityServices/GuidedCareTemplateInterventionActivityServicesModal";
import { GuidedCareActivityType } from "@health/queries/types";
import { useNavigate } from "react-router-dom";
import { guidedCareTemplatesPaths } from "../../constants";

type GuidedCareTemplateInterventionActivitiesColumnsProps = {
  interventionIndex: number;
};

export const useGuidedCareTemplateInterventionActivitiesColumns = (
  props: GuidedCareTemplateInterventionActivitiesColumnsProps
): CustomTableColumnProps<IGuidedCareTemplateInterventionActivitiesField>[] => {
  const { interventionIndex } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onManageActivityServicesClick = useCallback(
    (activityIndex: number) => {
      GuidedCareTemplateInterventionActivityServicesModalApi.open({
        interventionIndex,
        activityIndex,
      });
    },
    [interventionIndex]
  );

  const onManageActivityItemsClick = useCallback(
    (activityId: string) => {
      navigate(guidedCareTemplatesPaths.interventionActivityItemList.fullPathWithParams({ activityId }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "type",
        header: t("Type"),
        accessor: ({ templateActivityType }) => templateActivityType?.label,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ medicalMessage }) => medicalMessage?.value?.name || "-",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: ({ medicalMessage }) => medicalMessage?.value?.code || "-",
      },
      {
        key: "position",
        header: t("Position"),
        accessor: ({ teamMember }) => teamMember?.label,
      },
      {
        key: "numberOfOccurrences",
        header: t("Occurrences"),
        accessor: ({ numberOfOccurrences, frequencyType }) => `${numberOfOccurrences} / ${frequencyType?.label}`,
      },
      {
        key: "services",
        header: t("Services"),
        accessor: ({ templateActivityType }, index) => (
          <Button
            variant={"contained"}
            onClick={() => onManageActivityServicesClick(index)}
            disabled={
              templateActivityType?.value !== GuidedCareActivityType.Lab && templateActivityType?.value !== GuidedCareActivityType.Rad
            }
          >
            {t("Manage")}
          </Button>
        ),
      },
      {
        key: "items",
        header: t("Items"),
        accessor: ({ activityId }) => (
          <Button onClick={() => onManageActivityItemsClick(activityId!)} disabled={!activityId}>
            {t("Manage")}
          </Button>
        ),
      },
    ];
  }, [onManageActivityItemsClick, onManageActivityServicesClick, t]);
};
