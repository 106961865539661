import { GridProvider } from "@health/domains";
import { useNavigate, useParams } from "react-router-dom";
import {
  HealthConditionDiagnosisListDocument,
  useHealthConditionDiagnoseCreateMutation,
  useHealthConditionDiagnoseDeleteMutation,
  useHealthConditionDiagnoseDiseaseCreateMutation,
  useHealthConditionDiagnosisListQuery,
  useHealthConditionGetQuery,
} from "../../gql";
import { useHealthConditionDiagnosisListContainerColumns } from "./useHealthConditionDiagnosisListContainerColumns";
import { HealthConditionDiagnoseCreationMethod, IHealthConditionDiagnosisListContainerParams } from "../../types";
import { FilterGrid, PageWrapper, PlusIcon, StyledButton, TableGrid, useAddToast } from "@toolkit/ui";
import {
  HealthConditionDiagnoseCreateModal,
  HealthConditionDiagnoseCreateModalApi,
} from "../../modals/HealthConditionDiagnoseCreate/HealthConditionDiagnoseCreateModal";
import { IHealthConditionDiagnoseCreateFormEvent } from "../../forms/HealthConditionDiagnoseCreate/HealthConditionDiagnoseCreateForm";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import React from "react";
import { HealthConditionInformation } from "../../components/HealthConditionInformation/HealthConditionInformation";
import { HealthCondition } from "@health/queries/types";
import { useSetHealthConditionsBreadcrumbs } from "../../hooks";
import { healthConditionsPaths } from "../../constants";

export const HealthConditionDiagnosisListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { healthConditionId } = useParams<IHealthConditionDiagnosisListContainerParams>();

  const { data, loading } = useHealthConditionGetQuery({
    variables: {
      id: healthConditionId!,
    },
    skip: !healthConditionId,
  });

  const healthCondition = data?.getHealthCondition as HealthCondition;

  const [createHealthConditionDiagnose, { loading: isHealthConditionDiagnoseCreateLoading }] = useHealthConditionDiagnoseCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addDiagnosisToHealthCondition?.[0]?.errors?.length) {
        succeeded(t("Health Condition Diagnose added successfully"));
        HealthConditionDiagnoseCreateModalApi.close();
      } else {
        failed(t("Health Condition Diagnose add failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: HealthConditionDiagnosisListDocument,
        variables: { first: 10, filter: { healthConditionId } },
      },
    ],
  });

  const [createHealthConditionDiagnoseDisease, { loading: isHealthConditionDiagnoseDiseaseCreateLoading }] =
    useHealthConditionDiagnoseDiseaseCreateMutation({
      onCompleted: mutationData => {
        if (mutationData?.addDiagnosisToHealthConditionByDisease?.errors?.length) {
          succeeded(t("Health Condition Diagnose added successfully"));
          HealthConditionDiagnoseCreateModalApi.close();
        } else {
          failed(t("Health Condition Diagnose add failed"));
        }
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
      refetchQueries: [
        {
          query: HealthConditionDiagnosisListDocument,
          variables: { first: 10, filter: { healthConditionId } },
        },
      ],
    });

  const onAddNewItemClick = () => {
    HealthConditionDiagnoseCreateModalApi.open();
  };

  const onCancelClick = () => {
    navigate(healthConditionsPaths.list.fullPath);
  };

  const onHealthConditionDiagnoseCreateModalChange = (event: IHealthConditionDiagnoseCreateFormEvent) => {
    if (event.type === "CREATE") {
      if (event.payload.values?.creationMethod?.value === HealthConditionDiagnoseCreationMethod.Diagnose) {
        createHealthConditionDiagnose({
          variables: {
            diagnosisCode: event.payload.values?.diagnose?.value?.code,
            healthConditionId: healthConditionId!,
          },
        });
      } else if (event.payload.values?.creationMethod?.value === HealthConditionDiagnoseCreationMethod.Disease) {
        createHealthConditionDiagnoseDisease({
          variables: {
            disease: event.payload.values?.disease!,
            healthConditionId: healthConditionId!,
          },
        });
      }
    }
  };

  useSetHealthConditionsBreadcrumbs("DIAGNOSIS_LIST", pickLocalizedValue(healthCondition?.display!, healthCondition?.arabicDisplay!));

  return (
    <>
      <HealthConditionDiagnoseCreateModal
        isLoading={isHealthConditionDiagnoseCreateLoading || isHealthConditionDiagnoseDiseaseCreateLoading}
        onChange={onHealthConditionDiagnoseCreateModalChange}
      />

      <GridProvider
        gridName={"healthConditionDiagnosisList"}
        query={useHealthConditionDiagnosisListQuery}
        columns={useHealthConditionDiagnosisListContainerColumns()}
        filterInput={{ healthConditionId }}
        tableAction={{
          isEditVisible: false,
          isDeleteVisible: true,
        }}
        deleteItemProps={{
          name: t("Health Condition Diagnose"),
          entityTypeName: "HealthConditionDiagnosis",
          useDeleteMutation: useHealthConditionDiagnoseDeleteMutation,
        }}
        skipCall={!healthConditionId}
      >
        <PageWrapper
          filters={<FilterGrid />}
          actions={
            <>
              <StyledButton color={"success"} startIcon={<PlusIcon />} onClick={onAddNewItemClick}>
                {t("Add New")}
              </StyledButton>

              <StyledButton variant={"outlined"} onClick={onCancelClick}>
                {t("Cancel")}
              </StyledButton>
            </>
          }
        >
          <HealthConditionInformation healthCondition={healthCondition} isLoading={loading} />

          <TableGrid />
        </PageWrapper>
      </GridProvider>
    </>
  );
};
