import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import {
  HealthConditionParameterCreateForm,
  HealthConditionParameterCreateFormRef,
  IHealthConditionParameterCreateFormEvent,
} from "../../forms/HealthConditionParameterCreate/HealthConditionParameterCreateForm";

type IOpen = () => void;
type IClose = () => void;

export const HealthConditionParameterCreateModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type HealthConditionParameterCreateModalProps = {
  isLoading?: boolean;
  onChange: (event: IHealthConditionParameterCreateFormEvent) => void;
};

export const HealthConditionParameterCreateModal: FC<HealthConditionParameterCreateModalProps> = props => {
  const { isLoading, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);

  const healthConditionParameterCreateFormRef = useRef<HealthConditionParameterCreateFormRef>(null);

  const { t } = useTranslation("gcadmin");

  const onButtonClick = () => {
    healthConditionParameterCreateFormRef.current?.submit();
  };

  const open: IOpen = () => {
    setIsOpen(true);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    HealthConditionParameterCreateModalApi.open = open;
    HealthConditionParameterCreateModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Add New Parameter"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Add"),
        disableSubmit: isLoading,
        onSubmit: onButtonClick,
      }}
    >
      <HealthConditionParameterCreateForm ref={healthConditionParameterCreateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
