import { createPathDefinition } from "@toolkit/core";

export const riskFactorTemplatesRoute = `/risk-factor-templates`;

export const riskFactorTemplatesPaths = createPathDefinition(riskFactorTemplatesRoute, {
  list: "",
  create: "new",
  update: ":riskFactorTemplateId",
  clone: ":riskFactorTemplateId/clone",
  parameterList: ":riskFactorTemplateId/parameters",
});
