import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import { Button } from "@toolkit/ui";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";

type GuidedCareTemplateActivityItemsManageProps = {
  activityId?: string;
};

export const GuidedCareTemplateActivityItemsManage: FC<GuidedCareTemplateActivityItemsManageProps> = props => {
  const { activityId } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate(guidedCareTemplatesPaths.activityItemList.fullPathWithParams({ activityId: activityId! }));
  };

  return (
    <Button onClick={onButtonClick} disabled={!activityId}>
      {t("Manage")}
    </Button>
  );
};
