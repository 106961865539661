import { FC, useState } from "react";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { useMedicalMessagePublishMutation } from "../../gql";

type MedicalMessagePublishProps = {
  id: string;
  isPublished: boolean;
};

export const MedicalMessagePublish: FC<MedicalMessagePublishProps> = props => {
  const { id, isPublished } = props;

  const [active, setActive] = useState(isPublished);

  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();

  const [medicalMessagePublish, { loading: isSubmitting }] = useMedicalMessagePublishMutation({
    onCompleted: data => {
      const errors = data?.updateMedicalMessagePublishStatus?.medicalMessageErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Medical message has been updated successfully"));
        setActive(!!data?.updateMedicalMessagePublishStatus?.medicalMessage?.isPublished);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    medicalMessagePublish({ variables: { id } });
  };

  return <CustomToggleButton isLoading={isSubmitting} disabled={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
