import { useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { AgentsList } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { agentsPaths } from "../constants";
import { agentsBreadcrumb } from "../constants/AgentsBreadcrumbs";

export const AdminAgentsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  useEffect(() => {
    setBreadCrumb({
      title: agentsBreadcrumb().title,
    });
  }, []);

  const handleEditClick = (agentId: string) => {
    navigate(agentsPaths.update.fullPathWithParams({ agentId: agentId }));
  };
  return <AgentsList isAdmin handleEditClick={handleEditClick} />;
};
