import { useCustomForm } from "@toolkit/core";
import {
  labTemplateParameterCreateFormDefaultValues,
  labTemplateParameterCreateFormSchema,
  ILabTemplateParameterCreateFormValues,
} from "./LabTemplateParameterCreateFormSchema";
import { Grid } from "@toolkit/ui";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { HealthParametersAutocomplete } from "@health/autocompletes";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";

export type ILabTemplateParameterCreateFormEvent = {
  type: "CREATE";
  payload: {
    values: ILabTemplateParameterCreateFormValues;
  };
};

type LabTemplateParameterCreateFormProps = {
  onChange: (event: ILabTemplateParameterCreateFormEvent) => void;
};

export type LabTemplateParameterCreateFormRef = {
  getForm: () => UseFormReturn<ILabTemplateParameterCreateFormValues>;
  submit: () => void;
};

const LabTemplateParameterCreateFormForwardRef: ForwardRefRenderFunction<
  LabTemplateParameterCreateFormRef,
  LabTemplateParameterCreateFormProps
> = (props, ref) => {
  const { onChange } = props;

  const form = useCustomForm<ILabTemplateParameterCreateFormValues>({
    defaultValues: labTemplateParameterCreateFormDefaultValues,
    schema: labTemplateParameterCreateFormSchema,
  });

  const { handleSubmit } = form;

  const onSubmit = (values: ILabTemplateParameterCreateFormValues) => {
    onChange({
      type: "CREATE",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HealthParametersAutocomplete name={"healthParameter"} filter={{ isActive: true }} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const LabTemplateParameterCreateForm = forwardRef(LabTemplateParameterCreateFormForwardRef);
