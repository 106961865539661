import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components/Root/routeTypes";
import { ArticleCategoryCreateContainer, ArticleCategoryListContainer, ArticleCategoryUpdateContainer } from "../containers";
import { articlesCategoriesPaths, articlesCategoriesRoute } from "./ArticleCategoriesPaths";

export const articlesCategoriesRoutes: (props: { navigate: NavigateFunction }) => RouteItem = ({ navigate }) => {
  return {
    id: "articles-categories-routes",
    text: i18n.t("Articles Categories", { ns: "gcadmin" }),
    route: articlesCategoriesRoute,
    subItems: [
      {
        id: "article-category-list-route",
        route: articlesCategoriesPaths.list.route,
        fullPath: articlesCategoriesPaths.list.fullPath,
        element: <ArticleCategoryListContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "article-category-create-route",
        route: articlesCategoriesPaths.create.route,
        fullPath: articlesCategoriesPaths.create.fullPath,
        hidden: true,
        element: <ArticleCategoryCreateContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "article-category-update-route",
        route: articlesCategoriesPaths.update.route,
        fullPath: articlesCategoriesPaths.update.fullPath,
        hidden: true,
        element: <ArticleCategoryUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
