import React, { FC } from "react";
import { useFieldArray } from "react-hook-form";
import { useCustomFormContext } from "@toolkit/core";
import { CustomTable, Grid } from "@toolkit/ui";
import { IGuidedCareActivityServiceListItem, IGuidedCareTemplateActivityServiceUpsertFormEvent } from "pages/Templates/types";
import { GuidedCareTemplateActivityServiceUpsertForm } from "../TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertForm";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateInterventionActivityServicesColumns } from "./useGuidedCareTemplateInterventionActivityServicesColumns";

type GuidedCareTemplateInterventionActivityServicesFormProps = {
  interventionIndex: number;
  activityIndex: number;
};

export const GuidedCareTemplateInterventionActivityServices: FC<GuidedCareTemplateInterventionActivityServicesFormProps> = props => {
  const { interventionIndex, activityIndex } = props;

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { control } = form;

  const { fields, append, remove } = useFieldArray<
    IGuidedCareTemplateUpsertFormValues,
    `interventions.${number}.activities.${number}.activityServices`
  >({
    control,
    name: `interventions.${interventionIndex}.activities.${activityIndex}.activityServices`,
  });

  const onActivityServiceUpsertFormChange = (event: IGuidedCareTemplateActivityServiceUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
    }
  };

  const onDeleteRowClick = (item: IGuidedCareActivityServiceListItem, index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GuidedCareTemplateActivityServiceUpsertForm onChange={onActivityServiceUpsertFormChange} />
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareTemplateInterventionActivityServicesColumns()}
          isDeleteVisible
          isRowDeletable
          hasFooter={false}
          scrollable
          onDeleteRow={onDeleteRowClick}
        />
      </Grid>
    </Grid>
  );
};
