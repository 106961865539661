import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues } from "../../forms/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertFormSchema";
import {
  GuidedCareTemplateGoalRecommendationInterventionUpsertForm,
  GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef,
} from "../../forms/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertForm";
import { IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent } from "../../types";

type IGuidedCareTemplateGoalRecommendationInterventionUpsertModalData = {
  intervention: IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues;
};

type IOpen = (data: IGuidedCareTemplateGoalRecommendationInterventionUpsertModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateGoalRecommendationInterventionUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalRecommendationInterventionFormUpsertFormEvent) => void;
};

export const GuidedCareTemplateGoalRecommendationInterventionUpsertModal: FC<
  GuidedCareTemplateGoalRecommendationInterventionUpsertModalProps
> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareTemplateGoalRecommendationInterventionUpsertModalData>();

  const { t } = useTranslation("gcadmin");

  const templateGoalRecommendationInterventionUpsertFormRef = useRef<GuidedCareTemplateGoalRecommendationInterventionUpsertFormRef>(null);

  const onButtonClick = () => {
    templateGoalRecommendationInterventionUpsertFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.open = open;
    GuidedCareTemplateGoalRecommendationInterventionUpsertModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Update Interventions"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Update"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateGoalRecommendationInterventionUpsertForm
        intervention={data?.intervention!}
        ref={templateGoalRecommendationInterventionUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
