/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabTemplateParameterListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type LabTemplateParameterListQuery = { __typename?: 'Query', getLabTemplate?: { __typename?: 'LabTemplate', id?: string | null, labCodeDisplay?: string | null, display?: string | null, arabicDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', id?: string | null, standardCode?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, referenceRange?: string | null, createdDate?: string | null, defaultValue?: string | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, allowedValues?: Array<string | null> | null, category?: { __typename?: 'FieldCategory', display?: string | null, arabicDisplay?: string | null } | null, unit?: { __typename?: 'FieldUnit', display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null };


export const LabTemplateParameterListDocument = gql`
    query LabTemplateParameterList($id: ID!) {
  getLabTemplate(id: $id) {
    id
    labCodeDisplay
    display
    arabicDisplay
    fields {
      id
      standardCode
      code
      display
      arabicDisplay
      category {
        display
        arabicDisplay
      }
      type
      chartType
      unit {
        display
        arabicDisplay
      }
      referenceRange
      createdDate
      defaultValue
      minNormalRangeValue
      maxNormalRangeValue
      allowedValues
    }
  }
}
    `;

/**
 * __useLabTemplateParameterListQuery__
 *
 * To run a query within a React component, call `useLabTemplateParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabTemplateParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabTemplateParameterListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabTemplateParameterListQuery(baseOptions: Apollo.QueryHookOptions<LabTemplateParameterListQuery, LabTemplateParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabTemplateParameterListQuery, LabTemplateParameterListQueryVariables>(LabTemplateParameterListDocument, options);
      }
export function useLabTemplateParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabTemplateParameterListQuery, LabTemplateParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabTemplateParameterListQuery, LabTemplateParameterListQueryVariables>(LabTemplateParameterListDocument, options);
        }
export type LabTemplateParameterListQueryHookResult = ReturnType<typeof useLabTemplateParameterListQuery>;
export type LabTemplateParameterListLazyQueryHookResult = ReturnType<typeof useLabTemplateParameterListLazyQuery>;
export type LabTemplateParameterListQueryResult = Apollo.QueryResult<LabTemplateParameterListQuery, LabTemplateParameterListQueryVariables>;