import { DecisionPlanCategory, PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { BusinessRulesIcon } from "@toolkit/ui";
import { subListsRoutes } from "pages/Sublists/constants";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { GuidedCareDecisionCreateContainer, GuidedCareDecisionListContainer, GuidedCareDecisionUpdateContainer } from "../containers";
import { interventionRoutes, riskStratificationRoutes, taskFlowRoutes } from "./DecisionsPaths";

export const decisionsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "decisions-routes",
    text: t("System Rules", { ns: "gcadmin" }),
    icon: <BusinessRulesIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageRuleEngineRules),
    subItems: [
      {
        id: "risk-stratification-rules-routes",
        text: t("Risk Stratification Rules", { ns: "gcadmin" }),
        route: riskStratificationRoutes.route,
        subItems: [
          {
            id: "risk-stratification-rules-main-route",
            text: "",
            route: riskStratificationRoutes.paths.main.route,
            fullPath: riskStratificationRoutes.paths.main.fullPath,
            element: <GuidedCareDecisionListContainer category={DecisionPlanCategory.RiskStratification} />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "risk-stratification-rules-new-route",
            text: "",
            route: riskStratificationRoutes.paths.new.route,
            fullPath: riskStratificationRoutes.paths.new.fullPath,
            hidden: true,
            element: <GuidedCareDecisionCreateContainer category={DecisionPlanCategory.RiskStratification} />,
          },
          {
            id: "risk-stratification-rules-edit-route",
            text: "",
            route: riskStratificationRoutes.paths.edit.route,
            fullPath: riskStratificationRoutes.paths.edit.fullPath,
            hidden: true,
            element: <GuidedCareDecisionUpdateContainer category={DecisionPlanCategory.RiskStratification} />,
          },
        ],
      },
      {
        id: "task-flow-routes",
        text: t("Task Flow Rules", { ns: "gcadmin" }),
        route: taskFlowRoutes.route,
        subItems: [
          {
            id: "task-flow-list-route",
            text: "",
            route: taskFlowRoutes.paths.main.route,
            fullPath: taskFlowRoutes.paths.main.fullPath,
            element: <GuidedCareDecisionListContainer category={DecisionPlanCategory.TaskFlow} />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "task-flow-new-route",
            text: "",
            route: taskFlowRoutes.paths.new.route,
            fullPath: taskFlowRoutes.paths.new.fullPath,
            hidden: true,
            element: <GuidedCareDecisionCreateContainer category={DecisionPlanCategory.TaskFlow} />,
          },
          {
            id: "task-flow-edit-route",
            text: "",
            route: taskFlowRoutes.paths.edit.route,
            fullPath: taskFlowRoutes.paths.edit.fullPath,
            hidden: true,
            element: <GuidedCareDecisionUpdateContainer category={DecisionPlanCategory.TaskFlow} />,
          },
        ],
      },
      {
        id: "intervention-routes",
        text: t("Intervention", { ns: "gcadmin" }),
        route: interventionRoutes.route,
        subItems: [
          {
            id: "intervention-list-route",
            text: "",
            route: interventionRoutes.paths.main.route,
            fullPath: interventionRoutes.paths.main.fullPath,
            element: <GuidedCareDecisionListContainer category={DecisionPlanCategory.Intervention} />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "intervention-new-route",
            text: "",
            route: interventionRoutes.paths.new.route,
            fullPath: interventionRoutes.paths.new.fullPath,
            hidden: true,
            element: <GuidedCareDecisionCreateContainer category={DecisionPlanCategory.Intervention} />,
          },
          {
            id: "intervention-edit-route",
            text: "",
            route: interventionRoutes.paths.edit.route,
            fullPath: interventionRoutes.paths.edit.fullPath,
            hidden: true,
            element: <GuidedCareDecisionUpdateContainer category={DecisionPlanCategory.Intervention} />,
          },
        ],
      },
      subListsRoutes({ navigate, t }),
    ],
  };
};
