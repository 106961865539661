import { useCustomFormContext } from "@toolkit/core";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { useFieldArray } from "react-hook-form";
import React, { FC } from "react";
import { Box, Button, CustomTable, Grid, PlusIcon } from "@toolkit/ui";
import { IGuidedCareTemplateInterventionActivityItem } from "../../types";
import {
  GuidedCareTemplateInterventionActivityUpsertModal,
  GuidedCareTemplateInterventionActivityUpsertModalApi,
} from "../../modals/TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertModal";
import { useGuidedCareTemplateInterventionActivitiesColumns } from "./useGuidedCareTemplateInterventionActivitiesColumns";
import { IGuidedCareTemplateInterventionActivityUpsertFormEvent } from "../TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertForm";
import { useTranslation } from "@toolkit/i18n";
import { GuidedCareTemplateInterventionActivityServicesModal } from "../../modals/TemplateInterventionActivityServices/GuidedCareTemplateInterventionActivityServicesModal";

type GuidedCareTemplateInterventionActivitiesProps = {
  interventionIndex: number;
};

export const GuidedCareTemplateInterventionActivities: FC<GuidedCareTemplateInterventionActivitiesProps> = props => {
  const { interventionIndex } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { fields, prepend, update, remove } = useFieldArray<IGuidedCareTemplateUpsertFormValues, `interventions.${number}.activities`>({
    control: form.control,
    name: `interventions.${interventionIndex}.activities`,
  });

  const onAddClick = () => {
    GuidedCareTemplateInterventionActivityUpsertModalApi.open({
      interventionIndex,
    });
  };

  const onEditRowClick = (item: IGuidedCareTemplateInterventionActivityItem) => {
    GuidedCareTemplateInterventionActivityUpsertModalApi.open({
      interventionIndex,
      activity: item,
    });
  };

  const onDeleteRowClick = (item: IGuidedCareTemplateInterventionActivityItem, index: number) => {
    remove(index);
  };

  const onGuidedCareTemplateInterventionActivityUpsertModalChange = (event: IGuidedCareTemplateInterventionActivityUpsertFormEvent) => {
    if (event.type === "CREATE") {
      prepend(event.payload.values);
    } else if (event.type === "UPDATE") {
      const index = fields.findIndex(item => item.activityId === event.payload.values.activityId);
      update(index, event.payload.values);
    }

    GuidedCareTemplateInterventionActivityUpsertModalApi.close();
  };

  return (
    <Grid container spacing={2}>
      <GuidedCareTemplateInterventionActivityUpsertModal onChange={onGuidedCareTemplateInterventionActivityUpsertModalChange} />
      <GuidedCareTemplateInterventionActivityServicesModal />

      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button startIcon={<PlusIcon />} onClick={onAddClick}>
            {t("Add Activity")}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareTemplateInterventionActivitiesColumns({ interventionIndex })}
          isEditVisible
          isDeleteVisible
          scrollable
          hasFooter={false}
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
        />
      </Grid>
    </Grid>
  );
};
