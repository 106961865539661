import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { GuidedCareTemplateGoalRecommendationInterventionsActivitiesFields } from "../../forms/TemplateGoalRecommendationInterventionsActivitiesFields/GuidedCareTemplateGoalRecommendationInterventionsActivitiesFields";

type IGuidedCareTemplateGoalRecommendationInterventionActivitiesModalData = {
  interventionUniqueId: string;
};

type IOpen = (data: IGuidedCareTemplateGoalRecommendationInterventionActivitiesModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const GuidedCareTemplateGoalRecommendationInterventionActivitiesModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareTemplateGoalRecommendationInterventionActivitiesModalData>();

  const { t } = useTranslation("gcadmin");

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi.open = open;
    GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      maxWidth={"lg"}
      DialogTitleProps={{
        title: t("Intervention Activities"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <GuidedCareTemplateGoalRecommendationInterventionsActivitiesFields interventionUniqueId={data?.interventionUniqueId!} />
    </CustomDialog>
  );
};
