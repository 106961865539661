import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

export const useGuidedCareTemplateActivityItemsColumns = (): CustomTableColumnProps<IGuidedCareTemplateActivityItemUpdateFormValues>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "offsetInDays",
        header: t("Offset (Days)"),
        accessor: "offsetInDays",
      },
      {
        key: "beforeActivity",
        header: t("Before Activity"),
        accessor: ({ beforeActivity }) => (beforeActivity ? `${beforeActivity?.value?.code} - ${beforeActivity?.value?.name}` : ""),
      },
      {
        key: "beforeActivityOffset",
        header: t("Before Activity Offset (Days)"),
        accessor: "beforeActivityOffset",
      },
      {
        key: "afterActivity",
        header: t("After Activity"),
        accessor: ({ afterActivity }) => (afterActivity ? `${afterActivity?.value?.code} - ${afterActivity?.value?.name}` : ""),
      },
      {
        key: "afterActivityOffset",
        header: t("After Activity Offset (Days)"),
        accessor: "afterActivityOffset",
      },
    ];
  }, [t]);
};
