import { createPathDefinition } from "@toolkit/core";

export const medicalMessagesRoute = `/medical-messages`;

export const medicalMessagesPaths = createPathDefinition(medicalMessagesRoute, {
  main: "",
  create: "new",
  update: ":medicalMessageId",
  variants: ":medicalMessageId/variants",
  variantCreate: ":medicalMessageId/variants/new",
  variantUpdate: ":medicalMessageId/variants/:medicalMessageVariantId",
});
