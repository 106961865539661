import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { convertLabTemplateFormValuesToBackEndValues } from "../../others/utils";
import { ILabTemplateUpsertFormEvent, LabTemplateUpsertForm } from "../../forms/LabTemplateUpsert/LabTemplateUpsertForm";
import { useLabTemplateCreateMutation } from "../../gql";
import { labTemplatesPaths } from "../../constants";
import { useSetLabTemplatesBreadcrumbs } from "../../hooks";

export const LabTemplateCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createLabTemplate, { loading: isSubmitting }] = useLabTemplateCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addLabTemplate?.errors?.length) {
        succeeded(t("Lab Template created successfully"));
        navigate(labTemplatesPaths.list.fullPath);
      } else {
        failed(t("Lab Template create failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onLabTemplateUpsertFormChange = (event: ILabTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertLabTemplateFormValuesToBackEndValues(event.payload.values);

      createLabTemplate({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetLabTemplatesBreadcrumbs("CREATE");

  return <LabTemplateUpsertForm submitButtonLabel={t("Create")} isButtonDisabled={isSubmitting} onChange={onLabTemplateUpsertFormChange} />;
};
