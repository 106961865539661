import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Checkbox, CustomTableColumnProps, Typography, useTheme } from "@toolkit/ui";
import { TeamMemberPosition } from "@health/queries/types";
import { IGuidedCareTemplateMemberItem } from "pages/Templates/types";

export const useGuidedCareTemplateMembersColumns = (): CustomTableColumnProps<IGuidedCareTemplateMemberItem>[] => {
  const { t } = useTranslation("gcadmin");
  const theme = useTheme();
  return useMemo(() => {
    return [
      {
        key: "position",
        header: t("Position"),
        accessor: ({ position }) => t(position?.label!),
      },
      {
        key: "specialization",
        header: t("Specialization"),
        accessor: ({ position, specialization }) =>
          t(specialization?.value?.display!) || (
            <>
              {position?.value === TeamMemberPosition.TeamLeader ? (
                <Typography color={theme.palette.error.main}> {t("Required")} </Typography>
              ) : (
                <Typography> - </Typography>
              )}
            </>
          ),
      },
      {
        key: "isLicencedHealthProfessional",
        header: t("Licensed Health Professional"),
        accessor: ({ isLicencedHealthProfessional }) => {
          return isLicencedHealthProfessional ? <Checkbox defaultChecked={isLicencedHealthProfessional} disabled /> : undefined;
        },
      },
      {
        key: "isBackupPersonMandatory",
        header: t("Backup Person Mandatory"),
        accessor: ({ isBackupPersonMandatory }) => {
          return isBackupPersonMandatory ? <Checkbox defaultChecked={isBackupPersonMandatory} disabled /> : undefined;
        },
      },
      {
        key: "isKeyTeamMember",
        header: t("Key Team Member"),
        accessor: ({ isKeyTeamMember }) => {
          return isKeyTeamMember ? <Checkbox defaultChecked={isKeyTeamMember} disabled /> : undefined;
        },
      },
    ];
  }, [t]);
};
