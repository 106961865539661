import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { GuidedCareTemplateInterventionActivities } from "../../forms/TemplateInterventionActivities/GuidedCareTemplateInterventionActivities";

type IGuidedCareTemplateInterventionActivitiesModalData = {
  interventionIndex: number;
};

type IOpen = (data: IGuidedCareTemplateInterventionActivitiesModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateInterventionActivitiesModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const GuidedCareTemplateInterventionActivitiesModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareTemplateInterventionActivitiesModalData>();

  const { t } = useTranslation("gcadmin");

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareTemplateInterventionActivitiesModalApi.open = open;
    GuidedCareTemplateInterventionActivitiesModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      maxWidth={"lg"}
      DialogTitleProps={{
        title: t("Intervention Activities"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <GuidedCareTemplateInterventionActivities interventionIndex={data?.interventionIndex!} />
    </CustomDialog>
  );
};
