import { RiskFactorTemplate, RiskFactorTemplateSortingField } from "@health/queries/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, ShowButton, TruncateTypography, formatDate } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { riskFactorTemplatesPaths } from "../../constants";
import { getYesNoAutocompleteFilter } from "@health/enum-options";
import { RiskFactorTemplateActivation } from "../../components/RiskFactorTemplateActivation/RiskFactorTemplateActivation";

export const useRiskFactorTemplateListContainerColumns = (): CustomTableColumnProps<RiskFactorTemplate>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onShowParametersClick = useCallback(
    (id: string) => {
      navigate(riskFactorTemplatesPaths.parameterList.fullPathWithParams({ riskFactorTemplateId: id }));
    },
    [navigate]
  );

  const onCloneClick = useCallback(
    (id: string) => {
      navigate(riskFactorTemplatesPaths.clone.fullPathWithParams({ riskFactorTemplateId: id }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code }) => <TruncateTypography text={code || "-"} />,
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: RiskFactorTemplateSortingField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("Display"),
        accessor: ({ display, arabicDisplay }) => <TruncateTypography text={pickLocalizedValue(display!, arabicDisplay!)} />,
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: pickLocalizedValue(RiskFactorTemplateSortingField.Display, RiskFactorTemplateSortingField.ArabicDisplay),
        filter: {
          type: "string",
          name: pickLocalizedValue("display", "arabicDisplay"),
        },
      },
      {
        key: "arabicDisplay",
        header: t("Arabic Display"),
        filter: {
          type: "string",
          name: "arabicDisplay",
        },
        showOnlyForFilterField: true,
        hideFromSettings: true,
      },
      {
        key: "parameters",
        header: t("Parameters"),
        accessor: ({ id }) => <ShowButton variant={"text"} onClick={() => onShowParametersClick(id!)} />,
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon='clone' />} variant={"text"} onClick={() => onCloneClick(id!)}>
            {t("Clone")}
          </Button>
        ),
        type: "string",
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: RiskFactorTemplateSortingField.CreatedDate,
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <RiskFactorTemplateActivation id={id!} isActive={!!isActive} />,
        filter: getYesNoAutocompleteFilter({ name: "isActive" }),
      },
    ];
  }, [onCloneClick, onShowParametersClick, t]);
};
