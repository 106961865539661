import { Maybe } from "@health/queries/types";
import { CustomDialog, Grid, ShowButton, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";

type ArticleTagsProps = {
  tags: Maybe<Maybe<string>[]>;
};

export const ArticleTags: FC<ArticleTagsProps> = props => {
  const { tags } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog type={"info"} button={<ShowButton />} title={t("Tags")}>
      {tags?.length ? (
        <Grid container spacing={2}>
          {tags?.map(item => (
            <Grid key={item} item xs={12}>
              {item}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>{t("There are no tags")}</Typography>
      )}
    </CustomDialog>
  );
};
