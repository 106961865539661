import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateActivitiesItemsFilterForm,
  GuidedCareTemplateActivitiesItemsFormRef,
} from "../../forms/TemplateActivitiesItemsFilter/GuidedCareTemplateActivitiesItemsFilterForm";
import { IGuidedCareTemplateActivitiesItemsFilterFormValues } from "../../forms/TemplateActivitiesItemsFilter/GuidedCareTemplateActivitiesItemsFilterFormSchema";

type IGuidedCareTemplateActivitiesItemsFilterModalData = {
  values?: IGuidedCareTemplateActivitiesItemsFilterFormValues;
};

type IOpen = (data: IGuidedCareTemplateActivitiesItemsFilterModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateActivitiesItemsFilterModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateActivitiesItemsFilterModalProps = {
  onChange: (values: IGuidedCareTemplateActivitiesItemsFilterFormValues) => void;
};

export const GuidedCareTemplateActivitiesItemsFilterModal: FC<GuidedCareTemplateActivitiesItemsFilterModalProps> = props => {
  const { onChange } = props;

  const [data, setData] = useState<IGuidedCareTemplateActivitiesItemsFilterModalData>();

  const { t } = useTranslation("gcadmin");
  const { open: isOpen, handleToggle } = useOpenState();

  const templateActivitiesItemsFormRef = useRef<GuidedCareTemplateActivitiesItemsFormRef>(null);

  const onSubmit = () => {
    templateActivitiesItemsFormRef.current?.submit();
    close();
  };

  const open: IOpen = _data => {
    handleToggle();
    setData(_data);
  };

  const close: IClose = () => {
    handleToggle();
  };

  useEffect(() => {
    GuidedCareTemplateActivitiesItemsFilterModalApi.open = open;
    GuidedCareTemplateActivitiesItemsFilterModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Filter"),
        onClose: close,
      }}
      DialogActionsProps={{
        submitTitle: t("Submit"),
        onSubmit,
      }}
    >
      <GuidedCareTemplateActivitiesItemsFilterForm values={data?.values} ref={templateActivitiesItemsFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
