import { i18n } from "@toolkit/i18n";
import { articlesCategoriesPaths } from "./ArticleCategoriesPaths";

export const articlesCategoriesBreadcrumb = () => ({
  title: i18n.t("Articles Categories", { ns: "gcadmin" }),
  main: {
    id: "articles-categories-main",
    name: i18n.t("Articles Categories", { ns: "gcadmin" }),
    path: articlesCategoriesPaths.list.fullPath,
  },
  create: {
    id: "articles-category-create",
    name: i18n.t("New", { ns: "gcadmin" }),
  },
  update: (fieldName: string) => ({
    id: "articles-category-update",
    name: fieldName || i18n.t("Update", { ns: "gcadmin" }),
  }),
});
