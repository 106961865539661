import { z } from "zod";
import { zodSchema } from "@health/domains";

export const healthConditionParameterCreateFormSchema = z.object({
  healthParameter: zodSchema.healthParameterAutocomplete,
});

export type IHealthConditionParameterCreateFormValues = z.infer<typeof healthConditionParameterCreateFormSchema>;

export const healthConditionParameterCreateFormDefaultValues: Partial<IHealthConditionParameterCreateFormValues> = {
  healthParameter: undefined,
};
