import React, { FC } from "react";
import { TemplateActivity, TemplateActivityEventItem } from "@health/queries/types";
import { GuidedCareTemplateActivitiesItemsDialog } from "../TemplateActivitiesItemsDialog/GuidedCareTemplateActivitiesItemsDialog";
import { GuidedCareTemplateActivitiesItemsTimelineItem } from "../TemplateActivitiesItemsTimelineItem/GuidedCareTemplateActivitiesItemsTimelineItem";
import { GuidedCareTemplateActivitiesItemsTimelineItemIcon } from "../TemplateActivitiesItemsTimelineItemIcon/GuidedCareTemplateActivitiesItemsTimelineItemIcon";

type GuidedCareTemplateActivitiesItemsTimelineQuarterlyProps = {
  activity?: TemplateActivityEventItem;
  hasMoreData?: boolean;
  minOffsetInDays?: number;
  maxOffsetInDays?: number;
  selectItem: TemplateActivity;
  onSelectItem: (item: TemplateActivity) => void;
};

export const GuidedCareTemplateActivitiesItemsTimelineQuarterly: FC<GuidedCareTemplateActivitiesItemsTimelineQuarterlyProps> = props => {
  const { activity, selectItem, minOffsetInDays, maxOffsetInDays, onSelectItem: handleSelectItem, hasMoreData } = props;

  return (
    <>
      <GuidedCareTemplateActivitiesItemsTimelineItem offsetInDays={activity?.offsetInDays!} item={activity?.templateActivity!}>
        <GuidedCareTemplateActivitiesItemsTimelineItemIcon
          item={activity?.templateActivity!}
          selectItem={selectItem!}
          onSelectItem={handleSelectItem}
        />
      </GuidedCareTemplateActivitiesItemsTimelineItem>

      {hasMoreData && <GuidedCareTemplateActivitiesItemsDialog minOffsetInDays={minOffsetInDays!} maxOffsetInDays={maxOffsetInDays!} />}
    </>
  );
};
