import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateActivitiesItemsTimelineItemIconStyle = makeStyles()({
  icon: {
    borderRadius: 10,
    margin: "5px 2px",
    padding: "4px",
    width: 38,
    height: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
