import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { useHealthConditionListQuery } from "../../gql";
import { useHealthConditionListContainerColumns } from "./useHealthConditionListContainerColumns";
import { useSetHealthConditionsBreadcrumbs } from "../../hooks";
import { healthConditionsPaths } from "../../constants";
import { HealthCondition } from "@health/queries/types";

export const HealthConditionListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(healthConditionsPaths.create.fullPath);
  };

  const onEditRowClick = (item: HealthCondition) => {
    navigate(healthConditionsPaths.update.fullPathWithParams({ healthConditionId: item?.id! }));
  };

  useSetHealthConditionsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"healthConditionList"}
      query={useHealthConditionListQuery}
      columns={useHealthConditionListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
