import { DecisionListContainer } from "@health/domains";
import { Decision, DecisionPlanCategory } from "@health/queries/types";
import { healthParametersPaths } from "pages/HealthParameters/constants";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type HealthParameterDecisionListProps = {
  healthParameterId: string;
};

export const HealthParameterDecisionList: FC<HealthParameterDecisionListProps> = props => {
  const { healthParameterId } = props;

  const navigate = useNavigate();

  const onAddNewClick = () => {
    navigate(healthParametersPaths.decisionCreate.fullPathWithParams({ healthParameterId }));
  };

  const onEditRowClick = (item: Decision) => {
    navigate(healthParametersPaths.decisionUpdate.fullPathWithParams({ healthParameterId, id: item?.id! }));
  };

  if (!healthParameterId) {
    return <></>;
  }

  return (
    <DecisionListContainer
      category={DecisionPlanCategory.HealthCondition}
      filter={{ healthParameterId }}
      onAddNewClick={onAddNewClick}
      onEditRowClick={onEditRowClick}
    />
  );
};
