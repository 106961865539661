import { FC, useState } from "react";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { GuidedCareProgramActivationMutation, useGuidedCareProgramActivationMutation } from "pages/Programs/gql";

type GuidedCareProgramActivationProps = {
  id: string;
  isActive: boolean;
};

export const GuidedCareProgramActivation: FC<GuidedCareProgramActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();

  const [fetchGuidedCareProgramActivationMutation, { loading: isSubmitting }] = useGuidedCareProgramActivationMutation({
    onCompleted: (data: GuidedCareProgramActivationMutation) => {
      const errors = data?.changeGuidedCareHealthProgramActiveStatus?.guidedCareHealthProgramErrors;

      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        setActive(!!data?.changeGuidedCareHealthProgramActiveStatus?.guidedCareHealthProgram?.isActive);
        succeeded(t("Program updated successfully"));
      }
    },
    onError: () => {
      failed(t("Program updated failed"));
    },
  });

  const handleChangeToggle = () => {
    fetchGuidedCareProgramActivationMutation({
      variables: { id },
    });
  };

  return <CustomToggleButton isLoading={isSubmitting} disabled={isSubmitting} checked={active} onChange={handleChangeToggle} />;
};
