/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GuidedCareProgramActivationMutation = { __typename?: 'Mutation', changeGuidedCareHealthProgramActiveStatus?: { __typename?: 'GuidedCareHealthProgramCRUD', guidedCareHealthProgram?: { __typename?: 'GuidedCareHealthProgram', isActive?: boolean | null } | null, guidedCareHealthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareProgramActivationDocument = gql`
    mutation GuidedCareProgramActivation($id: ID!) {
  changeGuidedCareHealthProgramActiveStatus(id: $id) {
    guidedCareHealthProgram {
      isActive
    }
    guidedCareHealthProgramErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareProgramActivationMutationFn = Apollo.MutationFunction<GuidedCareProgramActivationMutation, GuidedCareProgramActivationMutationVariables>;

/**
 * __useGuidedCareProgramActivationMutation__
 *
 * To run a mutation, you first call `useGuidedCareProgramActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareProgramActivationMutation, { data, loading, error }] = useGuidedCareProgramActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareProgramActivationMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareProgramActivationMutation, GuidedCareProgramActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareProgramActivationMutation, GuidedCareProgramActivationMutationVariables>(GuidedCareProgramActivationDocument, options);
      }
export type GuidedCareProgramActivationMutationHookResult = ReturnType<typeof useGuidedCareProgramActivationMutation>;
export type GuidedCareProgramActivationMutationResult = Apollo.MutationResult<GuidedCareProgramActivationMutation>;
export type GuidedCareProgramActivationMutationOptions = Apollo.BaseMutationOptions<GuidedCareProgramActivationMutation, GuidedCareProgramActivationMutationVariables>;