import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateGoalRecommendationUpsertForm,
  GuidedCareTemplateGoalRecommendationUpsertFormRef,
} from "../../forms/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertForm";
import { IGuidedCareTemplateGoalRecommendationUpsertFormValues } from "../../forms/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";

type IGuidedCareTemplateGoalRecommendationUpsertModalData = {
  recommendation?: IGuidedCareTemplateGoalRecommendationUpsertFormValues;
};

type IOpen = (data?: IGuidedCareTemplateGoalRecommendationUpsertModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateGoalRecommendationUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateGoalRecommendationUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalRecommendationFormUpsertFormEvent) => void;
};

export const GuidedCareTemplateGoalRecommendationUpsertModal: FC<GuidedCareTemplateGoalRecommendationUpsertModalProps> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareTemplateGoalRecommendationUpsertModalData>();

  const { t } = useTranslation("gcadmin");

  const templateGoalRecommendationUpsertFormRef = useRef<GuidedCareTemplateGoalRecommendationUpsertFormRef>(null);

  const onButtonClick = () => {
    templateGoalRecommendationUpsertFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationUpsertModalApi.open = open;
    GuidedCareTemplateGoalRecommendationUpsertModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: data?.recommendation ? t("Update Recommendation") : t("Add New Recommendation"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: !!data?.recommendation,
        submitTitle: data?.recommendation ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateGoalRecommendationUpsertForm
        recommendation={data?.recommendation}
        ref={templateGoalRecommendationUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
