import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useHealthParameterCreateMutation } from "../../gql";
import { formatGraphQLError } from "@toolkit/apollo";
import { convertHealthParameterFormValuesToBackEndValues } from "../../others/utils";
import { HealthParameterUpsertForm, IHealthParameterUpsertFormEvent } from "../../forms/HealthParameterUpsert/HealthParameterUpsertForm";
import { healthParametersPaths } from "../../constants";
import { useSetHealthParametersBreadcrumbs } from "../../hooks";

export const HealthParameterCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createHealthParameter, { loading: isSubmitting }] = useHealthParameterCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addHealthParameter?.errors?.length) {
        succeeded(t("Health Parameter created successfully"));
        navigate(healthParametersPaths.list.fullPath);
      } else {
        failed(t("Health Parameter create failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthParameterUpsertFormChange = (event: IHealthParameterUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthParameterFormValuesToBackEndValues(event.payload.values);

      createHealthParameter({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetHealthParametersBreadcrumbs("CREATE");

  return (
    <HealthParameterUpsertForm
      submitButtonLabel={t("Create")}
      isButtonDisabled={isSubmitting}
      onChange={onHealthParameterUpsertFormChange}
    />
  );
};
