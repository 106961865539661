import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateGoalFormUpsertFormEvent } from "pages/Templates/types";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateGoalUpsertForm,
  GuidedCareTemplateGoalUpsertFormRef,
} from "../../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertForm";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";

type IGuidedCareTemplateGoalUpsertModalData = {
  goal: IGuidedCareTemplateGoalUpsertFormValues;
};

type IOpen = (data?: IGuidedCareTemplateGoalUpsertModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateGoalUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateGoalUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalFormUpsertFormEvent) => void;
};

export const GuidedCareTemplateGoalUpsertModal: FC<GuidedCareTemplateGoalUpsertModalProps> = props => {
  const { onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IGuidedCareTemplateGoalUpsertModalData>();

  const { t } = useTranslation("gcadmin");

  const templateGoalUpsertFormRef = useRef<GuidedCareTemplateGoalUpsertFormRef>(null);

  const onButtonClick = () => {
    templateGoalUpsertFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    GuidedCareTemplateGoalUpsertModalApi.open = open;
    GuidedCareTemplateGoalUpsertModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: data?.goal ? t("Update Goal") : t("Add New Goal"),
        onClose: () => setIsOpen(false),
      }}
      DialogActionsProps={{
        hasClose: !!data?.goal,
        submitTitle: data?.goal ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateGoalUpsertForm goal={data?.goal} ref={templateGoalUpsertFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
