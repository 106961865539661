import { z } from "zod";
import { createZodAutocompleteObject } from "@toolkit/ui";
import { guidedCareTemplateInterventionUpsertFormSchema } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";

export const guidedCareTemplateGoalRecommendationUpsertFormSchema = z.object({
  recommendationId: z.string().nullable().optional(),
  uniqueId: z.string().nullable().optional(),
  name: z.string(),
  nameAr: z.string().nullable().optional(),
  code: z.string(),
  description: z.string(),
  descriptionAr: z.string().nullable().optional(),
  interventions: z.array(createZodAutocompleteObject(guidedCareTemplateInterventionUpsertFormSchema)),
});

export type IGuidedCareTemplateGoalRecommendationUpsertFormValues = z.infer<typeof guidedCareTemplateGoalRecommendationUpsertFormSchema>;

export const guidedCareTemplateGoalRecommendationUpsertFormDefaultValues: Partial<IGuidedCareTemplateGoalRecommendationUpsertFormValues> = {
  recommendationId: undefined,
  uniqueId: undefined,
  name: undefined,
  nameAr: undefined,
  code: undefined,
  description: undefined,
  descriptionAr: undefined,
  interventions: [],
};
