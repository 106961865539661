import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "@toolkit/i18n";
import { Button, CirclePlusIcon, CustomTable, FormTextField, Grid } from "@toolkit/ui";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { z } from "zod";

export const ArticleReferencesForm = () => {
  const { t } = useTranslation("gc-admin");
  const { getValues } = useFormContext();
  const { append, remove } = useFieldArray({ name: "references" });
  const methods = useForm<{ reference: string }>({
    context: null,
    resolver: zodResolver(
      z.object({
        reference: z.string().min(3),
      })
    ),
    defaultValues: {
      reference: "",
    },
  });

  const addReference = (data: { reference: string }) => {
    append(data.reference);
    methods.setValue("reference", "");
    methods.resetField("reference");
  };
  const handleRemoveItem = (_, index: number) => {
    remove(index);
  };
  return (
    <>
      <FormProvider {...methods}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={8}>
            <FormTextField label={t("Reference")} name='reference' />
          </Grid>
          <Grid item xs={2} alignContent={"center"}>
            <Button disabled={!methods.formState.isValid} onClick={methods.handleSubmit(addReference)}>
              <CirclePlusIcon fontSize='small' sx={{ marginInlineEnd: 1 }} /> {t("Add")}
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
      <Grid item xs={12}>
        <CustomTable
          data={getValues("references")}
          columns={[
            {
              key: "reference",
              header: t("Reference"),
              accessor: s => s as string,
            },
          ]}
          isDeleteVisible
          onDeleteRow={handleRemoveItem}
        />
      </Grid>
    </>
  );
};
