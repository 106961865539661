import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormNumberField, Grid } from "@toolkit/ui";
import { MedicalMessagesAutocomplete } from "@health/autocompletes";
import { MedicalMessageCategory } from "@health/queries/types";
import { IGuidedCareProgramActivityItemUpdateFormEvent } from "pages/Programs/types";
import {
  GuidedCareProgramActivityItemUpdateFormSchema,
  IGuidedCareProgramActivityItemUpdateFormValues,
  guidedCareProgramActivityItemUpdateFormDefaultValues,
} from "./GuidedCareProgramActivityItemUpdateFormSchema";

type GuidedCareActivityItemUpdateFormProps = {
  activityItem?: IGuidedCareProgramActivityItemUpdateFormValues;
  onChange: (event: IGuidedCareProgramActivityItemUpdateFormEvent) => void;
};

export type GuidedCareActivityItemUpdateFormRef = {
  getForm: () => UseFormReturn<IGuidedCareProgramActivityItemUpdateFormValues>;
  submit: () => void;
};

const GuidedCareActivityItemUpdateFormForwardRef: ForwardRefRenderFunction<
  GuidedCareActivityItemUpdateFormRef,
  GuidedCareActivityItemUpdateFormProps
> = (props, ref) => {
  const { activityItem, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareProgramActivityItemUpdateFormValues>({
    defaultValues: guidedCareProgramActivityItemUpdateFormDefaultValues,
    schema: GuidedCareProgramActivityItemUpdateFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareProgramActivityItemUpdateFormValues) => {
    if (activityItem) {
      onChange({
        type: "SUBMIT",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (activityItem) {
      setValues(activityItem);
    }
  }, [activityItem, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <FormNumberField name={"offsetInDays"} label={"Offset (Days)"} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"beforeActivity"}
                  label={t("Before Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"afterActivity"}
                  label={t("After Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"beforeActivityOffset"} label={t("Before Activity - Offset (Days)")} />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"afterActivityOffset"} label={t("After Activity - Offset (Days)")} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareProgramActivityItemUpdateForm = forwardRef(GuidedCareActivityItemUpdateFormForwardRef);
