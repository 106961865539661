import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useMedicalMessagesAutocompleteQuery } from "./gql";
export const createMedicalMessageAutocompleteOption = (medicalMessage) => {
    return medicalMessage ? createAutocompleteOption(medicalMessage, "id", item => `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`) : undefined;
};
export const getMedicalMessagesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "medicalMessages",
        query: useMedicalMessagesAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
