/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionParameterDeleteMutationVariables = Types.Exact<{
  healthConditionId: Types.Scalars['ID'];
  healthParameterId: Types.Scalars['ID'];
}>;


export type HealthConditionParameterDeleteMutation = { __typename?: 'Mutation', deleteFieldFromHealthCondition?: { __typename?: 'HealthCondition', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const HealthConditionParameterDeleteDocument = gql`
    mutation HealthConditionParameterDelete($healthConditionId: ID!, $healthParameterId: ID!) {
  deleteFieldFromHealthCondition(
    healthConditionId: $healthConditionId
    healthParameterId: $healthParameterId
  ) {
    errors {
      field
      message
    }
  }
}
    `;
export type HealthConditionParameterDeleteMutationFn = Apollo.MutationFunction<HealthConditionParameterDeleteMutation, HealthConditionParameterDeleteMutationVariables>;

/**
 * __useHealthConditionParameterDeleteMutation__
 *
 * To run a mutation, you first call `useHealthConditionParameterDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionParameterDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthConditionParameterDeleteMutation, { data, loading, error }] = useHealthConditionParameterDeleteMutation({
 *   variables: {
 *      healthConditionId: // value for 'healthConditionId'
 *      healthParameterId: // value for 'healthParameterId'
 *   },
 * });
 */
export function useHealthConditionParameterDeleteMutation(baseOptions?: Apollo.MutationHookOptions<HealthConditionParameterDeleteMutation, HealthConditionParameterDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthConditionParameterDeleteMutation, HealthConditionParameterDeleteMutationVariables>(HealthConditionParameterDeleteDocument, options);
      }
export type HealthConditionParameterDeleteMutationHookResult = ReturnType<typeof useHealthConditionParameterDeleteMutation>;
export type HealthConditionParameterDeleteMutationResult = Apollo.MutationResult<HealthConditionParameterDeleteMutation>;
export type HealthConditionParameterDeleteMutationOptions = Apollo.BaseMutationOptions<HealthConditionParameterDeleteMutation, HealthConditionParameterDeleteMutationVariables>;