import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { createMedicalMessagesBreadcrumbs, medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { IMedicalMessageUpsertFormEvent, MedicalMessageUpsertForm } from "pages/MedicalMessages/forms";
import { MedicalMessageCreateMutation, useMedicalMessageCreateMutation } from "pages/MedicalMessages/gql";
import { convertMedicalMessageFormValuesToBackEndValues } from "pages/MedicalMessages/others";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const MedicalMessageCreateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();

  const [fetchMedicalMessageCreateMutation, { loading: isSubmitting }] = useMedicalMessageCreateMutation({
    onCompleted: (mutationData: MedicalMessageCreateMutation) => {
      const mutationErrors = mutationData?.medicalMessageCreate?.medicalMessageErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Medical Message created successfully"));
        navigate(medicalMessagesPaths.main.fullPath);
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const onMedicalMessageUpsertFormChange = (event: IMedicalMessageUpsertFormEvent) => {
    const values = convertMedicalMessageFormValuesToBackEndValues(event.payload.values);

    fetchMedicalMessageCreateMutation({
      variables: {
        input: values,
      },
    });
  };

  useEffect(() => {
    const { title, main, create } = createMedicalMessagesBreadcrumbs(t);
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);

  return (
    <MedicalMessageUpsertForm submitButtonLabel={t("Update")} isButtonDisabled={isSubmitting} onChange={onMedicalMessageUpsertFormChange} />
  );
};
