import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateActivitiesItemsDialogStyle = makeStyles()(theme => ({
  showMoreButtonIcon: {
    borderRadius: 10,
    margin: "5px 2px",
    padding: "4px",
    width: 38,
    height: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    border: "1.5px solid",
    borderColor: theme.palette.primary.main,

    "&:hover": {
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
      "& svg": {
        fill: theme.palette.common.white,
      },
      "#id": {
        "& svg": {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  moreIcon: {
    "& svg": {
      fill: theme.palette.primary.main,
    },
  },
}));
