import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import {
  IRiskFactorTemplateParameterCreateFormEvent,
  RiskFactorTemplateParameterCreateForm,
  RiskFactorTemplateParameterCreateFormRef,
} from "../../forms/RiskFactorTemplateParameterCreate/RiskFactorTemplateParameterCreateForm";

type IOpen = () => void;
type IClose = () => void;

export const RiskFactorTemplateParameterCreateModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type RiskFactorTemplateParameterCreateModalProps = {
  isLoading?: boolean;
  onChange: (event: IRiskFactorTemplateParameterCreateFormEvent) => void;
};

export const RiskFactorTemplateParameterCreateModal: FC<RiskFactorTemplateParameterCreateModalProps> = props => {
  const { isLoading, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);

  const riskFactorTemplateParameterCreateFormRef = useRef<RiskFactorTemplateParameterCreateFormRef>(null);

  const { t } = useTranslation("gcadmin");

  const onButtonClick = () => {
    riskFactorTemplateParameterCreateFormRef.current?.submit();
  };

  const open: IOpen = () => {
    setIsOpen(true);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    RiskFactorTemplateParameterCreateModalApi.open = open;
    RiskFactorTemplateParameterCreateModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Add New Parameter"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Add"),
        disableSubmit: isLoading,
        onSubmit: onButtonClick,
      }}
    >
      <RiskFactorTemplateParameterCreateForm ref={riskFactorTemplateParameterCreateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
