import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { IGuidedCareTemplateMemberUpsertFormEvent } from "pages/Templates/types";
import { FC, MouseEvent, useRef } from "react";
import {
  GuidedCareTemplateMemberUpsertForm,
  GuidedCareTemplateMemberUpsertFormRef,
} from "../../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertForm";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";

type GuidedCareTemplateMemberUpsertModalProps = {
  member?: IGuidedCareTemplateMemberUpsertFormValues;
  onChange: (values: IGuidedCareTemplateMemberUpsertFormEvent) => void;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareTemplateMemberUpsertModal: FC<GuidedCareTemplateMemberUpsertModalProps> = props => {
  const { member, onChange, isOpen, onClose } = props;

  const { t } = useTranslation("gcadmin");
  const templateMemberUpsertFormRef = useRef<GuidedCareTemplateMemberUpsertFormRef>(null);

  const onButtonClick = () => {
    templateMemberUpsertFormRef.current?.submit();
  };

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: member ? t("Update Team Member") : t("Add Team Member"),
        onClose,
      }}
      DialogActionsProps={{
        hasClose: !!member,
        submitTitle: member ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateMemberUpsertForm member={member} ref={templateMemberUpsertFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
