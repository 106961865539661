import { z } from "zod";
import { GuidedCareTemplateActivityItemUpdateFormSchema } from "../TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

export const guidedCareTemplateInterventionActivityItemsFormSchema = z.object({
  activityItems: z.array(GuidedCareTemplateActivityItemUpdateFormSchema),
});

export type IGuidedCareTemplateInterventionActivityItemsFormValues = z.infer<typeof guidedCareTemplateInterventionActivityItemsFormSchema>;

export const guidedCareTemplateInterventionActivityItemsFormDefaultValues: Partial<IGuidedCareTemplateInterventionActivityItemsFormValues> =
  {
    activityItems: [],
  };
