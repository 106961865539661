import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import React, { FC } from "react";
import {
  GuidedCareTemplateCustomAccordion,
  IGuidedCareTemplateCustomAccordionEvent,
} from "../../components/TemplateCustomAccordion/GuidedCareTemplateCustomAccordion";
import { EmptyDataContent } from "../../components/EmptyDataContent/EmptyDataContent";
import { GuidedCareTemplateGoalRecommendationInterventionsFields } from "../TemplateGoalRecommendationInterventionsFields/GuidedCareTemplateGoalRecommendationInterventionsFields";
import { IGuidedCareTemplateGoalRecommendationUpsertFormValues } from "../TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";

export type IGuidedCareTemplateGoalRecommendationsFieldsEvent = {
  type: "INTERVENTION_UPDATE" | "RECOMMENDATION_UPDATE" | "RECOMMENDATION_DELETE";
  payload: {
    goalIndex: number;
    recommendationIndex: number;
  };
};

type GuidedCareTemplateGoalRecommendationsFieldsProps = {
  goalIndex: number;
  recommendations: IGuidedCareTemplateGoalRecommendationUpsertFormValues[];
  onChange: (event: IGuidedCareTemplateGoalRecommendationsFieldsEvent) => void;
};

export const GuidedCareTemplateGoalRecommendationsFields: FC<GuidedCareTemplateGoalRecommendationsFieldsProps> = props => {
  const { goalIndex, recommendations, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const onGuidedCareTemplateCustomAccordionChange = (event: IGuidedCareTemplateCustomAccordionEvent, recommendationIndex: number) => {
    if (event.type === "ADD_CLICK") {
      onChange({
        type: "INTERVENTION_UPDATE",
        payload: { goalIndex, recommendationIndex },
      });
    } else if (event.type === "UPDATE_CLICK") {
      onChange({
        type: "RECOMMENDATION_UPDATE",
        payload: { goalIndex, recommendationIndex },
      });
    } else if (event.type === "DELETE_CLICK") {
      onChange({
        type: "RECOMMENDATION_DELETE",
        payload: { goalIndex, recommendationIndex },
      });
    }
  };

  return (
    <Box>
      {recommendations?.length ? (
        recommendations.map((recommendation, recommendationIndex) => {
          return (
            <GuidedCareTemplateCustomAccordion
              key={recommendation?.uniqueId}
              title={pickLocalizedValue(recommendation?.name, recommendation?.nameAr!)}
              description={pickLocalizedValue(recommendation?.description, recommendation?.descriptionAr!)}
              tooltipMessage={t("Add/Delete Interventions")}
              onChange={event => onGuidedCareTemplateCustomAccordionChange(event, recommendationIndex)}
            >
              <GuidedCareTemplateGoalRecommendationInterventionsFields interventions={recommendation?.interventions} />
            </GuidedCareTemplateCustomAccordion>
          );
        })
      ) : (
        <EmptyDataContent />
      )}
    </Box>
  );
};
