import { i18n } from "@toolkit/i18n";
import { articlesPaths } from "./ArticlesPaths";

export const articlesBreadcrumb = () => ({
  title: i18n.t("Articles", { ns: "gcadmin" }),
  main: {
    id: "articles-main",
    name: i18n.t("Articles", { ns: "gcadmin" }),
    path: articlesPaths.list.fullPath,
  },
  create: {
    id: "articles-create",
    name: i18n.t("New", { ns: "gcadmin" }),
  },
  update: (fieldName: string) => ({
    id: "articles-update",
    name: fieldName || i18n.t("Update", { ns: "gcadmin" }),
  }),
});
