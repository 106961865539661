/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramCreateMutationVariables = Types.Exact<{
  input: Types.GuidedCareHealthProgramInput;
}>;


export type GuidedCareProgramCreateMutation = { __typename?: 'Mutation', guidedCareHealthProgramCreate?: { __typename?: 'GuidedCareHealthProgramCRUD', guidedCareHealthProgramErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareProgramCreateDocument = gql`
    mutation GuidedCareProgramCreate($input: GuidedCareHealthProgramInput!) {
  guidedCareHealthProgramCreate(input: $input) {
    guidedCareHealthProgramErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareProgramCreateMutationFn = Apollo.MutationFunction<GuidedCareProgramCreateMutation, GuidedCareProgramCreateMutationVariables>;

/**
 * __useGuidedCareProgramCreateMutation__
 *
 * To run a mutation, you first call `useGuidedCareProgramCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareProgramCreateMutation, { data, loading, error }] = useGuidedCareProgramCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuidedCareProgramCreateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareProgramCreateMutation, GuidedCareProgramCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareProgramCreateMutation, GuidedCareProgramCreateMutationVariables>(GuidedCareProgramCreateDocument, options);
      }
export type GuidedCareProgramCreateMutationHookResult = ReturnType<typeof useGuidedCareProgramCreateMutation>;
export type GuidedCareProgramCreateMutationResult = Apollo.MutationResult<GuidedCareProgramCreateMutation>;
export type GuidedCareProgramCreateMutationOptions = Apollo.BaseMutationOptions<GuidedCareProgramCreateMutation, GuidedCareProgramCreateMutationVariables>;