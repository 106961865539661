import { getAutocompleteFilter } from "@toolkit/ui";
import { useArticleCategoriesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
export const getArticleCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "articleCategories",
        query: useArticleCategoriesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, item === null || item === void 0 ? void 0 : item.displayAr),
        backendAccessor: "id",
    });
};
