import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageWrapper } from "@toolkit/ui";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { IGuidedCareTemplateActivitiesItemsTimelineContainerParams } from "pages/Templates/types";
import { GuidedCareTemplateActivitiesItemList } from "../../components/TemplateActivitiesItemList/GuidedCareTemplateActivitiesItemList";
import { GuidedCareTemplateActivitiesItemsTimeline } from "../../components/TemplateActivitiesItemsTimeline/GuidedCareTemplateActivitiesItemsTimeline";

export const GuidedCareTemplateActivitiesItemsTimelineContainer = () => {
  const { setGuidedCareTemplateActivitiesItemListContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const { templateId } = useParams<IGuidedCareTemplateActivitiesItemsTimelineContainerParams>();

  useEffect(() => {
    setGuidedCareTemplateActivitiesItemListContainerBreadcrumb();
  }, [setGuidedCareTemplateActivitiesItemListContainerBreadcrumb]);

  return (
    <GuidedCareTemplateActivitiesItemList templateId={templateId!}>
      <PageWrapper>
        <GuidedCareTemplateActivitiesItemsTimeline />
      </PageWrapper>
    </GuidedCareTemplateActivitiesItemList>
  );
};
