/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.MedicalMessageFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.MedicalMessageSortingInput>;
}>;


export type MedicalMessageListQuery = { __typename?: 'Query', medicalMessages?: { __typename?: 'MedicalMessageCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'MedicalMessageCountableEdge', cursor: string, node: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null, createdDate?: any | null, lastModifiedDate?: any | null, medicalMessageType?: Types.MedicalMessageType | null, medicalMessageCategory?: Types.MedicalMessageCategory | null, headerImage?: string | null, isPublished?: boolean | null, readCounter?: number | null, sentCounter?: number | null, healthCondition?: { __typename?: 'HealthCondition', id?: string | null, display?: string | null, arabicDisplay?: string | null } | null } }> } | null };


export const MedicalMessageListDocument = gql`
    query MedicalMessageList($after: String, $before: String, $filter: MedicalMessageFilterInput, $first: Int, $last: Int, $sortBy: MedicalMessageSortingInput) {
  medicalMessages(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        id
        code
        name
        createdDate
        lastModifiedDate
        medicalMessageType
        medicalMessageCategory
        headerImage
        isPublished
        readCounter
        sentCounter
        healthCondition {
          id
          display
          arabicDisplay
        }
      }
    }
  }
}
    `;

/**
 * __useMedicalMessageListQuery__
 *
 * To run a query within a React component, call `useMedicalMessageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalMessageListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMedicalMessageListQuery(baseOptions?: Apollo.QueryHookOptions<MedicalMessageListQuery, MedicalMessageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalMessageListQuery, MedicalMessageListQueryVariables>(MedicalMessageListDocument, options);
      }
export function useMedicalMessageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalMessageListQuery, MedicalMessageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalMessageListQuery, MedicalMessageListQueryVariables>(MedicalMessageListDocument, options);
        }
export type MedicalMessageListQueryHookResult = ReturnType<typeof useMedicalMessageListQuery>;
export type MedicalMessageListLazyQueryHookResult = ReturnType<typeof useMedicalMessageListLazyQuery>;
export type MedicalMessageListQueryResult = Apollo.QueryResult<MedicalMessageListQuery, MedicalMessageListQueryVariables>;