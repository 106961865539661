/* eslint-disable react-hooks/rules-of-hooks */
import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { DoctorPatientIcon, useTheme } from "@toolkit/ui";
import { guidedCareProgramsRoutes } from "pages/Programs/constants";
import { guidedCareTemplatesRoutes } from "pages/Templates/constants";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";

export const guidedCareRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  const theme = useTheme();
  return {
    id: "guided-care-routes",
    text: t("Guided Care", { ns: "gcadmin" }),
    icon: (
      <DoctorPatientIcon
        sx={{
          "& path": {
            fill: theme.palette.primary.main,
          },
        }}
      />
    ),
    hidden: !hasPermission(PermissionEnum.ManageProgramTemplates) && !hasPermission(PermissionEnum.ManagePrograms),
    subItems: [guidedCareTemplatesRoutes({ navigate, t }), guidedCareProgramsRoutes({ navigate, t })],
  };
};
