/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateParameterListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type RiskFactorTemplateParameterListQuery = { __typename?: 'Query', getRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', code?: string | null, display?: string | null, arabicDisplay?: string | null, fields?: Array<{ __typename?: 'HealthParameter', id?: string | null, standardCode?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, type?: Types.TemplateFieldType | null, chartType?: Types.ChartType | null, referenceRange?: string | null, createdDate?: string | null, defaultValue?: string | null, minNormalRangeValue?: number | null, maxNormalRangeValue?: number | null, allowedValues?: Array<string | null> | null, category?: { __typename?: 'FieldCategory', display?: string | null, arabicDisplay?: string | null } | null, unit?: { __typename?: 'FieldUnit', display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null };


export const RiskFactorTemplateParameterListDocument = gql`
    query RiskFactorTemplateParameterList($id: ID!) {
  getRiskFactorTemplate(id: $id) {
    code
    display
    arabicDisplay
    fields {
      id
      standardCode
      code
      display
      arabicDisplay
      category {
        display
        arabicDisplay
      }
      type
      chartType
      unit {
        display
        arabicDisplay
      }
      referenceRange
      createdDate
      defaultValue
      minNormalRangeValue
      maxNormalRangeValue
      allowedValues
    }
  }
}
    `;

/**
 * __useRiskFactorTemplateParameterListQuery__
 *
 * To run a query within a React component, call `useRiskFactorTemplateParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskFactorTemplateParameterListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRiskFactorTemplateParameterListQuery(baseOptions: Apollo.QueryHookOptions<RiskFactorTemplateParameterListQuery, RiskFactorTemplateParameterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RiskFactorTemplateParameterListQuery, RiskFactorTemplateParameterListQueryVariables>(RiskFactorTemplateParameterListDocument, options);
      }
export function useRiskFactorTemplateParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RiskFactorTemplateParameterListQuery, RiskFactorTemplateParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RiskFactorTemplateParameterListQuery, RiskFactorTemplateParameterListQueryVariables>(RiskFactorTemplateParameterListDocument, options);
        }
export type RiskFactorTemplateParameterListQueryHookResult = ReturnType<typeof useRiskFactorTemplateParameterListQuery>;
export type RiskFactorTemplateParameterListLazyQueryHookResult = ReturnType<typeof useRiskFactorTemplateParameterListLazyQuery>;
export type RiskFactorTemplateParameterListQueryResult = Apollo.QueryResult<RiskFactorTemplateParameterListQuery, RiskFactorTemplateParameterListQueryVariables>;